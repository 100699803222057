import buildFormReducer from './builders/form';
import buildLoadDataReducer from './builders/loadData';
import actionTypes, { namespaces } from '../actionTypes';
import { combineReducers } from 'redux';

const { MY_DASHBOARD } = actionTypes;

const mainInitialState = {
  myAgreements: [],
};
const formInitialState = {
  sortBy: {
    value: undefined,
  },
  showPendingOnly: {
    value: false,
  },
  showRequireSigningOnly: {
    value: false,
  },
};
const loadDataInitialState = {
  isLoadingData: false,
};

const mainReducer = (state = mainInitialState, action) => {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case MY_DASHBOARD.RESET_STATE:
      return { ...mainInitialState };
    case MY_DASHBOARD.SET_DATA:
      return {
        ...state,
        ...actionPayload,
      };
    default:
      return state;
  }
};

const myDashboardReducer = combineReducers({
  main: mainReducer,
  form: buildFormReducer(formInitialState, namespaces.MY_DASHBOARD),
  loadData: buildLoadDataReducer(loadDataInitialState, namespaces.MY_DASHBOARD),
});

export { myDashboardReducer as default };
