import HttpClient from '../../utils/HttpClient';

const httpClient = HttpClient.createInstance();

export const checkAuthentication = () => httpClient.get('/auth/check');

export const logout = () => httpClient.get('/auth/logout');

export const subscribe = email => httpClient.post('/auth/subscribe', { email });

export const verifyUserResponseToken = token => httpClient.post('/captcha/verify', { token });
