import PageHeader from './containers/PageHeader';
import CounterSign from './containers/CounterSign';
import CreateAgreement from './containers/CreateAgreement';
import Home from './containers/Home';
import HowItWorks from './containers/HowItWorks';
import AboutUs from './containers/AboutUs';
import ContactUs from './containers/ContactUs';
import Login from './containers/Login';
import ForgotPassword from './containers/ForgotPassword';
import MyDashboard from './containers/MyDashboard';
import AccountSettings from './containers/AccountSettings';
import TermOfUse from './containers/TermOfUse';
import PrivacyPolicy from './containers/PrivacyPolicy';
import createStore from './store';
import { StripeProvider } from 'react-stripe-elements';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import React from 'react';

const initialState = window.INITIAL_STATE;
const store = createStore(initialState);

const routes = (
  <Provider store={store}>
    <StripeProvider
      apiKey={
        process.env.NODE_ENV === 'production'
          ? 'pk_live_85A542Tmg3pVisvfzOybI0Xe'
          : 'pk_test_LSVrgTvCX46ShpuVm8eHBhoI'
      }
    >
      <Router>
        <React.Fragment>
          <PageHeader />

          <Switch>
            <Route exact={true} path="/home" component={Home} />
            <Route exact={true} path="/how-it-works" component={HowItWorks} />
            <Route exact={true} path="/about-us" component={AboutUs} />
            <Route exact={true} path="/contact-us" component={ContactUs} />
            <Route exact={true} path="/login" component={Login} />
            <Route exact={true} path="/forgot-password" component={ForgotPassword} />
            <Route exact={true} path="/my-dashboard" component={MyDashboard} />
            <Route exact={true} path="/account-settings" component={AccountSettings} />
            <Route exact={true} path="/term-of-use" component={TermOfUse} />
            <Route exact={true} path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/create-agreement" component={CreateAgreement} />
            <Route path="/counter-sign" component={CounterSign} />

            <Redirect from="/" to="/home" />
          </Switch>
        </React.Fragment>
      </Router>
    </StripeProvider>
  </Provider>
);

export { routes as default };
