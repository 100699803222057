import * as datasource from './datasource';
import * as loginDatasource from '../login/datasource';
import meActionCreator from '../me';
import buildFormActionCreator from '../builders/form';
import buildUpdateDataActionCreator from '../builders/updateData';
import buildLoadDataActionCreator from '../builders/loadData';
import { showModal } from '../../utils/helpers';
import actionTypes, { namespaces } from '../../actionTypes';
import { jwtStorageKey } from '../../utils/constants';

const { CREATE_AGREEMENT } = actionTypes;

export default {
  ...buildFormActionCreator(namespaces.CREATE_AGREEMENT),
  ...buildUpdateDataActionCreator(namespaces.CREATE_AGREEMENT),
  ...buildLoadDataActionCreator(namespaces.CREATE_AGREEMENT),

  resetMainState() {
    return {
      type: CREATE_AGREEMENT.RESET_STATE,
    };
  },

  resetState() {
    return dispatch => {
      dispatch(this.resetMainState());

      dispatch(this.resetFormState());

      dispatch(this.resetUpdateDataState());

      dispatch(this.resetLoadDataState());
    };
  },

  showDiscountPercentage(value) {
    return {
      type: CREATE_AGREEMENT.SHOW_DISCOUNT_PERCENTAGE,
      payload: value,
    };
  },

  validateCoupon(code, applyTo) {
    return async dispatch => {
      try {
        dispatch(this.updateDataRequest());

        if (!code) {
          throw new Error('Coupon code is empty!');
        }

        const {
          data: { isValid, type, value, displayMessage },
        } = await datasource.validateCoupon(code, applyTo);

        if (isValid && type === 'percentage off') {
          dispatch(this.showDiscountPercentage(value));
        } else {
          showModal(displayMessage || 'This coupon code is invalid.');
        }

        dispatch(this.updateDataSuccess());
      } catch (error) {
        showModal(error.message, 'error');

        dispatch(this.updateDataFailure(error.message));
      }
    };
  },

  processPaymentStart() {
    return {
      type: CREATE_AGREEMENT.PROCESS_PAYMENT_START,
    };
  },

  processPaymentEnd() {
    return {
      type: CREATE_AGREEMENT.PROCESS_PAYMENT_END,
    };
  },

  createAccountAndAgreement(
    isAuthenticated,
    history,
    createAccountPayload,
    createAgreementPayload,
    handleComplete,
    isSigningIn = false
  ) {
    return async dispatch => {
      try {
        dispatch(this.updateDataRequest());

        if (!isAuthenticated) {
          if (isSigningIn) {
            const {
              data: { jwtToken, user },
            } = await loginDatasource.login(
              createAccountPayload.email,
              createAccountPayload.password
            );

            dispatch(meActionCreator.setData({ isAuthenticated: true, ...user }));

            window.localStorage.setItem(jwtStorageKey, jwtToken);
          } else {
            const {
              data: { jwtToken, user },
            } = await datasource.createAccount(createAccountPayload);

            dispatch(meActionCreator.setData({ isAuthenticated: true, ...user }));

            window.localStorage.setItem(jwtStorageKey, jwtToken);
          }
        }

        await datasource.createAgreement(createAgreementPayload);

        if (createAgreementPayload.isDiscounted) {
          showModal(
            "You won't be charged for this agreement, but we require you to create an account along with CC or Apple Pay information for future reference soon.",
            'success',
            'Okay'
          );
        } else {
          showModal('Your agreement is successfully sent!', 'success', 'Okay');
        }

        history.push('/my-dashboard');

        // Report to the browser that the payment was successful, prompting
        // it to close the browser payment interface.
        if (handleComplete) {
          handleComplete('success');
        }

        dispatch(this.updateDataSuccess());
      } catch (error) {
        showModal(error.message, 'error');

        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        if (handleComplete) {
          handleComplete('fail');
        }

        dispatch(this.updateDataFailure(error.message));
      }
    };
  },

  createAccountAndSubscription(
    isAuthenticated,
    history,
    createAccountPayload,
    createSubscriptionPayload,
    handleComplete,
    isSigningIn = false
  ) {
    return async dispatch => {
      try {
        dispatch(this.updateDataRequest());

        if (!isAuthenticated) {
          if (isSigningIn) {
            const {
              data: { jwtToken, user },
            } = await loginDatasource.login(
              createAccountPayload.email,
              createAccountPayload.password
            );

            dispatch(meActionCreator.setData({ isAuthenticated: true, ...user }));

            window.localStorage.setItem(jwtStorageKey, jwtToken);
          } else {
            const {
              data: { jwtToken, user },
            } = await datasource.createAccount(createAccountPayload);

            dispatch(meActionCreator.setData({ isAuthenticated: true, ...user }));

            window.localStorage.setItem(jwtStorageKey, jwtToken);
          }
        }

        await datasource.createSubscription(createSubscriptionPayload);

        showModal('Your agreement is successfully sent!', 'success', 'Okay');
        history.push('/my-dashboard');

        // Report to the browser that the payment was successful, prompting
        // it to close the browser payment interface.
        if (handleComplete) {
          handleComplete('success');
        }

        dispatch(this.updateDataSuccess());
      } catch (error) {
        showModal(error.message, 'error');

        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        if (handleComplete) {
          handleComplete('fail');
        }

        dispatch(this.updateDataFailure(error.message));
      }
    };
  },

  createAgreementDirectly(history, payload) {
    return async dispatch => {
      try {
        dispatch(this.updateDataRequest());

        await datasource.createAgreementDirectly(payload);

        showModal('Your agreement is successfully sent!', 'success', 'Okay');
        history.push('/my-dashboard');

        dispatch(this.updateDataSuccess());
      } catch (error) {
        showModal(error.message, 'error');

        dispatch(this.updateDataFailure(error.message));
      }
    };
  },
};
