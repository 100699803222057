import PageFooter from './PageFooter';
import CreateAgreementFooter from '../components/CreateAgreementFooter';
import Button from '../components/Button';
import React, { Component } from 'react';

import '../../static/assets/styles/containers/aboutUs.scss';

export default class AboutUs extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="about-us">
        <section className="intro">
          <div className="intro-statements">
            <h1>About PlayNice</h1>
            <p>
              PlayNice is the first platform that legally protects you from cyber bullying. Our agreements protect you for life from someone posting something online that can cause you harm.
            </p>
            <div className="buttons">
              <Button className="watch-video-btn" onClick={() => {}}>
                Watch The Video
              </Button>
              <Button className="learn-more-btn" onClick={() => {}}>Our Mission With Innocence Project</Button>
            </div>
          </div>

          <div className="intro-image" />
        </section>

        <section className="privacy">
          <div className="privacy-image-container">
            <div className="privacy-image" />
          </div>

          <div className="privacy-description">
            <h2>Eric Masella Founder and CEO</h2>
            <p>PlayNice’s founder, Eric Masella, is no stranger to cyberbullying. Almost 15 years ago, while he was beginning his career in New York City, an ex-girlfriend posted harmful things about Eric on a website that encouraged attacks on ex-boyfriends. The posts were vulgar and meant to do harm, and after consulting a lawyer and others that were disparaged, they quickly discovered that this bullying was “protected speech,” and there was little they could do. This online post was destroying his image personally and professionally. Realizing then, and after watching the problem escalate, he later discovered the solution while living in Los Angeles where some celebrity parties required signing a Conﬁdentiality Agreement part of the cost of entrance. If celebrities didn’t use these tools, people would have the right to splash their private lives all over the Internet. It’s not just celebrities that need these, everyone does.</p>
            <p className="bold">“The eﬀects of those posts were crippling and humiliating to me. It felt like I didn’t have control of my life.”</p>
            <p>Each young individual that wants to protect their private life, that wants to have a clean digital footprint, that wants to share more with someone close without fear, that wants a prospective employer to see them in their best light, should ALL use PlayNice. We should not only want to protect ourselves but also protect each other.</p>
          </div>
        </section>

        <section className="our-mission">
          <h2>Our Mission With The Innocence Project</h2>
          <p>A portion of our net profits will go to The Innocence Project to help right the wrongs of racial inequality in the US criminal justice system.  The Innocence Project’s mission is to free the staggering number of innocent people who remain incarcerated, and bring reform to the system responsible for their unjust imprisonment.</p>
          <p>PlayNice will play its role to help eliminate online bullying and the wrongful and harmful posts online that result in the conviction of public opinion, ruining someones reputation and ability to compete fairly in the world.</p>
          <Button onClick={() => {}}>Learn More</Button>
        </section>

        <CreateAgreementFooter className="new-design-create-agreement-footer" />
        <PageFooter />
      </div>
    );
  }
}
