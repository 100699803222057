import buildFormReducer from './builders/form';
import buildUpdateDataReducer from './builders/updateData';
import buildLoadDataReducer from './builders/loadData';
import { plans } from '../utils/constants';
import actionTypes, { namespaces } from '../actionTypes';
import { combineReducers } from 'redux';

const { CREATE_AGREEMENT } = actionTypes;

const mainInitialState = {
  isDiscounted: false,
  discountedPercentage: 0,
  isProcessingPayment: false,
};
const formInitialState = {
  fullName: {
    value: '',
  },
  dob: {
    value: undefined,
  },
  phoneNumber: {
    value: '',
  },
  state: {
    value: 'AL',
  },
  toFullName: {
    value: '',
  },
  toPhoneNumber: {
    value: '',
  },

  code: {
    value: '',
  },

  signatureDataUrl: {
    value: undefined,
  },
  signatureDated: {
    value: undefined,
  },

  plan: {
    value: plans.unlimitedOneYear.displayName,
  },
  email: {
    value: '',
  },
  password: {
    value: '',
  },
  termsAccepted: {
    value: false,
  },
  discountCode: {
    value: '',
  },
};
const updateDataInitialState = {
  isUpdatingData: false,
  error: {
    isVisible: false,
    message: '',
  },
};
const loadDataInitialState = {
  isLoadingData: false,
};

const mainReducer = (state = mainInitialState, action) => {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case CREATE_AGREEMENT.RESET_STATE:
      return { ...mainInitialState };
    case CREATE_AGREEMENT.SHOW_DISCOUNT_PERCENTAGE:
      return { ...state, isDiscounted: true, discountedPercentage: actionPayload };
    case CREATE_AGREEMENT.PROCESS_PAYMENT_START:
      return { ...state, isProcessingPayment: true };
    case CREATE_AGREEMENT.PROCESS_PAYMENT_END:
      return { ...state, isProcessingPayment: false };
    default:
      return state;
  }
};

const createAgreementReducer = combineReducers({
  main: mainReducer,
  form: buildFormReducer(formInitialState, namespaces.CREATE_AGREEMENT),
  updateData: buildUpdateDataReducer(updateDataInitialState, namespaces.CREATE_AGREEMENT),
  loadData: buildLoadDataReducer(loadDataInitialState, namespaces.CREATE_AGREEMENT),
});

export { createAgreementReducer as default };
