import { Modal as AntdModal } from 'antd';
import uuid from 'uuid';
import queryString from 'query-string';

const generateUniqueId = () => uuid.v4();

const redirectTo = url => {
  window.location.assign(url);
};

const parseQueryString = queryStr => (queryStr && queryString.parse(queryStr)) || {};

const formatPhoneNumber = phoneNumber => {
  const phoneNumberWithOnlyNumbers = phoneNumber.replace(/[.()\- ]/g, '');

  if (phoneNumberWithOnlyNumbers[0] === '+') {
    return phoneNumberWithOnlyNumbers;
  }
  if (phoneNumberWithOnlyNumbers[0] === '1' && phoneNumberWithOnlyNumbers.length === 11) {
    return `+${phoneNumberWithOnlyNumbers}`;
  }
  return `+1${phoneNumberWithOnlyNumbers}`;
};

// The `type` can be 'info', 'success', 'error', 'warning', or 'confirm'.
const showModal = (title, type = 'warning', okText = 'Try Again', maskClosable = true) => {
  AntdModal[type]({
    title,
    centered: true,
    maskClosable,
    okText,
  });
};

const willRedirectBackFromLogin = (history, performRedirect = true) => {
  const redirectUrl = window.encodeURIComponent(
    `${history.location.pathname}${history.location.search}`
  );
  const pathname = '/login';
  const search = `?redirect_url=${redirectUrl}`;

  if (performRedirect) {
    history.push({ pathname, search });
  }

  return `${pathname}${search}`;
};

/* istanbul ignore next */
const isMobile = () => {
  let check;

  (function mobileCheck(s) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        s
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        s.substr(0, 4)
      )
    ) {
      check = true;
    } else {
      check = false;
    }
  })(window.navigator.userAgent || window.navigator.vendor || window.opera);

  return check;
};

// Format a JS date to 'yyyy-mm-dd'.
const formatDate = date => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = `${d.getFullYear()}`;

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
};

const isPasswordStrongEnough = password => {
  const containAtLeastOneUpperCase = /[A-Z]/;
  const containAtLeastOneNumber = /[0-9]/;

  return containAtLeastOneUpperCase.test(password) &&
    containAtLeastOneNumber.test(password) &&
    password.length >= 8;
};

const isEmailValid = (email) => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRegex.test(email);
};

export {
  generateUniqueId,
  redirectTo,
  parseQueryString,
  formatPhoneNumber,
  showModal,
  willRedirectBackFromLogin,
  isMobile,
  formatDate,
  isPasswordStrongEnough,
  isEmailValid,
};
