import buildFormReducer from './builders/form';
import buildUpdateDataReducer from './builders/updateData';
import actionTypes, { namespaces } from '../actionTypes';
import { combineReducers } from 'redux';

const { ACCOUNT_SETTINGS } = actionTypes;

const mainInitialState = {};
const formInitialState = {
  fullName: {
    value: '',
  },
  state: {
    value: 'AL',
  },
  phoneNumber: {
    value: '',
  },
  dob: {
    value: undefined,
  },
  email: {
    value: '',
  },
  password: {
    value: '',
  },
  emailSubscribed: {
    value: false,
  },
};
const updateDataInitialState = {
  isUpdatingData: false,
  error: {
    isVisible: false,
    message: '',
  },
};

const mainReducer = (state = mainInitialState, action) => {
  const actionType = action.type;
  // const actionPayload = action.payload;

  switch (actionType) {
    case ACCOUNT_SETTINGS.RESET_STATE:
      return { ...mainInitialState };
    default:
      return state;
  }
};

const accountSettingsReducer = combineReducers({
  main: mainReducer,
  form: buildFormReducer(formInitialState, namespaces.ACCOUNT_SETTINGS),
  updateData: buildUpdateDataReducer(updateDataInitialState, namespaces.ACCOUNT_SETTINGS),
});

export { accountSettingsReducer as default };
