import actionCreator from '../../actionCreators/counterSign';
import commonActionCreator from '../../actionCreators/common';
import StepsHeader from '../../components/headers/Steps';
import Button from '../../components/Button';
import {
  formatPhoneNumber,
  showModal,
  parseQueryString,
  willRedirectBackFromLogin,
} from '../../utils/helpers';
import { errorMessages, agreementStatuses } from '../../utils/constants';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../../static/assets/styles/containers/counterSign/verifyPhoneNumber.scss';

class UnconnectedVerifyPhoneNumber extends Component {
  static propTypes = {
    isLoadingData: PropTypes.bool.isRequired,
    agreementRequestId: PropTypes.string,
    isAuthenticated: PropTypes.bool.isRequired,
    isUpdatingData: PropTypes.bool.isRequired,
    formCode: PropTypes.string.isRequired,
    toPhoneNumber: PropTypes.string,

    dispatchInitialize: PropTypes.func.isRequired,
    dispatchSetFormField: PropTypes.func.isRequired,
    dispatchCheckVerification: PropTypes.func.isRequired,
    dispatchRequestVerification: PropTypes.func.isRequired,

    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    agreementRequestId: undefined,
    toPhoneNumber: undefined,
  };

  async componentDidMount() {
    const { location, history, dispatchInitialize, dispatchRequestVerification } = this.props;

    const { id } = parseQueryString(location.search);

    try {
      const { agreement, myInfo } = await dispatchInitialize(id);

      if (
        agreement.toPhoneNumber !== myInfo.phoneNumber &&
        agreement.phoneNumber !== myInfo.phoneNumber
      ) {
        showModal('You don’t have the access to this agreement.');
        willRedirectBackFromLogin(history);
        return;
      }

      if (agreement.status === agreementStatuses.completed) {
        history.push({
          pathname: '/counter-sign/confirmation',
          search: location.search,
          state: { isAlreadyCompleted: true },
        });
      } else {
        history.push({
          pathname: '/counter-sign/agreement-review',
          search: location.search,
        });
      }
    } catch (err) {
      if (err.message === errorMessages.unauthenticated) {
        if (err.agreement.status === agreementStatuses.completed) {
          const redirectUrl = window.encodeURIComponent(
            `/counter-sign/verify-phone-number${history.location.search}`
          );
          const search = `?redirect_url=${redirectUrl}`;

          history.push({
            pathname: '/login',
            search,
          });
        } else {
          dispatchRequestVerification(formatPhoneNumber(err.agreement.toPhoneNumber), 'sms');

          showModal(
            'Welcome to PlayNice! Someone that cares about your privacy and reputation online sent you this agreement to sign. The PlayNice agreement allows 2 people to commit to never harming each other online. Thank you for joining the PlayNice movement!',
            'info',
            'Continue'
          );
        }
      }
    }
  }

  handleInputChange(field, event) {
    this.props.dispatchSetFormField(field, event.target.value);
  }

  handleVerifyPhoneNumber = event => {
    event.preventDefault();

    const { formCode, dispatchCheckVerification, toPhoneNumber, history } = this.props;

    if (!formCode) {
      showModal('Verification code is required!');
      return;
    }

    dispatchCheckVerification(formCode, formatPhoneNumber(toPhoneNumber), isUserExisted => {
      let pathname;
      const redirectUrl = window.encodeURIComponent(
        `/counter-sign/agreement-review${history.location.search}`
      );
      const search = `?redirect_url=${redirectUrl}`;

      if (isUserExisted) {
        pathname = '/login';
      } else {
        pathname = '/counter-sign/create-account';
        showModal(
          'In order to sign this agreement, you must create an account first!',
          'warning',
          'Okay'
        );
      }

      history.push({ pathname, search });
    });
  };

  handleResendCode = () => {
    const { dispatchRequestVerification, toPhoneNumber } = this.props;

    dispatchRequestVerification(formatPhoneNumber(toPhoneNumber), 'sms', () => {
      showModal('Please check your phone for the verification code!', 'success', 'Okay');
    });
  };

  render() {
    const {
      isLoadingData,
      agreementRequestId,
      isAuthenticated,
      toPhoneNumber,
      formCode,
      isUpdatingData,
    } = this.props;

    if (isLoadingData || !agreementRequestId || isAuthenticated) {
      return null;
    }

    return (
      <div className="verify-phone-number">
        <StepsHeader currentStepIndex={0} />

        <main>
          <form>
            <div className="inputs">
              <h3 className="with-subtitle">Phone Number Verification</h3>
              <h4>We sent a verification code to {toPhoneNumber}</h4>

              <div className="input-container">
                <label htmlFor="code">Verification Code</label>
                <input
                  autoComplete="one-time-code"
                  placeholder="123456"
                  id="code"
                  value={formCode}
                  onChange={this.handleInputChange.bind(this, 'code')}
                />
              </div>
            </div>

            <div className="form-row">
              <p>
                Didn't get the code?{' '}
                <span className="link" onClick={this.handleResendCode}>
                  Resend again
                </span>
              </p>
              <Button
                type="submit"
                onClick={this.handleVerifyPhoneNumber}
                disabled={isUpdatingData}
              >
                Submit
              </Button>
            </div>
          </form>
        </main>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoadingData: state.me.loadData.isLoadingData || state.counterSign.loadData.isLoadingData,
  isUpdatingData: state.common.updateData.isUpdatingData,
  agreementRequestId: state.counterSign.main._id,
  isAuthenticated: state.me.main.isAuthenticated,
  formCode: state.counterSign.form.code.value,
  toPhoneNumber: state.counterSign.main.toPhoneNumber,
});

const mapDispatchToProps = dispatch => {
  return {
    dispatchInitialize(id) {
      return dispatch(actionCreator.initialize(id));
    },

    dispatchSetFormField(field, value) {
      dispatch(actionCreator.setFormField(field, value));
    },

    dispatchRequestVerification(...params) {
      dispatch(commonActionCreator.requestVerification(...params));
    },

    dispatchCheckVerification(...params) {
      dispatch(commonActionCreator.checkVerification(...params));
    },
  };
};

const VerifyPhoneNumber = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedVerifyPhoneNumber);

export { UnconnectedVerifyPhoneNumber, VerifyPhoneNumber as default };
