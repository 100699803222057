import H2 from './H2';
import Button from './Button';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import '../../static/assets/styles/components/createAgreementFooter.scss';

class CreateAgreementFooter extends Component {
  static propTypes = {
    className: PropTypes.string,
    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  goToCreateAgreement = () => {
    this.props.history.push('/create-agreement/basic-info');
  };

  render() {
    const className = cx('create-agreement-footer', this.props.className);

    return (
      <section className={className}>
        <div>
          <H2 className="create-agreement-h2">
            Always remember to PlayNice and stay carefree and confident.
          </H2>
          <Button className="create-agreement-btn" onClick={this.goToCreateAgreement}>
            CREATE AN AGREEMENT
          </Button>
        </div>
      </section>
    );
  }
}

export { CreateAgreementFooter as UnwrappedCreateAgreementFooter };
export default withRouter(CreateAgreementFooter);
