import { noop } from '../utils/constants';
import { injectStripe, PaymentRequestButtonElement } from 'react-stripe-elements';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import '../../static/assets/styles/components/paymentRequestButtonForm.scss';

class PaymentRequestButtonForm extends Component {
  static propTypes = {
    stripe: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    onToken: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    productLabel: PropTypes.string.isRequired,
    productPrice: PropTypes.number.isRequired,
    productCountry: PropTypes.string,
    productCurrency: PropTypes.string,
  };

  static defaultProps = {
    onClick: noop,
    productCountry: 'US',
    productCurrency: 'usd',
  };

  constructor(props) {
    super(props);

    // For full documentation of the available paymentRequest options, see:
    // https://stripe.com/docs/stripe.js#the-payment-request-object
    const paymentRequest = this.props.stripe.paymentRequest({
      country: this.props.productCountry,
      currency: this.props.productCurrency,
      total: {
        label: this.props.productLabel,
        amount: this.props.productPrice,
      },
    });

    paymentRequest.on('token', this.props.onToken);

    paymentRequest.canMakePayment().then(result => {
      this.setState({
        canMakePayment: !!result,
      });
    });

    this.state = {
      canMakePayment: false,
    };
    this.paymentRequest = paymentRequest;
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.productCurrency !== this.props.productCurrency ||
      nextProps.productLabel !== this.props.productLabel ||
      nextProps.productPrice !== this.props.productPrice
    ) {
      this.paymentRequest.update({
        currency: nextProps.productCurrency,
        total: {
          label: nextProps.productLabel,
          amount: nextProps.productPrice,
        },
      });
    }
  }

  render() {
    return (
      this.state.canMakePayment && (
        <div className="payment-request-button-form">
          <PaymentRequestButtonElement
            onClick={this.props.onClick}
            paymentRequest={this.paymentRequest}
            style={{
              // For more details on how to style the Payment Request Button, see:
              // https://stripe.com/docs/elements/payment-request-button#styling-the-element
              paymentRequestButton: {
                type: 'default',
                theme: 'dark',
                height: '48px',
              },
            }}
          />
          <div className="line-separator-container">
            <div className="line-separator" />
            <div className="line-text">Or Pay with Credit Card</div>
            <div className="line-separator" />
          </div>
        </div>
      )
    );
  }
}

export default injectStripe(PaymentRequestButtonForm);
