import { isMobile } from '../../utils/helpers';
import rasterizeHtml from 'rasterizehtml/dist/rasterizeHTML.allinone';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import PhoneNumberFormatter from 'phone-number-formats';
import { format, parse, parseISO, subYears } from 'date-fns';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../../static/assets/styles/containers/counterSign/confirmation.scss';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const formatter = "MMMM d, y 'at' H:mm:ss (OOOO)";

class UnconnectedConfirmation extends Component {
  static propTypes = {
    fromFullName: PropTypes.string,
    fromDob: PropTypes.string,
    fromPhoneNumber: PropTypes.string,
    fromState: PropTypes.string,
    fromSignatureDataUrl: PropTypes.string,
    fromSignatureDated: PropTypes.string,

    toPhoneNumber: PropTypes.string,
    toFullNameFromRequester: PropTypes.string,

    agreementName: PropTypes.string,

    toSignatureDataUrl: PropTypes.string,
    toSignatureDated: PropTypes.string,
    toFullName: PropTypes.string,
    toDob: PropTypes.string,
    toState: PropTypes.string,

    requestedTimestamp: PropTypes.string,
    agreementCompletedTimestamp: PropTypes.string,

    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    fromFullName: '',
    fromDob: '',
    fromPhoneNumber: '',
    fromState: '',
    fromSignatureDataUrl: undefined,
    fromSignatureDated: undefined,
    toPhoneNumber: '',
    toFullNameFromRequester: '',
    agreementName: '',
    toSignatureDataUrl: undefined,
    toSignatureDated: undefined,
    toFullName: '',
    toDob: '',
    toState: '',
    requestedTimestamp: '',
    agreementCompletedTimestamp: '',
  };

  isLoading = true;

  UNSAFE_componentWillMount() {
    const isAlreadyCompleted =
      this.props.location.state && !this.props.location.state.isAlreadyCompleted;

    if (
      !isAlreadyCompleted &&
      (!this.props.toSignatureDataUrl ||
        !this.props.toSignatureDated ||
        !this.props.toFullName ||
        !this.props.toDob ||
        !this.props.toState)
    ) {
      this.props.history.push({
        pathname: '/counter-sign/verify-phone-number',
        search: this.props.history.location.search,
      });
    } else {
      this.isLoading = false;
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    if (this.isLoading || isMobile()) {
      return;
    }

    const canvasToDownloadWidth = this.pageContainerElement.scrollWidth;
    const canvasToDownloadHeight = this.pageContainerElement.scrollHeight;

    this.canvasRef.width = canvasToDownloadWidth;
    this.canvasRef.height = canvasToDownloadHeight + 700;

    const iframes = document.querySelectorAll('iframe');

    for (let i = 0; i < iframes.length; i++) {
      iframes[i].parentNode.removeChild(iframes[i]);
    }

    await rasterizeHtml.drawHTML(window.document.documentElement.outerHTML, this.canvasRef);

    const a4Width = 595;
    const docDefinition = {
      content: [
        {
          image: 'building',
          width: a4Width,
        },
      ],

      pages: 6,
      pageSize: {
        width: a4Width,
        height: 'auto',
      },
      pageMargins: [0, 0, 0, 0],
      images: {
        building: this.canvasRef.toDataURL(),
      },
    };

    this.canvasRef.style.display = 'none';

    pdfMake.createPdf(docDefinition).download('agreement.pdf');
  }

  setPageContainerRef = element => {
    this.pageContainerElement = element;
  };

  setCanvasRef = element => {
    this.canvasRef = element;
  };

  render() {
    if (this.isLoading) {
      return null;
    }

    const {
      fromFullName,
      fromDob,
      fromPhoneNumber,
      fromState,
      fromSignatureDataUrl,
      fromSignatureDated,
      toFullName,
      toFullNameFromRequester,
      toDob,
      toPhoneNumber,
      toState,
      toSignatureDataUrl,
      toSignatureDated,
      agreementName,
      lastOpenedByReceiverTimestamp,
      requestedTimestamp,
      agreementCompletedTimestamp,
    } = this.props;

    return (
      <div className="confirmation" ref={this.setPageContainerRef}>
        <header className="success-header">
          <img src="/images/ya.png" alt="Ya" />
          <p className="success">Successful!</p>
          <p>
            Congrats, you are now fully protected. We just sent you a text message with the link to
            re-access the agreement.{' '}
          </p>
        </header>

        <main>
          <section className="document-container">
            <h2>{agreementName}</h2>

            <p>
              This Mutual Respect and Confidentiality Agreement (this “Agreement”) dated{' '}
              <span>{toSignatureDated}</span> is entered into by and between{' '}
              <span>{fromFullName}</span>, an individual with state of <span>{fromState}</span>, and{' '}
              <span>{toFullName}</span> (<span>{toFullNameFromRequester}</span>), an individual with
              state of <span>{toState}</span>, to protect the parties’ reputational interests and
              confidentiality of certain information previously disclosed, or that may be disclosed,
              by one or both parties (“Disclosing Party”) to the other (“Receiving Party”). The
              parties are referred to in this Agreement each as a “Party,” and together, the
              “Parties.”
            </p>
            <p>
              In consideration of the mutual promises contained in this Agreement and other good and
              valuable consideration, the Parties agree as follows:
            </p>
            <p>
              1. Protected Information. “Protected Information” includes personal, private,
              confidential, or sensitive information, communications, messages, data, texts, media,
              music, sounds, images, photos, graphics, or other material in any manner or media now
              known or hereafter devised that depicts or relates or belongs to the Disclosing Party
              in connection with this Agreement.
            </p>
            <p>
              2. Confidentiality/Non-Disclosure of Protected Information. Unless otherwise agreed by
              the Disclosing Party in writing, the Receiving Party agrees, at all times, to hold the
              Disclosing Party’s Protected Information in strict confidence and to take reasonable
              precautions against unauthorized use, disclosure, publication, exploitation, or
              dissemination of such Protected Information or related information (including, without
              limitation, those precautions the Receiving Party utilizes to protect its own
              Protected Information). However, the Receiving Party may disclose Protected
              Information if and as required by law or court order, provided the Receiving Party
              promptly notifies the Disclosing Party of the disclosure and uses reasonable efforts
              to assist the Disclosing Party in obtaining a protective order preventing or limiting
              the disclosure.
            </p>
            <p>
              3. Return of Protected Information. At the Disclosing Party’s request, the Receiving
              Party will immediately return and permanently delete or cause to be deleted (if
              electronic) or destroy (if physical) all Protected Information, including all copies
              thereof and extracts therefrom, and promptly certify to the Disclosing Party that it
              has done so. The Receiving Party shall not keep any copies of or extracts from such
              Protected Information in any form or any media.
            </p>
            <p>4. Cooperation.</p>
            <p className="double-indent">
              a. The Receiving Party will immediately notify the Disclosing Party upon discovering
              any unauthorized disclosure or use of Protected Information and will cooperate with
              the Disclosing Party in every reasonable manner to help the Disclosing Party regain
              possession of the Protected Information and prevent its further unauthorized
              disclosure or use, including, but not limited to, by removing any compromised
              Protected Information from websites, social media pages, and other forums,
              publications, or distribution channels over which the Disclosing Party has ownership
              or control.
            </p>
            <p className="double-indent">
              b. During all reasonable times, the Receiving Party will cooperate with the Disclosing
              Party in any present or future Disputes (defined below) involving the Disclosing Party
              with respect to the unauthorized use or disclosure of Protected Information,
              including, but not limited to, by providing truthful testimony as a witness in such
              proceeding.
            </p>
            <p>
              5. Rights to Protected Information. The Disclosing Party shall retain all rights,
              including all intellectual property rights and privacy rights, to all Protected
              Information and does not grant to the Receiving Party any right, interest, or license
              to the Protected Information. Each Party’s intellectual property shall remain its own;
              provided, however, any intellectual property created jointly by the Parties that would
              constitute Protected Information (“Jointly Protected Information”) shall be owned
              jointly by the Parties and treated and protected as if each Party is a Receiving
              Party, and neither Party shall display, perform, distribute, prepare derivative works
              from, reproduce or otherwise exploit the Jointly Protected Information without the
              express written consent of the other Party.
            </p>
            <p>
              6. Non-Disparagement. The Parties agree not to make any negative, derogatory, or
              disparaging statements or communications, oral or written, about the other regarding
              the Protected Information or its contents. Unauthorized use, disclosure, publication,
              or dissemination of Protected Information may, in and of itself, constitute
              disparagement of the Disclosing Party.
            </p>
            <p>
              7. Harm Resulting from Unauthorized Use or Disclosure. Each Party acknowledges and
              agrees that any breach or threatened breach of this Agreement may constitute or result
              in any of the following, including, but not limited to, defamation (i.e., libel or
              slander); invasion of privacy; intrusion upon seclusion; public disclosure of private
              facts; false light; appropriation of name or likeness; intentional or negligent
              infliction of emotional distress; cyberbullying; personal injury; interference with an
              existing or prospective contract, business relationship, or economic advantage; loss
              of employment or educational opportunities; or other personal, reputational,
              dignitary, or economic harms to the Receiving Party. Reporting or disclosing to any
              governmental authority any material that the Receiving Party reasonably believes is
              not Protected Information due to its unlawful nature, or reporting or disclosing any
              Protected Information as required by law or court order, shall not constitute a breach
              of this Agreement.
            </p>
            <p>
              8. Remedies. The Receiving Party acknowledges and agrees that if it breaches or
              threatens to breach this Agreement, the Disclosing Party will suffer immediate and
              irreparable harm, meaning harm for which monetary damages would be an inadequate
              remedy. The Receiving Party agrees that the Disclosing Party may seek to restrain the
              Receiving Party from violating this Agreement through a temporary, preliminary, and/or
              permanent injunction, and such equitable relief shall be in addition to (and not
              instead of) any other remedies to which the Disclosing Party may be entitled,
              including monetary damages.
            </p>
            <p>
              9. No Waiver of Criminal Liability. Nothing in this Agreement shall waive, now or in
              the future, either Party’s right to seek criminal prosecution for, or disclaim a
              Party’s criminal liability from, any illegal act arising from the use, disclosure,
              publication, or dissemination of Protected Information.
            </p>
            <p>
              10. Representations and Warranties. Each Party represents and warrants to the other
              Party that: (i) it is at least eighteen (18) years of age and has the power and
              authority to enter into and fully perform this Agreement; (ii) it has legal capacity
              to contract and is not mentally impaired or under the influence of drugs or alcohol at
              the time of entering into this Agreement; and (iii) it is not bound by any other
              agreements that would prevent such Party from entering in to or fulfilling the terms
              of this Agreement.
            </p>
            <p>
              11. No Other Assurances; Entire Agreement. Each Party acknowledges, in signing this
              Agreement, it has not relied on any oral or written promises, statements, or
              representations made by the other Party other than those in this Agreement. This
              Agreement constitutes the entire agreement between the Parties, and replaces and
              cancels all previous oral or written agreements, understandings, and commitments in
              connection with the subject matter of this Agreement.
            </p>
            <p>
              12. Waiver of Liability of Let’s Play Nice, LLC. The Parties acknowledge that (1)
              Let’s Play Nice, LLC (“LPN”) is the provider of the PlayNice software application (the
              "App"); (2) each Party's use of the App is at all times to subject to the Terms of Use
              of the App, which include provisions disclaiming LPN's liability to each Party under
              this Agreement; (3) LPN is not a party to this Agreement; (4) LPN makes no
              representations or warranties hereunder to either Party regarding the App, the other
              Party's performance under this Agreement, or any other subject matter of this
              Agreement; and (5) LPN does not guarantee or otherwise assume any obligations with
              respect to either Party's performance under this Agreement.
            </p>
            <p>
              13. Assignment. The Parties agree that this Agreement is personal to them and may not
              be assigned by either Party to any other person or entity. Any purported assignment of
              this Agreement shall be void. Notwithstanding the foregoing, in the event of either
              Party’s death during the term of this Agreement, the deceased Party’s heirs,
              successors, executors, and administrators may enforce such Party’s rights under this
              Agreement.
            </p>
            <p>
              14. Notices. All notices permitted or required under this Agreement shall be in
              writing and delivered by personal delivery, electronic mail, first class mail, or
              overnight courier service (e.g., FedEx), and will be deemed delivered upon personal
              delivery, five (5) days after mailing, or upon acknowledgment of receipt of electronic
              transmission. Notices shall be sent to the addresses set forth above or such other
              address as either Party may specify in writing.
            </p>
            <p>
              15. Severability. The invalidity or unenforceability of any provision of this
              Agreement shall not affect the validity or enforceability of any other provision of
              this Agreement, which shall remain in full force and effect. Any provision of this
              Agreement deemed overbroad or unenforceable shall be given the maximum effect allowed
              by law.
            </p>
            <p>16. Dispute Resolution.</p>
            <p className="double-indent">
              a. Governing Law. This Agreement, and any controversy, dispute, or claim arising out
              of or relating to this Agreement (a “Dispute”), shall be governed by and construed in
              accordance with the laws of the jurisdiction in which this Agreement was entered into
              as determined by the geolocation stamps below the Parties’ signatures hereto; in the
              event that the Parties enter into this Agreement in different jurisdictions, conflict
              of law principles shall apply and any arbitration conducted pursuant to the terms of
              this Agreement shall be governed by the Federal Arbitration Act (9 U.S.C., Secs.
              1-16).
            </p>
            <p className="double-indent">
              b. Arbitration as Exclusive Dispute Resolution. The Parties agree that Disputes shall
              be resolved by final and binding arbitration by a neutral arbitrator in accordance
              with the then-prevailing arbitration rules of JAMS. The arbitration proceeding and all
              related documents will be confidential, unless disclosure is required by law. The
              decision of the arbitrator shall be final, binding and conclusive on the Parties and
              their respective heirs and successors, and not be subject to further review. The
              arbitrator may, in the arbitrator’s discretion, award attorneys’ fees and costs to
              such Party as the arbitrator sees fit in rendering a decision.{' '}
            </p>
            <p className="double-indent">
              c. Enforcement of Award. Judgment upon any award rendered by an arbitrator may be
              entered in any court properly having jurisdiction over the Parties or Dispute.
            </p>
            <p className="double-indent">
              d. Waiver of Jury Trial. Each Party acknowledges and agrees that any Dispute is likely
              to involve complicated, difficult, and sensitive issues, and therefore each knowingly,
              voluntarily, intentionally, irrevocably, and unconditionally waives any right it may
              have to a trial by jury with respect to a Dispute.
            </p>
            <p>
              17. Term; Survival. This Agreement shall continue in perpetuity. The death of either
              Party shall not terminate this Agreement, and each Party’s obligations under this
              Agreement will survive, and continue in full force and effect, after the death of the
              death of such Party and be binding upon its heirs and successors.
            </p>
            <p>
              18. No Waiver; Modification/Amendment. Neither Party’s action or inaction shall be
              deemed to waive any provision of this Agreement. No waiver of any provision of this
              Agreement shall constitute a waiver of any other provision or of the same provision on
              another occasion. With respect to sections 1-6, 16(b), and 18, this Agreement may only
              be changed or modified by written agreement signed by both Parties, and not by any
              oral agreement, statement, promise, commitment, or representation by one or both
              Parties. All other sections may not be amended or modified by the Parties.{' '}
            </p>
            <p>
              19. Knowing and Voluntary Agreement; Binding Effect. Each Party affirms that it has
              entered into this Agreement knowingly, freely, and voluntarily, and that it has had
              adequate time to carefully consider these terms and conditions and to decide whether
              to sign this Agreement. Each Party affirms that it understands each and every
              provision of this Agreement and, to the extent that it did not understand any portion
              of this Agreement, such Party has taken appropriate steps to understand such
              provision. Each Party understands and acknowledges the final and binding effect of
              this Agreement.
            </p>
            <p>
              20. Counterparts; Electronic Signature. This Agreement may be signed in one or more
              counterparts, and counterparts may be exchanged by electronic submission (including
              through the App), each of which will be deemed an original, but which together
              constitute one and the same instrument. The Parties acknowledge this Agreement has
              been electronically executed in accordance with the requirements of the Electronic
              Signatures and Records Act (“ESRA”). To the extent that the laws of a jurisdiction
              other than the State of New York do not apply to this Agreement and any Dispute under
              section 16(a), the Parties expressly waive the requirements for electronic signature
              under the applicable law, which the Parties acknowledge may be more burdensome than
              ESRA, and agree not to challenge the valid execution or binding effect of this
              Agreement on the grounds of incomplete compliance with applicable electronic signature
              law.
            </p>
            <p>
              21. No Practice of Law. LPN and its respective officers, managers, members, employees,
              representatives, agents, successors, or assigns are not a law firm and do not provide
              legal advice. No attorney-client relationship is intended, sought, or created by or
              through the execution of this Agreement. LPN and its respective members have not
              provided, nor will provide at any time in the future, legal advice to the Parties
              regarding or in connection with this Agreement, any Dispute, or arbitration proceeding
            </p>
            <p>
              The Parties are signing this Mutual Respect and Confidentiality Agreement as of the
              date and time set forth below each of their signatures.
            </p>
          </section>

          <section>
            <form>
              <h6>Party 1</h6>
              <div className="inputs">
                <div className="input-container">
                  <div>{fromFullName}</div>
                  <label>Print Name</label>
                </div>

                <div className="input-container signature">
                  <div>
                    <img src={fromSignatureDataUrl} alt="signature" />
                  </div>
                  <label>Signed</label>
                </div>

                <div className="input-container">
                  <div>{fromSignatureDated}</div>
                  <label>Dated</label>
                </div>

                <div className="input-container">
                  <div>{fromState}</div>
                  <label>State</label>
                </div>

                <div className="input-container">
                  <div>
                    {fromPhoneNumber &&
                      new PhoneNumberFormatter(fromPhoneNumber)
                        .format({ type: 'domestic' })
                        .toString()}
                  </div>
                  <label>Phone Number</label>
                </div>

                <div className="input-container">
                  <div>
                    {fromDob &&
                      format(parse(fromDob, 'yyyy-MM-dd', subYears(new Date(), 18)), 'M/d/yyyy')}
                  </div>
                  <label>Date of Birth</label>
                </div>
              </div>

              <h6>Party 2</h6>
              <div className="inputs">
                <div className="input-container">
                  <div>
                    {toFullName} ({toFullNameFromRequester})
                  </div>
                  <label>Print Name</label>
                </div>

                <div className="input-container signature">
                  <div>
                    <img src={toSignatureDataUrl} alt="signature" />
                  </div>
                  <label>Signed</label>
                </div>

                <div className="input-container">
                  <div>{toSignatureDated}</div>
                  <label>Dated</label>
                </div>

                <div className="input-container">
                  <div>{toState}</div>
                  <label>State</label>
                </div>

                <div className="input-container">
                  <div>
                    {toPhoneNumber &&
                      new PhoneNumberFormatter(toPhoneNumber)
                        .format({ type: 'domestic' })
                        .toString()}
                  </div>
                  <label>Phone Number</label>
                </div>

                <div className="input-container">
                  <div>
                    {toDob &&
                      format(parse(toDob, 'yyyy-MM-dd', subYears(new Date(), 18)), 'M/d/yyyy')}
                  </div>
                  <label>Date of Birth</label>
                </div>
              </div>
            </form>
          </section>

          <section className="audit-trail">
            <h5>Audit Trail</h5>

            <p>
              Agreement created by {fromFullName} ({fromPhoneNumber}) on{' '}
              {format(parseISO(requestedTimestamp), formatter)}
            </p>
            <p>
              ESigned by {fromFullName} ({fromPhoneNumber}) on{' '}
              {format(parseISO(requestedTimestamp), formatter)}
            </p>
            <p>
              Counter signing request sent to {toFullNameFromRequester} ({toPhoneNumber}) on{' '}
              {format(parseISO(requestedTimestamp), formatter)}
            </p>
            <p>
              The link was clicked by {toFullNameFromRequester} ({toPhoneNumber}) on{' '}
              {lastOpenedByReceiverTimestamp}
            </p>
            <p>
              ESigned by {toFullName} ({toFullNameFromRequester}) on {agreementCompletedTimestamp}
            </p>
            <p>Agreement completed on {agreementCompletedTimestamp}</p>
          </section>
        </main>

        <canvas ref={this.setCanvasRef} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fromFullName: state.counterSign.main.fullName,
  fromDob: state.counterSign.main.dob,
  fromPhoneNumber: state.counterSign.main.phoneNumber,
  fromState: state.counterSign.main.state,
  fromSignatureDataUrl: state.counterSign.main.signatureDataUrlFromRequester,
  fromSignatureDated: state.counterSign.main.signatureDatedFromRequester,

  toPhoneNumber: state.counterSign.main.toPhoneNumber,
  toFullNameFromRequester: state.counterSign.main.toFullNameFromRequester,

  agreementName: state.counterSign.main.agreementName,

  toSignatureDataUrl: state.counterSign.form.signatureDataUrl.dirty
    ? state.counterSign.form.signatureDataUrl.value
    : state.counterSign.main.signatureDataUrlFromReceiver,
  toSignatureDated: state.counterSign.form.signatureDated.dirty
    ? state.counterSign.form.signatureDated.value
    : state.counterSign.main.signatureDatedFromReceiver,
  toFullName: state.counterSign.main.toFullNameFromReceiver || state.me.main.fullName,
  toDob: state.counterSign.main.toDob || state.me.main.dob,
  toState: state.counterSign.main.toState || state.me.main.state,

  requestedTimestamp: state.counterSign.main.requestedTimestamp,
  lastOpenedByReceiverTimestamp: state.counterSign.main.lastOpenedByReceiverTimestamp
    ? format(parseISO(state.counterSign.main.lastOpenedByReceiverTimestamp), formatter)
    : format(new Date(), formatter),
  agreementCompletedTimestamp: state.counterSign.main.agreementCompletedTimestamp
    ? format(parseISO(state.counterSign.main.agreementCompletedTimestamp), formatter)
    : format(new Date(), formatter),
});

const Confirmation = connect(
  mapStateToProps,
  null
)(UnconnectedConfirmation);

export { UnconnectedConfirmation, Confirmation as default };
