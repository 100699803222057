import * as datasource from './datasource';
import { showModal } from '../../utils/helpers';
import buildLoadDataActionCreator from '../builders/loadData';
import buildFormActionCreator from '../builders/form';
import buildUpdateDataActionCreator from '../builders/updateData';
import actionTypes, { namespaces } from '../../actionTypes';
import { errorMessages, jwtStorageKey } from '../../utils/constants';

const { ME } = actionTypes;

const meActionCreator = {
  ...buildLoadDataActionCreator(namespaces.ME),
  ...buildFormActionCreator(namespaces.ME),
  ...buildUpdateDataActionCreator(namespaces.ME),

  resetMainState() {
    return {
      type: ME.RESET_STATE,
    };
  },

  resetState() {
    return dispatch => {
      dispatch(this.resetMainState());

      dispatch(this.resetLoadDataState());

      dispatch(this.resetFormState());

      dispatch(this.resetUpdateDataState());
    };
  },

  setData(payload) {
    return {
      type: ME.SET_DATA,
      payload,
    };
  },

  checkAuthentication() {
    return async dispatch => {
      try {
        dispatch(this.loadDataRequest());

        const {
          data: { jwtToken, user },
        } = await datasource.checkAuthentication();

        dispatch(this.setData({ isAuthenticated: true, ...user }));

        window.localStorage.setItem(jwtStorageKey, jwtToken);

        dispatch(this.loadDataSuccess());

        return user;
      } catch (error) {
        dispatch(this.resetMainState());

        dispatch(this.loadDataFailure());

        throw new Error(errorMessages.unauthenticated);
      }
    };
  },

  logout(history) {
    return async dispatch => {
      try {
        dispatch(this.updateDataRequest());

        await datasource.logout();

        dispatch(this.setData({ isAuthenticated: false }));

        window.localStorage.removeItem(jwtStorageKey);

        history.push('/login');

        dispatch(this.updateDataSuccess());
      } catch (error) {
        showModal(error.message, 'error');

        dispatch(this.updateDataFailure(error.message));
      }
    };
  },

  subscribe(email) {
    return async dispatch => {
      try {
        dispatch(this.updateDataRequest());

        await datasource.subscribe(email);

        dispatch(this.setFormField('email', ''));

        showModal('You have successfully subscribed to our newsletter!', 'success', 'Okay');

        dispatch(this.updateDataSuccess());
      } catch (error) {
        showModal(error.message, 'error');

        dispatch(this.updateDataFailure(error.message));
      }
    };
  },

  verifyUserResponseToken(token) {
    return async dispatch => {
      try {
        dispatch(this.loadDataRequest());

        const { data: verifyResData } = await datasource.verifyUserResponseToken(token);

        if (verifyResData.success) {
          switch (verifyResData.action) {
            case 'page_footer_load':
              dispatch(
                this.setData({ shouldSubscriptionFormBeVisible: verifyResData.score > 0.6 })
              );
              break;
            case 'inquiry_form_load':
              dispatch(this.setData({ shouldInquiryFormBeVisible: verifyResData.score > 0.6 }));
              break;
            default:
          }
        } else {
          throw new Error(JSON.stringify(verifyResData, null, 2));
        }

        dispatch(this.loadDataSuccess());
      } catch (error) {
        showModal(error.message, 'error');

        dispatch(this.loadDataFailure(error.message));
      }
    };
  },
};

export default meActionCreator;
