const noop = () => {};

const plans = {
  payPerPlay: {
    displayName: 'Pay Per Play',
    displayPrice: '$1.99',
    priceForStripe: 229,
  },
  unlimitedOneYear: {
    displayName: 'Unlimited One Year',
    displayPrice: '$8.99',
    priceForStripe: 929,
  },
};

const paymentMethods = {
  creditCard: 'Credit Card',
  paymentRequestApi: 'Payment Request',
};

const errorMessages = {
  unauthenticated: 'unauthenticated',
};

const jwtStorageKey = 'jwt';

const agreementStatuses = {
  pending: 'pending',
  completed: 'completed',
};

const agreementSortBy = {
  createdTimestamp: 'Created Timestamp',
  receiverName: 'Receiver Name',
  agreementStatus: 'Agreement Status',
};

const backendVersion = 'v1';
const baseUrl = {
  production: `https://playnice-backend.herokuapp.com/api/${backendVersion}`,
  test: `http://localhost/api/${backendVersion}`,
  development: `http://localhost:8087/api/${backendVersion}`,
};
const baseUrlHost = baseUrl.test.split('/api')[0];

export {
  noop,
  baseUrl,
  baseUrlHost,
  backendVersion,
  plans,
  paymentMethods,
  errorMessages,
  jwtStorageKey,
  agreementStatuses,
  agreementSortBy,
};
