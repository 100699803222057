import { Link } from 'react-router-dom';
import React, { Component } from 'react';

import '../../static/assets/styles/containers/privacyPolicy.scss';

class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="privacy-policy">
        <div className="content">
          <h5>PLAYNICE</h5>
          <h5 className="sub-title">Privacy Policy</h5>

          <ol>
            <li>
              <h6>ABOUT PLAYNICE</h6>
              <p>We are Let’s Play Nice, LLC, a Delaware limited liability company (“PlayNice”, “we”, “our” or “us”).</p>
            </li>
            <li>
              <h6>ABOUT THIS PRIVACY POLICY</h6>
              <p>
                This privacy policy (the “Privacy Policy”) applies to the personal data and other information that means any individual whose personal data we collect (“you”) provide to us or that we collect about you through our website, https://playniceapp.com (the “Site”) and all related sites, platforms, applications, and services provided by us (together with the Site, the “Services”). It explains, among other things, how and why we collect data about you when you use our Services, what data we collect, what we do with that data, how we look after it, and what your rights are in relation to it. Any capitalized terms not defined in this Privacy Policy shall have the meaning set forth in the <Link to="/term-of-use">PlayNice Terms of Service.</Link>
              </p>
            </li>
            <li>
              <h6>DATA WE COLLECT ABOUT YOU</h6>
              <p>The personal data we collect via or in relation to the Services can be split into three broad categories:</p>
              <ul>
                <li>information you provide to us;</li>
                <li>information collected automatically; and</li>
                <li>information we obtain from third parties.</li>
              </ul>

              <h6 className="list-sub-title">Information you provide to us</h6>
              <p>We may ask for and collect the following information when you use the Services:</p>
              <p><span className="underline">Identity information:</span> You must provide us with certain personal information, including your name, signature, phone number, email address, payment information, and state of residence.</p>
              <p><span className="underline">Payment Information:</span> We may request your credit card or other payment information to collect payment for purchases made through the Services. We use Stripe, a third-party provider to process the credit card information and do not store your payment information. The use and storage of your payment information is governed by <a target="_blank" href="https://stripe.com/privacy">Stripe’s Privacy Policy</a>.</p>
              <p><span className="underline">Communications:</span> When you communicate with us by email, mail, telephone, or through/via the Services, we collect information about your communication and any information you choose to provide.</p>

              <h6 className="list-sub-title">Information we collect automatically</h6>
              <p>We collect certain information automatically when you use the Services. This information includes:</p>
              <p><span className="underline">Interaction information and history:</span> Information about your interactions and history with the Services, including the pages that you visit and links you click on, the time, frequency and duration of your Site visits, and your IP address.</p>
              <p><span className="underline">Location data:</span> When you use certain features of the Services, we may collect data about your geographical location. We use this data to provide you with location-based services (such as personalized content).</p>
              <p><span className="underline">Cookies:</span> Like many websites on the Internet, when you visit the Site, PlayNice may assign your computer one or more cookies to facilitate access to the Site and to personalize your online experience. Through the use of a cookie, PlayNice also may automatically collect information about your online activity on the Services, such as the web pages you visit, the time and date of your visits, and the links you click on the Services. PlayNice may use this data to better understand how you interact with the Services, to monitor aggregate usage by our users and web traffic routing on the Services, and to improve the Site and our Services. Most browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies. If you choose to decline cookies, please note that you may not be able use some of the interactive features offered on the Services. In addition, please be aware that other parties may also place their own cookies on or through the Site and/or Services and may collect or solicit personal information from you. Other cookies may reflect de-identified demographic or other data linked to the registration data you have submitted to PlayNice in hashed, non-human readable form. No personally identifiable information is contained in these cookies. We do not support Do Not Track (“DNT”). Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked. You can enable or disable Do Not Track by visiting the preferences or settings page of your web browser. For further details, visit donottrack.us.</p>

              <h6 className="list-sub-title">Information we receive from other sources</h6>
              <p><span className="underline">Service providers and business partners:</span> We may also receive information about you from our service providers and business partners, including Twilio, Stripe, and other companies that assist with analytics, data processing and management, account management, payment processing, customer and technical support, hosting, and other services which we use to personalize your PlayNice experience. We explain in more detail why and how we use this information below.</p>
              <p>When we receive information from other sources, we rely on them having the appropriate provisions in place telling you how they collect data and who they may share it with.</p>
            </li>
            <li>
              <h6>HOW YOUR PERSONAL DATA IS USED</h6>
              <p>This following explains how we use and process your personal data:</p>
              <h6 className="list-sub-title">Information you provide to us</h6>
              <p>We will use this information to:</p>
              <ul>
                <li>Carry out our obligations arising from any User Agreements entered into between you and us and to provide you with the Services;</li>
                <li>Better understand our users;</li>
                <li>Respond to your enquiries or to process your requests in relation to your information;</li>
                <li>Notify you about important changes or developments to our Services;</li>
                <li>Provide you with information about other goods and services we offer that are similar to those that you have already purchased or enquired about;</li>
                <li>Invite other users to use the Services at your request;</li>
                <li>Keep records in response to any complaints filed by users;</li>
                <li>Ensure that the Services are being presented in the most effective manner for you, making the Services easier for you to use and to provide you with a smooth, efficient, safe and tailored experience; and</li>
                <li>Detect and protect us against error, fraud and other criminal activity, and enforce our <Link to="/term-of-use">Terms of Service</Link>.</li>
              </ul>

              <h6 className="list-sub-title">Information we collect automatically</h6>
              <p>We will use this information to:</p>
              <ul>
                <li>Administer the Services and for internal operations, including troubleshooting, data analysis, testing, research, and statistical purposes;</li>
                <li>Improve the Services and user experience to ensure that content is presented in the most effective manner for you;</li>
                <li>Allow you to participate in interactive features of our Services, when you choose to do so; and</li>
                <li>Be used as part of our efforts to keep the Services safe and secure.</li>
              </ul>

              <h6 className="list-sub-title">Information we receive from other sources</h6>
              <p>We may combine this information with information you give to us and information we collect about you. We may use this information and the combined information for the purposes set out above (depending on the information we receive).</p>
            </li>
            <li>
              <h6>SHARING YOUR PERSONAL DATA</h6>
              <p>The following describes the ways we may share your personal data in the normal course of business and in order to provide our Services.</p>
              <p><span className="underline">With your consent:</span> We will disclose your personal data when and if you have agreed that we may do so. We will make this clear to you at the point at which we collect your personal data, including the purpose(s) for which we will use the personal data.</p>
              <p><span className="underline">Service providers:</span> We use third-party service providers to facilitate or outsource some aspects of our Services and some of these service providers will process your personal data, such as when text message notifications are sent to your phone number.</p>
              <p><span className="underline">Promotional purposes:</span> We reserve the right to provide third parties with anonymized, aggregated information about our users (either on a user-wide basis or on a subset user basis) for our own promotional purposes.</p>
              <p><span className="underline">If required by law:</span> We may be required to disclose your personal data by law, in response to a valid order of a court or other authority, or to respond to requests relating to criminal investigations.</p>
              <p><span className="underline">Change of control:</span> If a business transfer or change of business ownership takes place, we may transfer your personal data to the new owner. If this happens, you will be informed of this transfer.</p>
              <p><span className="underline">Protecting our rights:</span> We may disclose your personal data if we feel this is necessary in order to protect or defend our legitimate rights and interests and/or to ensure the safety and security of the Services.</p>
            </li>
            <li>
              <h6>DATA SECURITY</h6>
              <p>
                PlayNice (either itself or through third party service providers) maintains a variety of commercially reasonable electronic and procedural safeguards designed to protect your personal information from unauthorized access and disclosure. For example, PlayNice uses accepted tools and techniques to protect against unauthorized access to PlayNice’s systems. You should be aware that PlayNice has no control over the security of other sites on the Internet that you might visit or with which you might interact even when a link may appear to another site from one of the Services. PlayNice wants you to feel confident using the Services, but no system can be completely secure. Therefore, although PlayNice takes steps to secure your information, PlayNice does not promise, and you should not expect, that your personal information or communications will always remain secure. In the event of a breach of the confidentiality or security of your information, including your personal information, PlayNice will notify you as necessary and if possible so you can take appropriate protective steps. PlayNice may notify you under such circumstances using the e-mail address(es) that PlayNice has on record for you. You also should carefully handle and disclose your personal information.
              </p>
            </li>
            <li>
              <h6>UPDATING AND RETENTION OF YOUR DATA</h6>
              <p>
                For instructions on accessing or updating your information, to request a copy of the information we hold about you or to otherwise request assistance with these rights please contact us using the information below.
              </p>
              <p>
                We retain your personal information as long as we are providing the Services to you. We retain your personal information after we cease providing Services to you to the extent necessary to fulfill your request (for example, keeping your email address to make sure it’s not on our mailing list); to comply with our legal and regulatory obligations; for the purpose of fraud monitoring, detection and prevention; to comply with our tax, accounting, and financial reporting obligations; or where we are required to retain the data by our contractual commitments to our partners. Where we retain data, we do so in accordance with any limitation periods and records retention obligations that are imposed by applicable law.
              </p>
              <p>
                You can update or delete your information by logging into your Account or by emailing us at info@letsplaynice.com. Even if we delete your information from our systems, keep in mind that the deletion by our third party providers may not be immediate and that the deleted information may persist in backup copies for a reasonable period of time.
              </p>
            </li>
            <li>
              <h6>CHILDREN</h6>
              <p>
                PlayNice is not intended for use by children under the age of 13. Individuals under the age of 13 are not permitted to use the Services and must not attempt to register an account or submit any personal data to us. We do not knowingly collect any personal data from any person who is under the age of 13 or allow them to register an account. If it comes to our attention that we have collected personal data from a person under the age of 13, we will delete this information as quickly as possible.
              </p>
              <p>
                If you are a parent or guardian and you learn that your child (under the age of 13) has provided us with personal data, please notify us immediately at info@letsplaynice.com.
              </p>
            </li>
            <li>
              <h6>YOUR RIGHTS</h6>
              <h6 className="sub-title-without-underline">California Residents</h6>
              <p>This section provides additional details about the personal information we collect about California consumers and the rights afforded to them under California law.</p>
              <p>If you are a resident of California, in addition to the rights set forth in this Privacy Policy, you generally have the right to request information from us regarding the manner in which we share certain categories of personal data with third parties for their direct marketing purposes. PlayNice does not currently share personal data with third parties for their direct marketing services.</p>
              <p>For more details about the personal information we have collected over the last 12 months, including the categories of sources, please see the Data We Collect About You section above. We collect this information for the business and commercial purposes described in the How Your Personal Information is Used section above. We share this information with the categories of third parties described in the Sharing Your Personal Data section above. PlayNice does not sell (as such term is defined in the California Consumer Privacy Act) the personal information we collect (and will not sell it without providing a right to opt out).</p>
            </li>
            <li>
              <h6>VISITING THE SITE AND SERVICES FROM OUTSIDE THE UNITED STATES</h6>
              <p>This Privacy Policy is intended to cover collection of information on the Services from residents of the United States and is not directed to the residents of any other country. If you are visiting the Site or using the Services from outside the United States, please be aware that your information may be transferred to, stored, and processed in the United States where PlayNice’s servers are located and PlayNice’s central database is operated. By using the Services, you understand that your information may be transferred to PlayNice’s facilities and those third parties with whom PlayNice shares it as described in this Privacy Policy. You are responsible for ensuring that information you share conforms to all local data protection laws.</p>
            </li>
            <li>
              <h6>MORE INFORMATION</h6>
              <p>
                It is our goal to make our privacy practices easy to understand. If you have questions, concerns or if you would like more detailed information, please contact us at info@letsplaynice.com.
              </p>
            </li>
            <li>
              <h6>CHANGES TO THIS PRIVACY POLICY</h6>
              <p>
                This Privacy Policy may change from time to time and, if it does, the up-to-date version will always be available on our Services. If we make changes to this Privacy Policy, we will post the revised Privacy Policy on the Services and update the “Last updated” date at the top of this page. Please be sure to periodically check this page to ensure that you are aware of any changes to this Privacy Policy. Any material changes to this Privacy Policy will be communicated by posting a notice of the change on the Services.
              </p>
            </li>
          </ol>
        </div>
      </div>
    );
  }
}

export { PrivacyPolicy as default };
