import { noop } from '../utils/constants';
import { Modal as AntdModal } from 'antd';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import '../../static/assets/styles/components/modal.scss';

class Modal extends Component {
  static propTypes = {
    className: PropTypes.string,
    isVisible: PropTypes.bool,
    title: PropTypes.node,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    hasTopRightCloseButton: PropTypes.bool,
    keyboard: PropTypes.bool,
    footer: PropTypes.arrayOf(PropTypes.element.isRequired),
    children: PropTypes.node,
  };

  static defaultProps = {
    className: '',
    isVisible: false,
    title: null,
    onOk: noop,
    onCancel: noop,
    hasTopRightCloseButton: true,
    keyboard: true,
    footer: null,
    children: null,
  };

  render() {
    const {
      className,
      isVisible,
      title,
      onOk,
      onCancel,
      hasTopRightCloseButton,
      footer,
      children,
      ...otherProps
    } = this.props;

    return (
      <AntdModal
        wrapClassName={className}
        visible={isVisible}
        title={title}
        onOk={onOk}
        onCancel={onCancel}
        closable={hasTopRightCloseButton}
        footer={footer}
        {...otherProps}
      >
        {children}
      </AntdModal>
    );
  }
}

export { Modal as default };
