import states from './states.json';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SelectUSState extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    id: undefined,
    value: undefined,
  };

  _handleChange = event => {
    this.props.onChange(event.target.value);
  };

  render() {
    const idProps = this.props.id ? { id: this.props.id } : {};

    return (
      <select
        {...idProps}
        className={this.props.className}
        onChange={this._handleChange}
        value={this.props.value}
      >
        {states.map(item => (
          <option key={item.abbreviation} value={item.abbreviation}>
            {item.name}
          </option>
        ))}
      </select>
    );
  }
}

export { SelectUSState as default };
