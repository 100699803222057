import * as datasource from './datasource';
import meActionCreator from '../me';
import buildFormActionCreator from '../builders/form';
import buildUpdateDataActionCreator from '../builders/updateData';
import buildLoadDataActionCreator from '../builders/loadData';
import { showModal } from '../../utils/helpers';
import { errorMessages } from '../../utils/constants';
import actionTypes, { namespaces } from '../../actionTypes';

const { COUNTER_SIGN } = actionTypes;

export default {
  ...buildFormActionCreator(namespaces.COUNTER_SIGN),
  ...buildLoadDataActionCreator(namespaces.COUNTER_SIGN),
  ...buildUpdateDataActionCreator(namespaces.COUNTER_SIGN),

  resetMainState() {
    return {
      type: COUNTER_SIGN.RESET_STATE,
    };
  },

  resetState() {
    return dispatch => {
      dispatch(this.resetMainState());

      dispatch(this.resetFormState());

      dispatch(this.resetUpdateDataState());
    };
  },

  initialize(id) {
    return async dispatch => {
      const agreement = await dispatch(this.loadAgreement(id));

      try {
        const myInfo = await dispatch(meActionCreator.checkAuthentication());

        return { agreement, myInfo };
      } catch (err) {
        if (err.message === errorMessages.unauthenticated) {
          err.agreement = agreement;
        }

        throw err;
      }
    };
  },

  setData(payload) {
    return {
      type: COUNTER_SIGN.SET_DATA,
      payload,
    };
  },

  loadAgreement(id) {
    return async dispatch => {
      try {
        dispatch(this.loadDataRequest());

        const { data: agreement } = await datasource.loadAgreement(id);

        dispatch(this.setData(agreement));

        dispatch(this.loadDataSuccess());

        return agreement;
      } catch (error) {
        showModal('Sorry, this link is no longer valid.', 'error', 'Try Another Link');

        dispatch(this.loadDataFailure(error.message));

        throw error;
      }
    };
  },

  counterSign(history, payload) {
    return async dispatch => {
      try {
        dispatch(this.updateDataRequest());

        await datasource.counterSign(payload);

        history.push({
          pathname: '/counter-sign/confirmation',
          search: history.location.search,
        });

        dispatch(this.updateDataSuccess());
      } catch (error) {
        showModal(error.message, 'error');

        dispatch(this.updateDataFailure(error.message));
      }
    };
  },
};
