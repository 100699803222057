import HttpClient from '../../utils/HttpClient';

const httpClient = HttpClient.createInstance();

export const validateCoupon = (code, applyTo) =>
  httpClient.post('/coupons/validate', { code, applyTo });

export const createAccount = payload => httpClient.post('/agreements/create-account', payload);

export const createAgreement = payload => httpClient.post('/agreements/pay-per-play', payload);

export const createSubscription = payload =>
  httpClient.post('/agreements/from-subscription', payload);

export const createAgreementDirectly = payload =>
  httpClient.post('/agreements/from-previous-subscription', payload);
