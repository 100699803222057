import meActionCreator from '../actionCreators/me';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import '../../static/assets/styles/containers/pageHeader.scss';

class UnconnectedPageHeader extends Component {
  static propTypes = {
    isLoadingData: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    fullName: PropTypes.string,

    dispatchCheckAuthentication: PropTypes.func.isRequired,
    dispatchLogout: PropTypes.func.isRequired,

    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    fullName: '',
  };

  async componentDidMount() {
    try {
      await this.props.dispatchCheckAuthentication();
    } catch (e) {}
  }

  handleLogout = () => {
    this.props.dispatchLogout(this.props.history);
  };

  render() {
    const { isLoadingData, isAuthenticated, fullName } = this.props;
    /* eslint-disable prettier/prettier */
    const tabs = !isAuthenticated ? [
      <LinkContainer key="home-1" active={false} to="/home">
        <Nav.Link className="first-nav-link">HOME</Nav.Link>
      </LinkContainer>,
      <LinkContainer key="how-it-works" active={false} to="/how-it-works">
        <Nav.Link>HOW IT WORKS</Nav.Link>
      </LinkContainer>,
      <LinkContainer key="about-us" active={false} to="/about-us">
        <Nav.Link>ABOUT US</Nav.Link>
      </LinkContainer>,
      <LinkContainer key="contact-us" active={false} to="/contact-us">
        <Nav.Link>CONTACT</Nav.Link>
      </LinkContainer>,
      <LinkContainer key="create-agreement-1" active={false} to="/create-agreement">
        <Nav.Link className="create-agreement">CREATE AN AGREEMENT</Nav.Link>
      </LinkContainer>,
      <LinkContainer key="login" active={false} to="/login">
        <Nav.Link>LOGIN</Nav.Link>
      </LinkContainer>,
    ] : [
      <LinkContainer key="home-2" active={false} to="/home">
        <Nav.Link className="first-nav-link">HOME</Nav.Link>
      </LinkContainer>,
      <LinkContainer key="how-it-works" active={false} to="/how-it-works">
        <Nav.Link>HOW IT WORKS</Nav.Link>
      </LinkContainer>,
      <LinkContainer key="about-us" active={false} to="/about-us">
        <Nav.Link>ABOUT US</Nav.Link>
      </LinkContainer>,
      <LinkContainer key="contact-us" active={false} to="/contact-us">
        <Nav.Link>CONTACT</Nav.Link>
      </LinkContainer>,
      <LinkContainer key="create-agreement-2" active={false} to="/create-agreement">
        <Nav.Link className="create-agreement">CREATE AN AGREEMENT</Nav.Link>
      </LinkContainer>,
      <NavDropdown
        key="my-account"
        className="my-account"
        title={<img src="/images/avatar-logo.png" />}
        alignRight={true}
      >
        <LinkContainer active={false} to="/my-dashboard">
          <NavDropdown.Item>Dashboard</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer active={false} to="/account-settings">
          <NavDropdown.Item>Settings</NavDropdown.Item>
        </LinkContainer>
        <NavDropdown.Divider />
        <NavDropdown.Item onSelect={this.handleLogout}>logout</NavDropdown.Item>
      </NavDropdown>,
    ];
    /* eslint-enable prettier/prettier */

    return (
      <Navbar
        fixed="top"
        variant="light"
        bg="white"
        expand="xl"
        collapseOnSelect={true}
        className="pn-page-header"
      >
        <Container className="nav-content-container">
          <Navbar.Brand>
            <LinkContainer to="/home">
              <img src="/images/new-logo.png" alt="logo" />
            </LinkContainer>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="pn-navbar-toggle" />
          <Navbar.Collapse id="pn-navbar-toggle" className="justify-content-end">
            <Nav>{isLoadingData ? null : tabs}</Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

const mapStateToProps = state => ({
  isLoadingData: state.me.loadData.isLoadingData,
  isAuthenticated: state.me.main.isAuthenticated,
  fullName: state.me.main.fullName,
});

const mapDispatchToProps = dispatch => ({
  dispatchCheckAuthentication() {
    return dispatch(meActionCreator.checkAuthentication());
  },

  dispatchLogout(history) {
    dispatch(meActionCreator.logout(history));
  },
});

const PageHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UnconnectedPageHeader));

export { UnconnectedPageHeader, PageHeader as default };
