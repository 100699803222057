import actionCreator from '../../actionCreators/createAgreement';
import commonActionCreator from '../../actionCreators/common';
import StepsHeader from '../../components/headers/Steps';
import Button from '../../components/Button';
import { formatPhoneNumber, showModal } from '../../utils/helpers';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../../static/assets/styles/containers/createAgreement/verifyPhoneNumber.scss';

class UnconnectedVerifyPhoneNumber extends Component {
  static propTypes = {
    isUpdatingData: PropTypes.bool.isRequired,
    formCode: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    dob: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    toFullName: PropTypes.string.isRequired,
    toPhoneNumber: PropTypes.string.isRequired,

    dispatchSetFormField: PropTypes.func.isRequired,
    dispatchCheckVerification: PropTypes.func.isRequired,
    dispatchRequestVerification: PropTypes.func.isRequired,

    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  componentDidMount() {
    if (
      !this.props.fullName ||
      !this.props.dob ||
      !this.props.phoneNumber ||
      !this.props.state ||
      !this.props.toFullName ||
      !this.props.toPhoneNumber
    ) {
      this.props.history.push('/create-agreement/basic-info');
    }
  }

  handleInputChange(field, event) {
    this.props.dispatchSetFormField(field, event.target.value);
  }

  handleVerifyPhoneNumber = event => {
    event.preventDefault();

    const { formCode, dispatchCheckVerification, phoneNumber, history } = this.props;

    if (!formCode) {
      showModal('Verification code is required!');
      return;
    }

    dispatchCheckVerification(formCode, formatPhoneNumber(phoneNumber), () => {
      history.push('/create-agreement/agreement-review');
    });
  };

  handleResendCode = () => {
    const { dispatchRequestVerification, phoneNumber } = this.props;

    dispatchRequestVerification(formatPhoneNumber(phoneNumber), 'sms', () => {
      showModal('Please check your phone for the verification code!', 'success', 'Okay');
    });
  };

  render() {
    return (
      <div className="verify-phone-number">
        <StepsHeader currentStepIndex={0} />

        <main>
          <form>
            <div className="inputs">
              <h3 className="with-subtitle">Phone Number Verification</h3>
              <h4>We sent a verification code to {this.props.phoneNumber}</h4>

              <div className="input-container">
                <label htmlFor="code">Verification Code</label>
                <input
                  autoComplete="one-time-code"
                  placeholder="123456"
                  id="code"
                  value={this.props.formCode}
                  onChange={this.handleInputChange.bind(this, 'code')}
                />
              </div>
            </div>

            <div className="form-row">
              <p>
                Didn't get the code?{' '}
                <span className="link" onClick={this.handleResendCode}>
                  Resend again
                </span>
              </p>
              <Button
                type="submit"
                onClick={this.handleVerifyPhoneNumber}
                disabled={this.props.isUpdatingData}
              >
                Submit
              </Button>
            </div>
          </form>
        </main>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isUpdatingData: state.common.updateData.isUpdatingData,
  formCode: state.createAgreement.form.code.value,

  fullName: state.createAgreement.form.fullName.value,
  dob: state.createAgreement.form.dob.value,
  phoneNumber: state.createAgreement.form.phoneNumber.value,
  state: state.createAgreement.form.state.value,
  toFullName: state.createAgreement.form.toFullName.value,
  toPhoneNumber: state.createAgreement.form.toPhoneNumber.value,
});

const mapDispatchToProps = dispatch => {
  return {
    dispatchSetFormField(field, value) {
      dispatch(actionCreator.setFormField(field, value));
    },

    dispatchCheckVerification(...params) {
      dispatch(commonActionCreator.checkVerification(...params));
    },

    dispatchRequestVerification(...params) {
      dispatch(commonActionCreator.requestVerification(...params));
    },
  };
};

const VerifyPhoneNumber = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedVerifyPhoneNumber);

export { UnconnectedVerifyPhoneNumber, VerifyPhoneNumber as default };
