import actionCreator from '../../actionCreators/createAccount';
import H2 from '../../components/H2';
import CheckboxInput from '../../components/CheckboxInput';
import Button from '../../components/Button';
import PasswordInput from '../../components/PasswordInput';
import UsStateSelect from '../../components/UsStateSelect';
import { showModal, parseQueryString, formatDate, isPasswordStrongEnough } from '../../utils/helpers';
import DropdownDate from 'react-dropdown-date';
import { isAfter, isBefore, parse } from 'date-fns';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../../static/assets/styles/containers/counterSign/createAccount.scss';

/*
 * This page should only be used in Counter Sign flow.
 */
class UnconnectedCreateAccount extends Component {
  static propTypes = {
    isUpdatingData: PropTypes.bool.isRequired,
    formFullName: PropTypes.string.isRequired,
    formState: PropTypes.string.isRequired,
    formDob: PropTypes.string,
    formEmail: PropTypes.string.isRequired,
    formPassword: PropTypes.string.isRequired,
    formTermsAccepted: PropTypes.bool.isRequired,
    formEmailSubscribed: PropTypes.bool.isRequired,
    toPhoneNumberFromAgreement: PropTypes.string.isRequired,
    agreementRequestId: PropTypes.string.isRequired,

    dispatchSetFormField: PropTypes.func.isRequired,
    dispatchCreateAccount: PropTypes.func.isRequired,

    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    formDob: undefined,
  };

  constructor(props) {
    super(props);

    const queryParams = parseQueryString(props.location.search);
    const redirectUrl = queryParams.redirect_url || '';
    const absPath = redirectUrl.replace(/^[/\\]*/, '/');

    this.redirectUrl = absPath;
  }

  handleInputChange(field, event) {
    this.props.dispatchSetFormField(field, event.target ? event.target.value : event);
  }

  handleCheckBoxChange = (field, isChecked) => {
    this.props.dispatchSetFormField(field, isChecked);
  };

  handleCreateAccount = event => {
    event.preventDefault();

    const {
      formFullName,
      formState,
      toPhoneNumberFromAgreement,
      formDob,
      formEmail,
      formPassword,
      formTermsAccepted,
      formEmailSubscribed,
      history,
    } = this.props;

    if (!this.checkIfAbleToCounterSign()) {
      showModal(
        'We are sorry that both parties need to be under 18 and above 13 to sign the pledge.',
        'warning',
        'Confirm'
      );
    } else if (!formTermsAccepted) {
      showModal('Terms need to be accepted for account creation!');
    } else if (!formFullName || !formState || !formDob || !formEmail || !formPassword) {
      showModal('At least one of the fields is missing!');
    } else if (!isPasswordStrongEnough(formPassword)) {
      showModal('The password must contain at least one uppercase letter and one number and must be at least 8 characters!');
    } else {
      this.props.dispatchCreateAccount(history, this.redirectUrl, {
        fullName: formFullName,
        state: formState,
        phoneNumber: toPhoneNumberFromAgreement,
        dob: formDob,
        email: formEmail,
        password: formPassword,
        isPhoneVerified: true,
        termsAccepted: formTermsAccepted,
        emailSubscribed: formEmailSubscribed,
      });
    }
  };

  checkIfAbleToCounterSign = () => {
    const today = new Date();
    const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
    const thirteenYearsAgo = new Date(today.getFullYear() - 13, today.getMonth(), today.getDate());
    const isRequesterUnderAgeOfEighteen = isAfter(
      parse(this.props.formDob, 'yyyy-MM-dd', today),
      eighteenYearsAgo
    );
    const parsedReceiverDob = parse(this.props.formDob, 'yyyy-MM-dd', today);
    const isReceiverBetweenAgeOfEighteenAndThirteen =
      isAfter(parsedReceiverDob, eighteenYearsAgo) && isBefore(parsedReceiverDob, thirteenYearsAgo);

    return isRequesterUnderAgeOfEighteen ? isReceiverBetweenAgeOfEighteenAndThirteen : true;
  };

  render() {
    if (!this.props.agreementRequestId) {
      this.props.history.goBack();
      return null;
    }

    return (
      <div id="create-account">
        <main>
          <H2>Create Account</H2>
          <h4>
            Signing up for PlayNice is fast and easy, you can start create your first agreement in a
            few seconds.
          </h4>

          <form>
            <div className="inputs">
              <div className="input-container">
                <label htmlFor="full-name">Full Name</label>
                <input
                  placeholder="John Doe"
                  id="full-name"
                  value={this.props.formFullName}
                  onChange={this.handleInputChange.bind(this, 'fullName')}
                />
              </div>

              <div className="input-container">
                <label htmlFor="state">State</label>
                <UsStateSelect id="state" onChange={this.handleInputChange.bind(this, 'state')} />
              </div>

              <div className="input-container">
                <label htmlFor="phone-number">Phone Number</label>
                <input
                  type="tel"
                  placeholder="(555) 555-5555"
                  id="phone-number"
                  value={this.props.toPhoneNumberFromAgreement}
                  onChange={this.handleInputChange.bind(this, 'phoneNumber')}
                  disabled={true}
                />
              </div>

              <div className="input-container">
                <label htmlFor="dropdown-date">DOB</label>
                <DropdownDate
                  startDate="1905-01-01" // 'yyyy-mm-dd' format only
                  // selectedDate={formDob} // 'yyyy-mm-dd' format only
                  order={['month', 'day', 'year']}
                  onDateChange={date => {
                    const formattedDate = formatDate(date);

                    this.handleInputChange('dob', formattedDate);
                  }}
                  classes={{
                    dateContainer: 'custom-dropdown-date',
                    year: 'custom-dropdown-date-select',
                    month: 'custom-dropdown-date-select',
                    day: 'custom-dropdown-date-select',
                  }}
                  defaultValues={{ month: 'Month', day: 'Day', year: 'Year' }}
                  options={{ yearReverse: true, monthShort: true, monthCaps: false }}
                />
              </div>

              <div className="input-container">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  placeholder="you@email.com"
                  id="email"
                  value={this.props.formEmail}
                  onChange={this.handleInputChange.bind(this, 'email')}
                />
              </div>

              <div className="input-container">
                <label htmlFor="password">Create a password</label>
                <PasswordInput
                  value={this.props.formPassword}
                  onChange={this.handleInputChange.bind(this, 'password')}
                  placeholder="7 chars with a symbol and a number"
                  id="password"
                  autoComplete="current-password"
                />
              </div>
            </div>

            <hr className="inputs-agreement-top-separator" />

            <CheckboxInput
              onChange={this.handleCheckBoxChange.bind(this, 'termsAccepted')}
              value={this.props.formTermsAccepted}
            >
              I agree to PlayNice’s{' '}
              <Link to="/term-of-use">
                Terms of Service
              </Link>{' '}
              and <Link to="/term-of-use">E-Sign Consent</Link>.
            </CheckboxInput>
            <CheckboxInput
              onChange={this.handleCheckBoxChange.bind(this, 'emailSubscribed')}
              value={this.props.formEmailSubscribed}
            >
              Please, from time to time, email me additional information on how PlayNice can help me
              stay safe from online harm.
            </CheckboxInput>

            <hr className="inputs-agreement-bottom-separator" />

            <div className="form-row">
              <p>
                Already have a PlayNice account?{' '}
                <Link to={`/login${this.props.location.search}`}>Sign in</Link>
              </p>
              <Button
                type="submit"
                onClick={this.handleCreateAccount}
                disabled={this.props.isUpdatingData}
              >
                Create My Account
              </Button>
            </div>
          </form>
        </main>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isUpdatingData: state.createAccount.updateData.isUpdatingData,
  formFullName: state.createAccount.form.fullName.value,
  formState: state.createAccount.form.state.value,
  formDob: state.createAccount.form.dob.value,
  formEmail: state.createAccount.form.email.value,
  formPassword: state.createAccount.form.password.value,
  formTermsAccepted: state.createAccount.form.termsAccepted.value,
  formEmailSubscribed: state.createAccount.form.emailSubscribed.value,
  toPhoneNumberFromAgreement: state.counterSign.main.toPhoneNumber,
  agreementRequestId: state.counterSign.main._id,
});

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetFormField(field, value) {
      dispatch(actionCreator.setFormField(field, value));
    },

    dispatchCreateAccount(...params) {
      dispatch(actionCreator.createAccount(...params));
    },
  };
}

const CreateAccount = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedCreateAccount);

export { UnconnectedCreateAccount, CreateAccount as default };
