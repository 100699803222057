import actionCreator from '../actionCreators/accountSettings';
import meActionCreator from '../actionCreators/me';
import H2 from '../components/H2';
import CheckboxInput from '../components/CheckboxInput';
import Button from '../components/Button';
import PasswordInput from '../components/PasswordInput';
import UsStateSelect from '../components/UsStateSelect';
import {
  showModal,
  //formatDate,
  willRedirectBackFromLogin,
  isPasswordStrongEnough,
} from '../utils/helpers';
import { errorMessages } from '../utils/constants';
//import DropdownDate from 'react-dropdown-date';
//import { addDays, format, parse, subYears } from 'date-fns';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../static/assets/styles/containers/accountSettings.scss';

class UnconnectedAccountSettings extends Component {
  static propTypes = {
    isLoadingData: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    isUpdatingData: PropTypes.bool.isRequired,
    formFullName: PropTypes.string.isRequired,
    formState: PropTypes.string,
    formPhoneNumber: PropTypes.string.isRequired,
    formDob: PropTypes.string,
    formEmail: PropTypes.string.isRequired,
    formPassword: PropTypes.string.isRequired,
    formEmailSubscribed: PropTypes.bool.isRequired,

    dispatchResetState: PropTypes.func.isRequired,
    dispatchSetFormField: PropTypes.func.isRequired,
    dispatchCheckAuthentication: PropTypes.func.isRequired,
    dispatchUpdateProfile: PropTypes.func.isRequired,

    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    formState: undefined,
    formDob: undefined,
  };

  async componentDidMount() {
    try {
      await this.props.dispatchCheckAuthentication();
    } catch (err) {
      if (err.message === errorMessages.unauthenticated) {
        willRedirectBackFromLogin(this.props.history);
      }
    }
  }

  isSettingInitialDob = true; // to fix the dob dropdown component not set initial value correctly

  handleInputChange(field, event) {
    this.props.dispatchSetFormField(field, event.target ? event.target.value : event);
  }

  handleCheckBoxChange = (field, isChecked) => {
    this.props.dispatchSetFormField(field, isChecked);
  };

  handleSaveChanges = event => {
    event.preventDefault();

    const {
      formFullName,
      formState,
      formDob,
      formEmail,
      formPassword,
      formEmailSubscribed,
    } = this.props;

    if (!formFullName || !formState || !formDob || !formEmail) {
      showModal('At least one of the fields is missing!');
    } else if (formPassword && !isPasswordStrongEnough(formPassword)) {
      showModal('The password must contain at least one uppercase letter and one number and must be at least 8 characters!');
    } else {
      this.props.dispatchUpdateProfile({
        fullName: formFullName,
        state: formState,
        dob: formDob,
        email: formEmail,
        emailSubscribed: formEmailSubscribed,
        password: formPassword,
      });
    }
  };

  render() {
    if (this.props.isLoadingData || !this.props.isAuthenticated) {
      return null;
    }

    if (this.props.formDob) {
      this.isSettingInitialDob = false;
    }

    return (
      <div className="account-settings">
        <main>
          <H2>Account Settings</H2>

          <form>
            <div className="inputs">
              <div className="input-container">
                <label htmlFor="full-name">Full Name</label>
                <input
                  placeholder="John Doe"
                  id="full-name"
                  value={this.props.formFullName}
                  onChange={this.handleInputChange.bind(this, 'fullName')}
                />
              </div>

              <div className="input-container">
                <label htmlFor="state">State</label>
                <UsStateSelect
                  id="state"
                  onChange={this.handleInputChange.bind(this, 'state')}
                  value={this.props.formState}
                />
              </div>

              <div className="input-container">
                <label htmlFor="phone-number">Phone Number</label>
                <input
                  type="tel"
                  placeholder="(555) 555-5555"
                  id="phone-number"
                  value={this.props.formPhoneNumber}
                  onChange={this.handleInputChange.bind(this, 'phoneNumber')}
                  disabled={true}
                />
              </div>

              <div className="input-container">
                <label htmlFor="dropdown-date">DOB</label>
                <input type="date" id="dropdown-date" value={this.props.formDob} disabled={true} />
                {/*
                <DropdownDate
                  startDate="1905-01-01" // 'yyyy-mm-dd' format only
                  selectedDate={format(addDays(parse(this.props.formDob, 'yyyy-MM-dd', subYears(new Date(), 18)), this.isSettingInitialDob ? 1 : 0), 'MM/dd/yyyy')} // 'yyyy-mm-dd' format only
                  order={['month', 'day', 'year']}
                  onDateChange={date => {
                    const formattedDate = formatDate(date);

                    this.handleInputChange('dob', formattedDate);
                  }}
                  classes={{
                    dateContainer: 'custom-dropdown-date',
                    year: 'custom-dropdown-date-select',
                    month: 'custom-dropdown-date-select',
                    day: 'custom-dropdown-date-select',
                  }}
                  defaultValues={{ month: 'Month', day: 'Day', year: 'Year' }}
                  options={{ yearReverse: true, monthShort: true, monthCaps: false }}
                />
                */}
              </div>

              <div className="input-container">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  placeholder="you@email.com"
                  id="email"
                  value={this.props.formEmail}
                  onChange={this.handleInputChange.bind(this, 'email')}
                />
              </div>

              <div className="input-container">
                <label htmlFor="password">Create a password</label>
                <PasswordInput
                  value={this.props.formPassword}
                  onChange={this.handleInputChange.bind(this, 'password')}
                  placeholder="7 chars with a symbol and a number"
                  id="password"
                  autoComplete="current-password"
                />
              </div>
            </div>

            <hr className="inputs-agreement-top-separator" />

            <CheckboxInput
              onChange={this.handleCheckBoxChange.bind(this, 'emailSubscribed')}
              value={this.props.formEmailSubscribed}
            >
              Please, from time to time, email me additional information on how PlayNice can help me
              stay safe from online harm.
            </CheckboxInput>

            <hr className="inputs-agreement-bottom-separator" />

            <div className="form-row">
              <Button
                className="cancel-changes-button"
                type="button"
                onClick={this.props.dispatchResetState}
                disabled={this.props.isUpdatingData}
              >
                CANCEL CHANGES
              </Button>
              <Button
                type="submit"
                onClick={this.handleSaveChanges}
                disabled={this.props.isUpdatingData}
              >
                SAVE CHANGES
              </Button>
            </div>
          </form>
        </main>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const accountSettingsForm = state.accountSettings.form;
  const meMain = state.me.main;

  return {
    isLoadingData: state.me.loadData.isLoadingData,
    isAuthenticated: state.me.main.isAuthenticated,
    isUpdatingData: state.accountSettings.updateData.isUpdatingData,
    formFullName: accountSettingsForm.fullName.dirty
      ? accountSettingsForm.fullName.value
      : meMain.fullName || '',
    formState: accountSettingsForm.state.dirty ? accountSettingsForm.state.value : meMain.state,
    formPhoneNumber: accountSettingsForm.phoneNumber.dirty
      ? accountSettingsForm.phoneNumber.value
      : meMain.phoneNumber || '',
    formDob: accountSettingsForm.dob.dirty ? accountSettingsForm.dob.value : meMain.dob,
    formEmail: accountSettingsForm.email.dirty
      ? accountSettingsForm.email.value
      : meMain.email || '',
    formPassword: accountSettingsForm.password.dirty ? accountSettingsForm.password.value : '',
    formEmailSubscribed: accountSettingsForm.emailSubscribed.dirty
      ? accountSettingsForm.emailSubscribed.value
      : meMain.emailSubscribed || false,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchResetState() {
      dispatch(actionCreator.resetState());
    },

    dispatchSetFormField(field, value) {
      dispatch(actionCreator.setFormField(field, value));
    },

    dispatchCheckAuthentication() {
      return dispatch(meActionCreator.checkAuthentication());
    },

    dispatchUpdateProfile(...params) {
      dispatch(actionCreator.updateProfile(...params));
    },
  };
}

const AccountSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedAccountSettings);

export { UnconnectedAccountSettings, AccountSettings as default };
