import SelectUsState from './SelectUsState';
import { generateUniqueId } from '../../utils/helpers';
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import '../../../static/assets/styles/components/usStateSelect.scss';

export default function UsStateSelect({ className, id, onChange, value }) {
  const clazzName = cx('us-state-select', className);

  return (
    <SelectUsState
      className={clazzName}
      id={id || generateUniqueId()}
      onChange={onChange}
      value={value}
    />
  );
}

UsStateSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
};

UsStateSelect.defaultProps = {
  className: '',
  id: undefined,
  value: undefined,
};
