import BasicInfo from './BasicInfo';
import VerifyPhoneNumber from './VerifyPhoneNumber';
import AgreementReview from './AgreementReview';
import PaymentAndSend from './PaymentAndSend';
import { Elements } from 'react-stripe-elements';
import { Route, Redirect, Switch } from 'react-router-dom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import '../../../static/assets/styles/containers/createAgreement/index.scss';

// eslint-disable-next-line react/prefer-stateless-function
class CreateAgreement extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    return (
      <div className="create-agreement">
        <Elements>
          <Switch>
            <Route
              exact={true}
              path={`${this.props.match.path}/basic-info`}
              component={BasicInfo}
            />
            <Route
              exact={true}
              path={`${this.props.match.path}/verify-phone-number`}
              component={VerifyPhoneNumber}
            />
            <Route
              exact={true}
              path={`${this.props.match.path}/agreement-review`}
              component={AgreementReview}
            />
            <Route
              exact={true}
              path={`${this.props.match.path}/payment-and-send`}
              component={PaymentAndSend}
            />

            <Redirect
              from={`${this.props.match.path}`}
              to={{
                pathname: `${this.props.match.path}/basic-info`,
              }}
            />
          </Switch>
        </Elements>
      </div>
    );
  }
}

export { CreateAgreement as default };
