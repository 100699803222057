import * as datasource from './datasource';
import buildFormActionCreator from '../builders/form';
import buildUpdateDataActionCreator from '../builders/updateData';
import { showModal } from '../../utils/helpers';
import actionTypes, { namespaces } from '../../actionTypes';

const { FORGOT_PASSWORD } = actionTypes;

export default {
  ...buildFormActionCreator(namespaces.FORGOT_PASSWORD),
  ...buildUpdateDataActionCreator(namespaces.FORGOT_PASSWORD),

  resetMainState() {
    return {
      type: FORGOT_PASSWORD.RESET_STATE,
    };
  },

  resetState() {
    return dispatch => {
      dispatch(this.resetMainState());

      dispatch(this.resetFormState());

      dispatch(this.resetUpdateDataState());
    };
  },

  forgotPassword(email) {
    return async dispatch => {
      try {
        dispatch(this.updateDataRequest());

        await datasource.forgotPassword(email);

        showModal(
          "If your email address is in our system, the instructions for resetting your password were sent. If you haven't received an email after a few minutes, please check your spam folder!",
          'success',
          'Okay'
        );

        dispatch(this.updateDataSuccess());
      } catch (error) {
        showModal(error.message, 'error');

        dispatch(this.updateDataFailure(error.message));
      }
    };
  },
};
