import actionCreator from '../../actionCreators/createAgreement';
import StepsHeader from '../../components/headers/Steps';
import PaymentRequestButtonForm from '../../components/PaymentRequestButtonForm';
import Button from '../../components/Button';
import PasswordInput from '../../components/PasswordInput';
import CheckboxInput from '../../components/CheckboxInput';
import { formatPhoneNumber, showModal, isPasswordStrongEnough, isEmailValid } from '../../utils/helpers';
import { plans, paymentMethods } from '../../utils/constants';
import { isBefore, parse } from 'date-fns';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';

import '../../../static/assets/styles/containers/createAgreement/paymentAndSend.scss';

class UnconnectedPaymentAndSend extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    isUpdatingData: PropTypes.bool.isRequired,
    formPlan: PropTypes.oneOf(Object.values(plans).map(p => p.displayName)).isRequired,
    formEmail: PropTypes.string.isRequired,
    formPassword: PropTypes.string.isRequired,
    formTermsAccepted: PropTypes.bool.isRequired,
    formDiscountCode: PropTypes.string.isRequired,
    isDiscounted: PropTypes.bool.isRequired,
    discountedPercentage: PropTypes.number.isRequired,
    isProcessingPayment: PropTypes.bool.isRequired,
    fullName: PropTypes.string.isRequired,
    dob: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    toFullName: PropTypes.string.isRequired,
    toPhoneNumber: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    signatureDataUrl: PropTypes.string.isRequired,
    signatureDated: PropTypes.string.isRequired,

    dispatchResetMainState: PropTypes.func.isRequired,
    dispatchValidateCoupon: PropTypes.func.isRequired,
    dispatchSetFormField: PropTypes.func.isRequired,
    dispatchProcessPaymentStart: PropTypes.func.isRequired,
    dispatchProcessPaymentEnd: PropTypes.func.isRequired,
    dispatchCreateAccountAndAgreement: PropTypes.func.isRequired,
    dispatchCreateAccountAndSubscription: PropTypes.func.isRequired,

    stripe: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  state = {
    isCreatingAccount: true,
  };

  componentDidMount() {
    if (this.props.isAuthenticated) {
      if (
        !this.props.toFullName ||
        !this.props.toPhoneNumber ||
        !this.props.signatureDataUrl ||
        !this.props.signatureDated
      ) {
        this.props.history.push('/create-agreement/basic-info');
      }
    } else if (
      !this.props.fullName ||
      !this.props.dob ||
      !this.props.phoneNumber ||
      !this.props.state ||
      !this.props.toFullName ||
      !this.props.toPhoneNumber ||
      !this.props.code ||
      !this.props.signatureDataUrl ||
      !this.props.signatureDated
    ) {
      this.props.history.push('/create-agreement/basic-info');
    }

    window.scrollTo(0, 0);
  }

  static isOverAgeOfEighteen(dobValue) {
    const today = new Date();
    const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

    return isBefore(parse(dobValue, 'yyyy-MM-dd', today), eighteenYearsAgo);
  }

  handleInputChange(field, valueOrEvent) {
    const isChoosingPlan = typeof valueOrEvent === 'string';
    const isCheckboxField = typeof valueOrEvent === 'boolean';
    let valueToSet;

    if (isChoosingPlan) {
      valueToSet = valueOrEvent;
      this.props.dispatchResetMainState();
    } else if (isCheckboxField) {
      valueToSet = valueOrEvent;
    } else {
      valueToSet = valueOrEvent.target.value;
    }

    this.props.dispatchSetFormField(field, valueToSet);
  }

  handleValidateCoupon = event => {
    event.preventDefault(); // Prevent default form submission

    this.props.dispatchValidateCoupon(this.props.formDiscountCode, this.props.formPlan);
  };

  createToken = async () => {
    let token;
    let error;

    try {
      const tokenWrapper = await this.props.stripe.createToken();

      token = tokenWrapper.token;
      error = tokenWrapper.error;
    } catch (err) {
      error = err;
    }

    return { token, error };
  };

  shouldPay() {
    const { dob, isDiscounted, discountedPercentage } = this.props;

    return (
      UnconnectedPaymentAndSend.isOverAgeOfEighteen(dob) &&
      !(isDiscounted && discountedPercentage === 100)
    );
  }

  async handlePayment(paymentMethod, event) {
    const {
      isAuthenticated,
      history,
      formPlan,
      phoneNumber,
      fullName,
      dob,
      state,
      formPassword: password,
      formEmail: email,
      formDiscountCode: discountCode,
      formTermsAccepted,
      signatureDataUrl,
      signatureDated,
      isDiscounted,
      toFullName,
      toPhoneNumber,
      dispatchProcessPaymentStart,
      dispatchCreateAccountAndAgreement,
      dispatchCreateAccountAndSubscription,
      dispatchProcessPaymentEnd,
    } = this.props;

    dispatchProcessPaymentStart();

    if (paymentMethod === paymentMethods.creditCard) {
      event.preventDefault(); // Prevent default form submission
    }

    if (!isAuthenticated && (!email || !password || !formPlan)) {
      showModal('At least one of the fields is missing!');
    } else if (!isAuthenticated && !isEmailValid(email)) {
      showModal('The email is invalid!');
    } else if (!isAuthenticated && !isPasswordStrongEnough(password)) {
      showModal('The password must contain at least one uppercase letter and one number and must be at least 8 characters!');
    } else if (!isAuthenticated && this.state.isCreatingAccount && !formTermsAccepted) {
      showModal('Terms need to be accepted for account creation!');
    } else if (
      formPlan === plans.payPerPlay.displayName ||
      !UnconnectedPaymentAndSend.isOverAgeOfEighteen(dob)
    ) {
      let error;
      let token;

      if (this.shouldPay()) {
        if (paymentMethod === paymentMethods.creditCard) {
          const tokenWrapper = await this.createToken();

          error = tokenWrapper.error;
          token = tokenWrapper.token;
        } else {
          token = event.token;
        }
      }

      if (error) {
        showModal(error.message);
      } else {
        await dispatchCreateAccountAndAgreement(
          isAuthenticated,
          history,
          {
            phoneNumber: formatPhoneNumber(phoneNumber),
            fullName,
            dob,
            state,
            password,
            email,
            discountCode: isDiscounted ? discountCode : null,
          },
          {
            tokenId: token && token.id,
            signatureDataUrl,
            signatureDated,
            phoneNumber: formatPhoneNumber(phoneNumber),
            email,
            fullName,
            dob,
            state,
            toFullName,
            toPhoneNumber: formatPhoneNumber(toPhoneNumber),
            isDiscounted,
          },
          event.complete,
          !this.state.isCreatingAccount
        );
      }
    } else if (formPlan === plans.unlimitedOneYear.displayName) {
      let error;
      let token;

      if (paymentMethod === paymentMethods.creditCard) {
        const tokenWrapper = await this.createToken();

        error = tokenWrapper.error;
        token = tokenWrapper.token;
      } else {
        token = event.token;
      }

      if (error) {
        showModal(error.message);
      } else {
        await dispatchCreateAccountAndSubscription(
          isAuthenticated,
          history,
          {
            phoneNumber: formatPhoneNumber(phoneNumber),
            fullName,
            dob,
            state,
            password,
            email,
          },
          {
            tokenId: token && token.id,
            signatureDataUrl,
            signatureDated,
            toFullName,
            toPhoneNumber: formatPhoneNumber(toPhoneNumber),
          },
          event.complete,
          !this.state.isCreatingAccount
        );
      }
    }

    dispatchProcessPaymentEnd();
  }

  handlePaymentRequestButtonClick = event => {
    const {
      isAuthenticated,
      formPlan,
      formPassword: password,
      formEmail: email,
      formTermsAccepted,
    } = this.props;

    if (!isAuthenticated && (!email || !password || !formPlan)) {
      event.preventDefault();

      showModal('At least one of the fields is missing!');
    } else if (!isAuthenticated && !isEmailValid(email)) {
      event.preventDefault();

      showModal('The email is invalid!');
    } else if (!isAuthenticated && !isPasswordStrongEnough(password)) {
      event.preventDefault();

      showModal('The password must contain at least one uppercase letter and one number and must be at least 8 characters!');
    } else if (!isAuthenticated && this.state.isCreatingAccount && !formTermsAccepted) {
      event.preventDefault();

      showModal('Terms need to be accepted for account creation!');
    }
  };

  renderOrderSummaryForm() {
    const {
      isAuthenticated,
      isUpdatingData,
      formPlan,
      toFullName,
      toPhoneNumber,
      formDiscountCode,
      isDiscounted,
      discountedPercentage,
    } = this.props;
    const priceToDisplay =
      formPlan === plans.payPerPlay.displayName
        ? plans.payPerPlay.displayPrice
        : plans.unlimitedOneYear.displayPrice;
    const price = Number(priceToDisplay.slice(1));
    const ccProcessingFee = 0.3;

    return (
      <form>
        <h3>Order Summery</h3>

        <div className="space-between-row">
          <p className="order">Friendship Agreement with {toFullName}</p>
          {/*
          <button className="edit" type="button">
            Edit
          </button>
          */}
        </div>
        <p className="phone-number">{toPhoneNumber}</p>

        <h5>
          <div className="line-separator" />
        </h5>

        <div className="space-between-row">
          <p>{formPlan}</p>
          <p>{priceToDisplay}</p>
        </div>
        <div className="space-between-row">
          <p>CC Processing Fee</p>
          <p>$0.30</p>
        </div>

        <h5>
          <div className="line-separator" />
        </h5>

        {!isAuthenticated &&
          (isDiscounted ? (
            [
              <div key="Discount Code Applied" className="space-between-row">
                <p className="discount-title">Discount Code Applied</p>
                <p className=" discount-title">-${((price + ccProcessingFee) * discountedPercentage) / 100}</p>
              </div>,
              <p key="percent off" className="discount-description">
                {formDiscountCode} ({discountedPercentage}% off)
              </p>,
            ]
          ) : (
            <div className="inputs inline-input-with-button">
              <div className="input-container">
                <input
                  placeholder="Discount Code"
                  value={formDiscountCode}
                  onChange={this.handleInputChange.bind(this, 'discountCode')}
                />
                <Button type="submit" onClick={this.handleValidateCoupon} disabled={isUpdatingData}>
                  Apply
                </Button>
              </div>
            </div>
          ))}

        {!isAuthenticated && (
          <h5>
            <div className="line-separator" />
          </h5>
        )}

        <div className="space-between-row">
          <p>Total</p>
          <p className="price-container">
            <span className="currency">USD</span>
            <span className="price">
              {((price * (100 - discountedPercentage)) / 100).toFixed(2)}
            </span>
          </p>
        </div>
      </form>
    );
  }

  renderUnderAgeDescriptionForm() {
    return (
      <form>
        <div>
          <h3>Important Note:</h3>

          <p>
            Since you are under the age of 18, you are not eligible to sign an enforceable contract,
            but you are eligible to sign a FREE anti Cyber-Bullying pledge with anyone you’d like,
            as long as they are under the age of 18. You’ll both promise to protect each other from
            all forms of bullying, and be part of the movement to end cyber bullying for good. Once
            you both turn 18, you’ll be notified to upgrade your agreement to a fully protected
            adult agreement. Enjoy and be safe!
          </p>
        </div>
      </form>
    );
  }

  render() {
    const {
      isAuthenticated,
      isProcessingPayment,
      formPlan,
      formEmail,
      formPassword,
      dob,
    } = this.props;
    const price =
      formPlan === plans.payPerPlay.displayName
        ? plans.payPerPlay.priceForStripe
        : plans.unlimitedOneYear.priceForStripe;
    const { isCreatingAccount } = this.state;

    return (
      <div className="payment-and-send">
        <StepsHeader currentStepIndex={2} />

        <main className="split-panes">
          <section className="pane payment-pane">
            <form>
              {UnconnectedPaymentAndSend.isOverAgeOfEighteen(dob) && (
                <div className="inputs plans-container">
                  <h3>Select a Plan</h3>

                  <div className="plans">
                    <div
                      className={cx(
                        'border-box',
                        formPlan === plans.unlimitedOneYear.displayName && 'active'
                      )}
                      onClick={this.handleInputChange.bind(
                        this,
                        'plan',
                        plans.unlimitedOneYear.displayName
                      )}
                    >
                      <p>{plans.unlimitedOneYear.displayName}</p>
                      <p>{plans.unlimitedOneYear.displayPrice}</p>
                    </div>

                    <div
                      className={cx(
                        'border-box',
                        formPlan === plans.payPerPlay.displayName && 'active'
                      )}
                      onClick={this.handleInputChange.bind(
                        this,
                        'plan',
                        plans.payPerPlay.displayName
                      )}
                    >
                      <p>{plans.payPerPlay.displayName}</p>
                      <p>{plans.payPerPlay.displayPrice}</p>
                    </div>
                  </div>
                </div>
              )}

              <div className="inputs">
                {!isAuthenticated && [
                  <h3 key="Create Your Account">
                    {isCreatingAccount ? 'Create Your Account' : 'Sign In'}
                  </h3>,
                  isCreatingAccount ? (
                    <p key="already-have-account" className="align-left">
                      Already have a PlayNice account?&nbsp;
                      <span
                        className="link"
                        onClick={() => {
                          this.setState({ isCreatingAccount: false });
                        }}
                      >
                        Sign in
                      </span>
                    </p>
                  ) : (
                    <p key="dont-have-account" className="align-left">
                      Don’t have a PlayNice account?&nbsp;
                      <span
                        className="link"
                        onClick={() => {
                          this.setState({ isCreatingAccount: true });
                        }}
                      >
                        Sign up
                      </span>
                    </p>
                  ),
                  <div key="email" className="input-container">
                    <label htmlFor="email">Email</label>
                    <input
                      autoComplete="email"
                      type="email"
                      placeholder="you@email.com"
                      id="email"
                      value={formEmail}
                      onChange={this.handleInputChange.bind(this, 'email')}
                    />
                  </div>,
                  <div key="password" className="input-container">
                    <label htmlFor="password">{isCreatingAccount ? 'Create ' : ''}Password</label>
                    <PasswordInput
                      value={formPassword}
                      onChange={this.handleInputChange.bind(this, 'password')}
                      placeholder="password"
                      id="password"
                      autoComplete="current-password"
                    />
                  </div>,
                  isCreatingAccount && (
                    <CheckboxInput
                      key="terms-of-service"
                      className="terms-accepted-checkbox"
                      onChange={this.handleInputChange.bind(this, 'termsAccepted')}
                      value={this.props.formTermsAccepted}
                    >
                      I agree to PlayNice’s{' '}
                      <Link to="/term-of-use">
                        Terms of Service
                      </Link>.
                    </CheckboxInput>
                  ),
                ]}
              </div>

              {this.shouldPay() && (
                <div className="inputs">
                  <h3>Make Payment</h3>

                  <PaymentRequestButtonForm
                    productLabel={formPlan}
                    productPrice={price}
                    onToken={this.handlePayment.bind(this, paymentMethods.paymentRequestApi)}
                    onClick={this.handlePaymentRequestButtonClick}
                  />

                  <div className="credit-card-form-container">
                    <CardElement />
                  </div>
                </div>
              )}

              <Button
                className="send-agreement"
                type="submit"
                onClick={this.handlePayment.bind(this, paymentMethods.creditCard)}
                disabled={isProcessingPayment}
              >
                {UnconnectedPaymentAndSend.isOverAgeOfEighteen(dob)
                  ? 'Complete the Payment'
                  : 'Send the Agreement'}
              </Button>
            </form>
          </section>

          <section className="pane summery-pane">
            {UnconnectedPaymentAndSend.isOverAgeOfEighteen(dob)
              ? this.renderOrderSummaryForm()
              : this.renderUnderAgeDescriptionForm()}
          </section>
        </main>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { isAuthenticated } = state.me.main;

  return {
    isAuthenticated,
    isUpdatingData: state.createAgreement.updateData.isUpdatingData,
    formPlan: state.createAgreement.form.plan.value,
    formEmail: isAuthenticated ? state.me.main.email : state.createAgreement.form.email.value,
    formPassword: state.createAgreement.form.password.value,
    formTermsAccepted: state.createAgreement.form.termsAccepted.value,
    formDiscountCode: state.createAgreement.form.discountCode.value,
    isDiscounted: state.createAgreement.main.isDiscounted,
    discountedPercentage: state.createAgreement.main.discountedPercentage,
    isProcessingPayment: state.createAgreement.main.isProcessingPayment,

    fullName: isAuthenticated ? state.me.main.fullName : state.createAgreement.form.fullName.value,
    dob: isAuthenticated ? state.me.main.dob : state.createAgreement.form.dob.value,
    phoneNumber: isAuthenticated
      ? state.me.main.phoneNumber
      : state.createAgreement.form.phoneNumber.value,
    state: isAuthenticated ? state.me.main.state : state.createAgreement.form.state.value,
    toFullName: state.createAgreement.form.toFullName.value,
    toPhoneNumber: state.createAgreement.form.toPhoneNumber.value,
    code: state.createAgreement.form.code.value,
    signatureDataUrl: state.createAgreement.form.signatureDataUrl.value,
    signatureDated: state.createAgreement.form.signatureDated.value,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchResetMainState() {
      dispatch(actionCreator.resetMainState());
    },

    dispatchSetFormField(field, value) {
      dispatch(actionCreator.setFormField(field, value));
    },

    dispatchValidateCoupon(...params) {
      dispatch(actionCreator.validateCoupon(...params));
    },

    dispatchProcessPaymentStart() {
      dispatch(actionCreator.processPaymentStart());
    },

    dispatchProcessPaymentEnd() {
      dispatch(actionCreator.processPaymentEnd());
    },

    dispatchCreateAccountAndAgreement(...params) {
      // need the `return` to fire `dispatchProcessPaymentEnd()`
      return dispatch(actionCreator.createAccountAndAgreement(...params));
    },

    dispatchCreateAccountAndSubscription(...params) {
      // need the `return` to fire `dispatchProcessPaymentEnd()`
      return dispatch(actionCreator.createAccountAndSubscription(...params));
    },
  };
}

const PaymentAndSend = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectStripe(UnconnectedPaymentAndSend));

export { UnconnectedPaymentAndSend, PaymentAndSend as default };
