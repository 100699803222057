import buildUpdateDataReducer from './builders/updateData';
import actionTypes, { namespaces } from '../actionTypes';
import { combineReducers } from 'redux';

const { COMMON } = actionTypes;

const mainInitialState = {};
const updateDataInitialState = {
  isUpdatingData: false,
  error: {
    isVisible: false,
    message: '',
  },
};

const mainReducer = (state = mainInitialState, action) => {
  const actionType = action.type;
  // const actionPayload = action.payload;

  switch (actionType) {
    case COMMON.RESET_STATE:
      return { ...mainInitialState };
    default:
      return state;
  }
};

const commonReducer = combineReducers({
  main: mainReducer,
  updateData: buildUpdateDataReducer(updateDataInitialState, namespaces.COMMON),
});

export { commonReducer as default };
