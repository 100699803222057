import { Link } from 'react-router-dom';
import React, { Component } from 'react';

import '../../static/assets/styles/containers/termOfUse.scss';

class TermOfUse extends Component {
  render() {
    return (
      <div className="term-of-use">
        <div className="content">
          <h5>PLAYNICE</h5>
          <h5 className="sub-title">Terms of Service</h5>

          <p>
            Welcome to www.playniceapp.com. Please read these Terms of Service carefully before using the services offered by Let’s Play Nice, LLC, a Delaware limited liability company (“​PlayNice​”, “​us​”, “​we​”, “​our​”).
          </p>
          <p>
            These Terms of Service (this “​Agreement​”) sets forth the terms and conditions of our relationship and governs your use of the PlayNice website www.playniceapp.com (the “​Site​”) and the mutual respect and confidentiality agreement (“​User Agreement​”) using a form provided by PlayNice, and all other services, features, content, and applications offered by PlayNice (collectively, the “​Services​”). By accessing the Services, you agree to be bound by the terms of this Agreement, which also incorporate <Link to="/privacy-policy">PlayNice’s Privacy Policy​.</Link>
          </p>
          <p>
            IMPORTANT NOTICE: THIS AGREEMENT CONTAINS AN ARBITRATION AGREEMENT, WHICH WILL REQUIRE YOU TO SUBMIT ANY CLAIMS YOU MAY HAVE AGAINST PLAYNICE TO BINDING AND FINAL ARBITRATION. IN ADDITION, UNDER THE ARBITRATION PROVISION, (A) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST PLAYNICE ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, AND (B) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS. BY ENTERING THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND ALL OF THE TERMS OF THIS AGREEMENT, INCLUDING BUT NOT LIMITED TO THE SECTIONS REGARDING ARBITRATION.
          </p>
          <p>
            PLEASE READ THE FOLLOWING TERMS CAREFULLY. BY USING THE SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THE FOLLOWING TERMS AND CONDITIONS. If you are not eligible, or do not agree to this Agreement, then you do not have our permission to use the Services.
          </p>

          <h6>General Agreement between PlayNice and You</h6>
          <ol>
            <li>
              PlayNice may make changes to this Agreement from time to time without specifically notifying you. PlayNice will post the latest Agreement on the Site, but it is up to you to review it before using the Services. If you continue to use the Services after any of these changes, your continued use will mean that you have accepted any changes to the Agreement. If you do not accept updated versions of the Services, PlayNice shall not bear any responsibility or liability for your decision.
            </li>
            <li>
              In addition, some services offered through the Services may be subject to additional terms and conditions specified by PlayNice from time to time, and your use of such services is subject to those additional terms and conditions, which are incorporated into this Agreement by this reference.
            </li>
            <li>
              PLAYNICE IS NOT A LAW FIRM, DOES NOT PRACTICE LAW, AND DOES NOT GIVE LEGAL ADVICE. PLAYNICE IS NOT AND DOES NOT ACT AS YOUR ATTORNEY, AGENT, REPRESENTATIVE OR PARTNER. Therefore, your use of the Services does not create an attorney-client relationship with PlayNice. The Services should not be used as a substitute for the advice of an attorney. All information you provide to us is subject to our <Link to="/privacy-policy">Privacy Policy</Link>, but is not protected by attorney-client privilege or as attorney work product. You understand and agree that PlayNice does not review any information you provide us for legal accuracy or sufficiency, draw legal conclusions, provide opinions about your use of the Services, or apply the law to the facts of your situation. In any action you take through the Services, you are representing yourself.
            </li>
          </ol>

          <h6>Paid Services; Account</h6>
          <ol>
            <li>
              PlayNice may offer the Services to be paid for an annual basis (“​Subscription​”) or on an as-used basis (“​A La Carte​”, and together with the Subscription, the “​Paid Services​”).
            </li>
            <li>
              When you first purchase the Subscription, you will agree to a specific price for a period of one
              year (“​Subscription Period​”). Any changes in the Subscription pricing will take effect at the start
              of the next Subscription Period following the date of the price change.
            </li>
            <li>
              You understand that any and all credit card, debit card, bank, or other payment information supplied by you to pay for any purchase made on the Site (“​Payment Method​”), including the Paid Services (collectively, the “​Transaction​”), is transmitted directly to Stripe or another Third Party Service (defined below), which processes, stores, and otherwise uses such information in order to facilitate payments.
            </li>
            <li>
              You understand that PlayNice does not store your Payment Method informationand that all payments are facilitated separately by Stripe or another the Third Party Service. You will ensure that at all times all Payment Methods are accurate, correct and kept updated. All Transactions made through Stripe are subject to Stripe’s terms and conditions, available at https://stripe.com/us/legal.
            </li>
            <li>
              You agree to ensure that all Payment Method supplied by you is accurate and correct and kept updated with the applicable Third Party Service at all times and that you are fully authorized to use such Payment Method and Third Party Service for purposes of paying for any Transaction.
            </li>
            <li>
              You hereby authorize us to charge your Payment Method for each Transaction. You further authorize us to continue to attempt to charge and/or place holds with respect to all sums described here in, or any prior thereof, to your Payment Method until such amounts are paid in full.
            </li>
            <li>
              You agree to provide an updated Payment Method upon request or if the Payment Method you previously provided is no longer valid. You acknowledge and agree that PlayNice will not have any liability whatsoever for any insufficient funds or other charges incurred by you as a result of attempts to charge, and/or play holds on, your Payment Method in accordance with this Agreement.
            </li>
            <li>
              You agree that once you make payment for any Transaction, you will not seek to cancel, charge back, or otherwise seek a refund on any basis that is fraudulent, misleading, or untrue.
            </li>
            <li>
              You hereby authorize us to charge your Payment Method in advance on a yearly basis and/or to place a hold on your Payment Method with respect to any unpaid charges for your Subscription during the Subscription Period. Your Payment Method will be charged at the beginning of each Subscription Period. You agree to pay the then-current fee for the applicable Subscription, plus any applicable taxes and other charges.
            </li>
            <li>All Paid Services are non-refundable, expect where required by law.</li>
            <li>
              If you believe you have been billed in error for the Paid Services, please notify us within 60 days of the billing date by emailing us at info@letsplaynice.com. PlayNice will not issue reunds or credits after the expiration of this 60-day period, except where required by applicable law.
            </li>
            <li>
              To enter into a User Agreement or to purchase the Paid Services, you must create an account on the Site (“​Account​”). You understand that you responsible for the accuracy and completeness of the information provided through your Account.
            </li>
            <li>
              By creating an Account or by otherwise completing a Transaction, you warrant that you are: (a) at least 18 years old and legally capable of entering into binding contracts, or (b) a parent or legal guardian of a minor between the ages of 13 and 18 years old (“​Minor​”) and accept the terms of this Agreement on behalf of such Minor. The use of the Services by a Minor is subject to the express consent of their parent or legal guardian.
            </li>
            <li>
              By creating an Account, you agree to accept and receive communications from PlayNice, including via email and text message notifications to the cellular telephone number you provided to PlayNice, regarding User Agreements. You represent and warrant that you have the right to provide such phone number to PlayNice. Message and data rates may apply. You can opt out of text messages by replying STOP to a PlayNice text message, however opting out of receiving text messages may impact your use of the Services.
            </li>
            <li>
              PlayNice may offer promotions or discounts related to Paid Services from time to time. Please read the details of those offers carefully, as any additional terms presented to you during the sign-up process will form part of the terms of this Agreement. PlayNice has the right to end or modify any promotion related to the purchase of Paid Services at any time at its sole discretion.
            </li>
            <li>Unless otherwise specified in writing, all Transactions are non-refundable.</li>
          </ol>

          <h6>Subscription Auto-Renewal; Cancellation</h6>
          <ol>
            <li>
              Subscriptions will automatically renew on a yearly basis at the then-current rate of your Subscription plan until cancelled in accordance with this section.
            </li>
            <li>
              Your failure to terminate, or continued use of, your Subscription reaffirms that we are authorized to charge you for that Subscription.
            </li>
            <li>
              You agree that your Account will be subject to this automatic renewal feature upon the expiration of the Subscription Period, unless you cancel your Subscription no later than 5 days prior to the end of the then-current Subscription Period.
            </li>
            <li>
              To cancel your Subscription, sign into your Account, go to your settings, and follow the instructions for cancellation.
            </li>
            <li>
              If you cancel your Subscription during the Subscription Period, you will continue to have access to the Subscription through the end of the Subscription Period and will not be eligible for a prorated refund of any prior of the Subscription fee paid for the then-current Subscription Period.
            </li>
            <li>
              PlayNice may immediately terminate or suspend your Account, and all or a portion of your Subscription, without notice or refund if: (a) your payment is overdue (provided that we will use reasonable efforts to notify you of the overdue payment before we terminate or suspend); (b) your provide false or inaccurate information; (c) you violate this Agreement or any other PlayNice rules or agreements; (d) you engage in conduct that is in violation of any applicable law (including, without limitation, intellectual property laws); or (e) if you engage in conduct that is threatening, abusive or harassing to PlayNice employees, agents, or other PlayNice users, including, for example, making threats to physically harm or damage property.
            </li>
          </ol>

          <h6>User Agreements</h6>
          <ol>
            <li>
              You understand and acknowledge that PlayNice is the provider of the Services and is not a party to any User Agreement.
            </li>
            <li>
              You understand and acknowledge that PlayNice makes no representations, warranties or guarantees to you regarding any user’s performance or failure to perform under any User Agreement.
            </li>
            <li>
              By entering into the User Agreement, you agree to final and binding arbitration by a neutral arbitrator. You understand that PlayNice does not provide arbitration services, that under no circumstances will PlayNice serve as an arbitrator of any dispute under a User Agreement, and that any arbitration proceeding may require the payment of fees to the arbitrator or arbitration provider. PlayNice makes no guarantees that: (a) if challenged, that the arbitration provision in a User Agreement will be enforced by a court, (b) any arbitration proceeding will end in your favor, or (c) that any user will comply with the outcome of any arbitration.
            </li>
            <li>
              PLAYNICE IS NOT A LAW FIRM, DOES NOT PRACTICE LAW, AND DOES NOT GIVE LEGAL ADVICE. PlayNice is not and does not act as your attorney, agent, representative or partner.
            </li>
          </ol>

          <h6>What Laws and Rules You Are Responsible for Following</h6>
          <ol>
            <li>
              You are responsible for all of your activity in connection with the Services, including abiding by all applicable local, state, national and international laws and regulations applicable to you. You are solely responsible for ensuring that this Agreement complies with all laws, rules and regulations applicable to you. You promise not to use the Services for any purpose that is prohibited by this Agreement.
            </li>
            <li>
              You understand PlayNice may, in its sole discretion, refuse to offer the Services to any person or entity and change its eligibility criteria at any time and that PlayNice reserves the right (without undertaking any duty) to use “geo-filtering” or other technologies to block access to certain jurisdictions, in its sole discretion, without notice.
            </li>
            <li>
              ou understand that your right to access the Services will be revoked where this Agreement or use of the Services is prohibited and, if that is the case, you agree not to use or access the Services in any way.
            </li>
            <li>
              You agree that PlayNice may terminate your use of the Services and prohibit you from accessing the Services, for any reason, or no reason, and with or without notice.
            </li>
          </ol>
          <p>
            We do our best to keep the Services safe and operational, but we cannot guarantee it. We need your help to do that, which includes the following commitments.
          </p>
          <ol>
            <li>
              You will not use manual or automated software, devices, or other processes to “crawl” or “spider” any page of the Site.
            </li>
            <li>
              You will not harvest or scrape any content or materials from the Services.
            </li>
            <li>
              You will not knowingly transmit false or misleading information through the Services.
            </li>
            <li>
              You will not use the Services in any manner that: infringes (or results in the infringement of)
              PlayNice’s or any third party’s intellectual property or other rights; is (or you reasonably believe to be) illegal, fraudulent, or unauthorized, or in furtherance of any illegal, counterfeiting, fraudulent, pirating, unauthorized, or violent activity, or that involves (or you reasonably believe to involve) any stolen, illegal, counterfeit, fraudulent, pirated, or unauthorized material; does not comply with all applicable laws, rules, or regulations, including obtaining all necessary permits, licenses, registrations, etc. In the case of any proposed or actual transaction, “applicable” refers to both your own location and to location(s) of all other parties to the transaction; or would cause PlayNice to be in violation of any law or regulation, or to infringe any right of any third party.
            </li>
            <li>
              You will not publish falsehoods or misrepresentations that may damage PlayNice or any third party.
            </li>
          </ol>

          <h6>Some portions of the Services rely on Third Party Services</h6>
          <ol>
            <li>
              You acknowledge that the Services interoperate with several third-party sites and services, including, but not limited to, Stripe, Twilio, and other third party services (collectively, the “​Third Party Services​”), and that some of the Services provided are dependent on the availability of such Third Party Services. If at any time any Third Party Services cease to provide availability to PlayNice, PlayNice may cease to provide such features to you without entitling you to refund, credit, or other compensation.
            </li>
            <li>
              In order to use the features of the Services related to the Third Party Services, you may be required to register for an account with such Third Party Service.
            </li>
          </ol>

          <h6>We are not responsible for third party sites and content</h6>
          <ol>
            <li>
              You understand the Services may contain (or you may receive from PlayNice or third parties) links to other web sites (“​Third Party Sites​”) or content posted, owned or originating from third parties such as, by way of example only, pictures, designs, photographs, graphics, text, sound, recipes, video, information, software, applications and any other content (“​Third Party Content​”).
            </li>
            <li>
              You understand and agree that PlayNice is not responsible for, and does not control, Third Party Services, Third Party Sites and Third Party Content. You also understand and agree that PlayNice is not responsible for the availability of such external sites or resources, and does not endorse and is not responsible or liable for any content, advertising, products or other materials on or available from such Third Party Services, Third Party Sites and Third Party Content.
            </li>
            <li>
              You acknowledge and agree that PlayNice shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of, or reliance upon, any such content, goods or services available on or through any such Third Party Services, Third Party Site or Third Party Content.
            </li>
          </ol>

          <h6>Who Owns What and How You Can Use It</h6>
          <h6>
            The copyright to all content the on the Services is owned by the provider of that content.
          </h6>
          <ol>
            <li>
              All content and data related to the Services are the property of PlayNice and are protected under applicable copyright law, trademark law and other proprietary rights.
            </li>
            <li>
              You may not copy, redistribute, use or publish any part of the Site or the Services, except as allowed by this Agreement.
            </li>
            <li>
              You do not acquire ownership rights to any content, document or other materials viewed through the Site or the Services, except as otherwise set forth herein or the terms of an applicable written agreement between us.
            </li>
          </ol>

          <h6>You may not use trademarks appearing on the Services in an infringing manner</h6>
          <ol>
            <li>
              You agree that PLAYNICE, EMPOWER EACH OTHER, PROTECT EACH OTHER, the PlayNice logo, and the PlayNice graphics, logos, page headers, button icons, scripts, and service names are trademarks, registered trademarks or trade dress of PlayNice or its affiliates (“​Trademark Content​”).
            </li>
            <li>
              PlayNice trademarks and trade dress may not be used in connection with any product or service that is not PlayNice’s, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits PlayNice.
            </li>
            <li>
              All other trademarks not owned by PlayNice or its affiliates that may appear on this Site or the Services are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by PlayNice or its affiliates.
            </li>
          </ol>

          <h6>You may use the PlayNice Services for limited, noncommercial purposes</h6>
          <ol>
            <li>
              PlayNice grants you a limited license to access and make personal use of the Services.
            </li>
            <li>
              PlayNice does not grant you the right to download (other than page caching) or modify the Services, or any portion of the Services.
            </li>
            <li>
              You understand PlayNice does not grant you the right to resell or make commercial use of the Site and the Services or their contents; make any derivative use of the Services or their contents; any downloading or copying of account information for the benefit of a third party, or merchant; or any use of data mining, robots, or similar data gathering and extraction tools.
            </li>
            <li>
              You understand the Services or any portion of the Services may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose.
            </li>
            <li>
              You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information of PlayNice and our affiliates without express written consent.
            </li>
            <li>
              You may not use any meta tags or any other “hidden text” utilizing PlayNice’s name or trademarks without the express written consent of PlayNice. Any unauthorized use terminates the permission or license granted by PlayNice.
            </li>
            <li>
              You are granted a limited, revocable, and nonexclusive right to create a hyperlink, or at PlayNice’s sole discretion other items such as widgets, buttons, or other web page elements to the home page of PlayNice so long as the link does not portray PlayNice, its affiliates, or their products or services in a false, misleading, derogatory, or otherwise offensive matter.
            </li>
            <li>
              You may not use any PlayNice logo or other proprietary graphic or trademark as part of the link without express written permission.
            </li>
          </ol>

          <h6>Legal Conditions</h6>
          <h6>PlayNice liability is limited</h6>
          <ol>
            <li>
              PlayNice is not responsible for any Third Party Services, Third Party Sites, Third Party Content, or any other content posted on the Services, whether caused by PlayNice, third parties or by any of the equipment or programming associated with or utilized in the Site and the Services.
            </li>
            <li>
              PlayNice assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of communications.
            </li>
            <li>
              PlayNice is not responsible for any problems or technical malfunction of any telephone network or lines, cellular data provider or network, computer online systems, servers or providers, computer equipment, software, failure of email on account of technical problems or traffic congestion on the Services, including injury or damage to any other person’s computer and/or mobile device.
            </li>
            <li>
              PlayNice is not responsible for any failures, defects or delays in delivery caused by your provision of incorrect information, your failure to comply with instructions for use of the Services; or an event which is outside of PlayNice’s reasonable control.
            </li>
            <li>
              Neither PlayNice nor any of its affiliates, advertisers, promoters or distribution partners shall be responsible for any loss or damage, including personal injury or death, resulting from anyone’s use of the Site or the Services, whether online or offline.
            </li>
          </ol>

          <h6>General Disclaimer and Limitation of Liability</h6>
          <p>
            WE TRY TO KEEP THE SITE AND SERVICES UP, BUG-FREE AND SAFE, BUT YOU USE THEM AT YOUR OWN RISK. EXCEPT FOR THE EXPRESS WARRANTIES STATED HEREIN, THE SITE AND SERVICES ARE PROVIDED ON AN “AS IS” BASIS, AND PLAYNICE MAKES NO REPRESENTATION, WARRANTY, OR GUARANTY AS TO THE RELIABILITY, COMPATIBILITY, TIMELINESS, QUALITY, SUITABILITY, TRUTH, AVAILABILITY, ACCURACY, OR COMPLETENESS OF THE SERVICES OR ANY INFORMATION OR CONTENT CONTAINED THEREIN AND PLAYNICE FURTHER DOES NOT REPRESENT OR WARRANT THAT (A) THE USE OF THE SITE OR SERVICES WILL BE SECURE, TIMELY, UNINTERRUPTED, OR ERROR-FREE OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM, OR DATA; (B) THE SITE OR SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS; (C) ANY DATA WILL BE ACCURATE OR RELIABLE; (D) ERRORS OR DEFECTS WILL BE CORRECTED; OR (E) THE SERVERS, NETWORKS, AND OTHER SYSTEMS THAT MAKE THE SITE OR SERVICES AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR FEATURES. SERVICES MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET, ELECTRONIC COMMUNICATIONS, AND OTHER SERVICES. PLAYNICE IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGE RESULTING FROM SUCH PROBLEMS. ALL CONDITIONS, REPRESENTATIONS, AND WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS, ARE HEREBY DISCLAIMED BY THE PLAYNICE TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
          </p>
          <p>
            IN NO EVENT WILL PLAYNICE OR ITS DIRECTORS, OFFICERS, EMPLOYEES OR AGENTS HAVE ANY LIABILITY WHATSOEVER FOR YOUR USE OF ANY INFORMATION, OR SERVICES. WE ARE NOT LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES (INCLUDING DAMAGES FOR LOSS OF BUSINESS, LOSS OF PROFITS, LITIGATION, OR THE LIKE), WHETHER BASED ON BREACH OF CONTRACT, BREACH OF WARRANTY, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NO ADVICE OR INFORMATION OBTAINED BY YOU FROM US THROUGH THE SITE OR SERVICES SHALL CREATE ANY WARRANTY, REPRESENTATION OR GUARANTEE NOT EXPRESSLY STATED IN THIS AGREEMENT. PLAYNICE’S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU FOR THE SERVICES DURING THE TWELVE (12) MONTHS PRIOR TO THE CLAIM ARISING, BUT IN NO CASE WILL OUR LIABILITY TO YOU EXCEED $100. YOU ACKNOWLEDGE THAT IF NO FEES ARE PAID TO US FOR THE SERVICES, YOU SHALL BE LIMITED TO INJUNCTIVE RELIEF ONLY, UNLESS OTHERWISE PERMITTED BY LAW, AND SHALL NOT BE ENTITLED TO DAMAGES OF ANY KIND FROM US, REGARDLESS OF THE CAUSE OF ACTION.
          </p>
          <p>
            IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE SECTION 1542, WHICH STATES, IN PART: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.”
          </p>

          <h6>You agree to indemnify PlayNice</h6>
          <p>
            You agree to indemnify, defend, and hold harmless PlayNice, its contractors, licensors, subsidiaries and affiliates and their respective partners, directors, officers, employees and agents from and against any and all claims and expenses, including any and all losses, costs, liabilities, and attorneys’ fees, arising out of or in connection with: (1) your use of the Services, including but not limited to any injuries or damages to personal property; (2) your violation of any law or the rights of any third party, or (3) your breach of this Agreement, any User Agreement, and/or any breach of your representations and warranties set forth herein.
          </p>

          <h6>You agree that New York law applies to this Agreement</h6>
          <p>
            Subject to the arbitration clauses included below, if there is any dispute arising out of the Site and the Services, by using the Services, you expressly agree that any such dispute shall be governed by the laws of the State of New York, without regard to its conflict of law provisions, and you expressly agree and consent to the exclusive jurisdiction and venue of the state and federal courts of the State of New York, in New York County, for the resolution of any such dispute.
          </p>

          <h6>Your general representation and warranty</h6>
          <p>
            You represent and warrant that you will use the Services in accordance with the <Link to="/privacy-policy">Privacy Policy</Link>, with this Agreement, and with all applicable laws and regulations (including without limitation any local laws or regulations in your country, state, city, or other governmental area, regarding online conduct and acceptable content, and including all applicable laws regarding the transmission of technical data exported from the United States or the country in which you reside).
          </p>

          <h6>Other Terms</h6>
          <ol>
            <li>
              You and PlayNice agree that any cause of action arising out of or related to the Site or the Services must commence within one (1) year after the cause of action arose; otherwise, such cause of action is permanently barred.
            </li>
            <li>
              You agree that, except for claims for injunctive or equitable relief or claims regarding intellectual property rights (which may be brought in any competent court without the posting of a bond), any dispute arising under this Agreement shall be finally settled in accordance with the Comprehensive Arbitration Rules of the Judicial Arbitration and Mediation Service, Inc. (“JAMS”) by arbitrators appointed in accordance with such rules. The award rendered by the arbitrator shall include costs of arbitration, reasonable attorneys’ fees and reasonable costs for expert and other witnesses, and any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. Nothing in this Section shall prevent either party from seeking injunctive or other equitable relief from the courts for matters related to data security, intellectual property or unauthorized access to the Site or the Service. ALL CLAIMS MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING, AND, UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS. YOU AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND PLAYNICE ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.
            </li>
            <li>
              You agree any arbitration shall take place in New York County in the State of New York and the arbitral decision may be enforced in any court. The prevailing party in any action or proceeding to enforce this Agreement shall be entitled to costs and attorneys’ fees.
            </li>
            <li>
              You understand that the Services enable you to send and receive e-signatures in the United States under the 2000 U.S. Electronic Signature in Global and National Commerce Act and the Uniform Electronic Transactions Act UETA as adopted by individual states. You acknowledge that PlayNice does not authenticate its users’ signatures or identities.
            </li>
            <li>
              If any part of this Agreement is held invalid or unenforceable, that part will be construed to reflect the parties’ original intent, and the remaining portions will remain in full force and effect.
            </li>
            <li>
              A waiver by either party of any term or condition of this Agreement or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof.
            </li>
            <li>PlayNice may assign its rights under this Agreement without condition.</li>
            <li>
              This Agreement will be binding upon and will inure to the benefit of the parties, their successors and permitted assigns. The headings of the paragraphs of this Agreement are inserted for convenience only and shall not be deemed to constitute part of this Agreement or to affect the construction thereof.
            </li>
          </ol>
        </div>
      </div>
    );
  }
}

export { TermOfUse as default };
