import PageFooter from './PageFooter';
import actionCreator from '../actionCreators/contactUs';
import meActionCreator from '../actionCreators/me';
import CreateAgreementFooter from '../components/CreateAgreementFooter';
import H2 from '../components/H2';
import Button from '../components/Button';
import { showModal } from '../utils/helpers';
import { Select } from 'antd';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import '../../static/assets/styles/containers/contactUs.scss';

class UnconnectedContactUs extends Component {
  static propTypes = {
    isUpdatingData: PropTypes.bool.isRequired,
    formType: PropTypes.string,
    formFullName: PropTypes.string.isRequired,
    formEmail: PropTypes.string.isRequired,
    formDescription: PropTypes.string.isRequired,
    shouldInquiryFormBeVisible: PropTypes.bool.isRequired,

    dispatchSetFormField: PropTypes.func.isRequired,
    dispatchSubmitInquiry: PropTypes.func.isRequired,
    dispatchVerifyUserResponseToken: PropTypes.func.isRequired,
  };

  static defaultProps = {
    formType: undefined,
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    window.grecaptcha.ready(async () => {
      const token = await window.grecaptcha.execute('6LeKq90UAAAAAK1VT8ZxKTfh2f1COTKnRccvmc2Q', {
        action: 'inquiry_form_load',
      });

      this.props.dispatchVerifyUserResponseToken(token);
    });

    UnconnectedContactUs.setReCaptchaVisibility(true);
  }

  componentWillUnmount() {
    UnconnectedContactUs.setReCaptchaVisibility(false);
  }

  static setReCaptchaVisibility(isVisible) {
    window.setTimeout(() => {
      window.document.querySelector('.grecaptcha-badge').style.visibility = isVisible
        ? 'visible'
        : 'hidden';
    }, 1000);
  }

  handleInputChange(field, event) {
    this.props.dispatchSetFormField(field, event.target ? event.target.value : event);
  }

  submitInquiry = event => {
    event.preventDefault();

    const {
      formType: type,
      formFullName: fullName,
      formEmail: email,
      formDescription: description,
    } = this.props;

    if (!type || !fullName || !email || !description) {
      showModal('At least one of the fields is missing!');
    } else {
      this.props.dispatchSubmitInquiry({ type, fullName, email, description });
    }
  };

  render() {
    const {
      formType,
      formFullName,
      formEmail,
      formDescription,
      isUpdatingData,
      shouldInquiryFormBeVisible,
    } = this.props;

    return (
      <div className="contact-us">
        <section className="content">
          <p className="sub-title bold">PlayNice Users</p>
          <p className="sub-title">
            Feel free to contact us with any questions regarding your account or your experience
            with PlayNice.
          </p>

          <p className="sub-title bold">Universities/Schools</p>
          <p className="sub-title">
            We have various programs to offer students, so please reach out and a PlayNIce
            representative will be happy to send you a package so you can learn how to better
            protect your students and school from online harm.
          </p>

          <p className="sub-title bold">Social Media/Dating Platforms</p>
          <p className="sub-title">
            Learn how to integrate PlayNice into your current platform to offer your users
            protection from online harm, and participate in our affiliate program.
          </p>

          <p className="sub-title bold">Media/Press and General Inquiries</p>
          <p className="sub-title">
            Please reach out and we’d be happy to answer any questions you may have, or supply any
            materials you may need.
          </p>
        </section>

        {shouldInquiryFormBeVisible && (
          <section className="general-inquiries">
            <form>
              <Select
                className="inquiry-type-select"
                onChange={this.handleInputChange.bind(this, 'type')}
                placeholder="Inquiry Type"
                value={formType}
              >
                <Select.Option value="PlayNice Users">PlayNice Users</Select.Option>
                <Select.Option value="Universities/Schools">Universities/Schools</Select.Option>
                <Select.Option value="Social Media/Dating Platforms">
                  Social Media/Dating Platforms
                </Select.Option>
                <Select.Option value="Media/Press and General Inquiries">
                  Media/Press and General Inquiries
                </Select.Option>
              </Select>
              <input
                type="text"
                placeholder="Your Full Name"
                value={formFullName}
                onChange={this.handleInputChange.bind(this, 'fullName')}
              />
              <input
                type="text"
                placeholder="Email Address"
                value={formEmail}
                onChange={this.handleInputChange.bind(this, 'email')}
              />
              <textarea
                rows={5}
                placeholder="Write something..."
                value={formDescription}
                onChange={this.handleInputChange.bind(this, 'description')}
                maxLength="1000"
              />
              <Button type="submit" onClick={this.submitInquiry} disabled={isUpdatingData}>
                Submit
              </Button>
            </form>
          </section>
        )}

        <CreateAgreementFooter />
        <PageFooter />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isUpdatingData: state.contactUs.updateData.isUpdatingData,
  formType: state.contactUs.form.type.value,
  formFullName: state.contactUs.form.fullName.value,
  formEmail: state.contactUs.form.email.value,
  formDescription: state.contactUs.form.description.value,
  shouldInquiryFormBeVisible: state.me.main.shouldInquiryFormBeVisible,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFormField(field, value) {
    dispatch(actionCreator.setFormField(field, value));
  },

  dispatchSubmitInquiry(...params) {
    dispatch(actionCreator.submitInquiry(...params));
  },

  dispatchVerifyUserResponseToken(token) {
    dispatch(meActionCreator.verifyUserResponseToken(token));
  },
});

const ContactUs = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedContactUs);

export { UnconnectedContactUs, ContactUs as default };
