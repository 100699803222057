import common from './common';
import createAccount from './createAccount';
import login from './login';
import forgotPassword from './forgotPassword';
import createAgreement from './createAgreement';
import counterSign from './counterSign';
import contactUs from './contactUs';
import myDashboard from './myDashboard';
import accountSettings from './accountSettings';
import me from './me';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  common,
  createAccount,
  login,
  forgotPassword,
  createAgreement,
  counterSign,
  contactUs,
  myDashboard,
  accountSettings,
  me,
});

export { rootReducer as default };
