const actionTypes = {
  FORM: {
    RESET_STATE: 'FORM.RESET_STATE',
    SET_FIELD: 'FORM.SET_FIELD',
    SET_FIELD_VALIDATION_RESULT: 'FORM.SET_FIELD_VALIDATION_RESULT',
    SET_IS_VALIDATING: 'FORM.SET_IS_VALIDATING',
  },
  LOAD_DATA: {
    RESET_STATE: 'LOAD_DATA.RESET_STATE',
    REQUEST: 'LOAD_DATA.REQUEST',
    SUCCESS: 'LOAD_DATA.SUCCESS',
    FAILURE: 'LOAD_DATA.FAILURE',
  },
  UPDATE_DATA: {
    RESET_STATE: 'UPDATE_DATA.RESET_STATE',
    REQUEST: 'UPDATE_DATA.REQUEST',
    SUCCESS: 'UPDATE_DATA.SUCCESS',
    FAILURE: 'UPDATE_DATA.FAILURE',
  },
  COMMON: {
    RESET_STATE: 'COMMON.RESET_STATE',
  },
  CREATE_ACCOUNT: {
    RESET_STATE: 'CREATE_ACCOUNT.RESET_STATE',
  },
  LOGIN: {
    RESET_STATE: 'LOGIN.RESET_STATE',
  },
  FORGOT_PASSWORD: {
    RESET_STATE: 'FORGOT_PASSWORD.RESET_STATE',
  },
  CREATE_AGREEMENT: {
    RESET_STATE: 'CREATE_AGREEMENT.RESET_STATE',
    SHOW_DISCOUNT_PERCENTAGE: 'CREATE_AGREEMENT.SHOW_DISCOUNT_PERCENTAGE',
    PROCESS_PAYMENT_START: 'CREATE_AGREEMENT.PROCESS_PAYMENT_START',
    PROCESS_PAYMENT_END: 'CREATE_AGREEMENT.PROCESS_PAYMENT_END',
  },
  COUNTER_SIGN: {
    RESET_STATE: 'COUNTER_SIGN.RESET_STATE',
    SET_DATA: 'COUNTER_SIGN.SET_DATA',
  },
  MY_DASHBOARD: {
    RESET_STATE: 'MY_DASHBOARD.RESET_STATE',
    SET_DATA: 'MY_DASHBOARD.SET_DATA',
  },
  ACCOUNT_SETTINGS: {
    RESET_STATE: 'ACCOUNT_SETTINGS.RESET_STATE',
  },
  CONTACT_US: {
    RESET_STATE: 'CONTACT_US.RESET_STATE',
  },
  ME: {
    RESET_STATE: 'ME.RESET_STATE',
    SET_DATA: 'ME.SET_DATA',
  },
};

const namespaces = Object.keys(actionTypes).reduce((acc, type) => ({ ...acc, [type]: type }), {});

export { actionTypes as default, namespaces };
