import * as datasource from './datasource';
import { showModal } from '../../utils/helpers';
import meActionCreator from '../me';
import buildFormActionCreator from '../builders/form';
import buildLoadDataActionCreator from '../builders/loadData';
import buildUpdateDataActionCreator from '../builders/updateData';
import actionTypes, { namespaces } from '../../actionTypes';

const { MY_DASHBOARD } = actionTypes;

export default {
  ...buildFormActionCreator(namespaces.MY_DASHBOARD),
  ...buildLoadDataActionCreator(namespaces.MY_DASHBOARD),
  ...buildUpdateDataActionCreator(namespaces.MY_DASHBOARD),

  resetMainState() {
    return {
      type: MY_DASHBOARD.RESET_STATE,
    };
  },

  resetState() {
    return dispatch => {
      dispatch(this.resetMainState());

      dispatch(this.resetFormState());

      dispatch(this.resetLoadDataState());
    };
  },

  setData(payload) {
    return {
      type: MY_DASHBOARD.SET_DATA,
      payload,
    };
  },

  initialize() {
    return async dispatch => {
      await dispatch(meActionCreator.checkAuthentication());

      await dispatch(this.fetchMyAgreements());
    };
  },

  fetchMyAgreements() {
    return async dispatch => {
      try {
        dispatch(this.loadDataRequest());

        const { data: myAgreements } = await datasource.fetchMyAgreements();

        dispatch(this.setData({ myAgreements }));

        dispatch(this.loadDataSuccess());
      } catch (error) {
        showModal(error.message, 'error');

        dispatch(this.loadDataFailure(error.message));

        throw error;
      }
    };
  },

  resendAgreement(...params) {
    return async dispatch => {
      try {
        dispatch(this.updateDataRequest());

        await datasource.resendAgreement(...params);

        showModal('Your invitation to PlayNIce has been resent!', 'success', 'Okay');

        dispatch(this.updateDataSuccess());
      } catch (error) {
        showModal(error.message, 'error');

        dispatch(this.updateDataFailure(error.message));
      }
    };
  },
};
