import meActionCreator from '../actionCreators/me';
import { showModal } from '../utils/helpers';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import '../../static/assets/styles/containers/pageFooter.scss';

class UnconnectedPageFooter extends Component {
  componentDidMount() {
    window.grecaptcha.ready(async () => {
      const token = await window.grecaptcha.execute('6LeKq90UAAAAAK1VT8ZxKTfh2f1COTKnRccvmc2Q', {
        action: 'page_footer_load',
      });

      this.props.dispatchVerifyUserResponseToken(token);
    });
  }

  handleSubscription = event => {
    event.preventDefault();

    if (!this.props.formEmail) {
      showModal('Email is missing!');
      return;
    }

    this.props.dispatchSubscribe(this.props.formEmail);
  };

  render() {
    const {
      formEmail,
      shouldSubscriptionFormBeVisible,
      dispatchSetFormField,
      history,
    } = this.props;

    return (
      <footer id="pn-page-footer">
        <div className="site-map-with-get-updates">
          <div className="site-map">
            <section>
              <h4>PlayNice</h4>
              <p>2020 Play Nice, Inc.</p>
              <p>
                We currently service USA and will soon launch
                <br />
                in Canada, Latin America, and Europe
              </p>
            </section>

            <section>
              <h6>Get Started</h6>
              <p className="link" onClick={() => history.push('/how-it-works')}>
                How it Works
              </p>
              <p className="link" onClick={() => history.push('/about-us')}>
                About Us
              </p>
            </section>

            <section>
              <h6>Support</h6>
              <p className="link" onClick={() => history.push('/contact-us')}>
                Contact
              </p>
              <p className="link" onClick={() => {}}>
                FAQ
              </p>
            </section>

            <section>
              <h6>Company</h6>
              <p className="link" onClick={() => history.push('/about-us')}>
                Our Story
              </p>
              <Link className="link" to="/privacy-policy">
                Privacy Policy
              </Link>
              <Link className="link" to="/term-of-use">
                Terms of Service
              </Link>
              <Link className="link" to="/term-of-use">
                Consent to Services
              </Link>
            </section>
          </div>

          <div className="get-updates">
            {shouldSubscriptionFormBeVisible && (
              <form>
                <input
                  placeholder="Enter your email"
                  value={formEmail}
                  onChange={event => dispatchSetFormField('email', event.target.value)}
                />
                <button type="submit" onClick={this.handleSubscription}>
                  Get Updates
                </button>
              </form>
            )}

            <div>
              <img
                onClick={() => window.open('https://www.facebook.com/theplayniceapp/', '_blank')}
                className="social-media-icon"
                src="/images/facebook-icon.png"
                alt="facebook"
              />
              <img
                onClick={() => window.open('https://twitter.com/playniceapp', '_blank')}
                className="social-media-icon"
                src="/images/twitter-icon.png"
                alt="twitter"
              />
              <img
                onClick={() => window.open('https://www.instagram.com/playniceapp', '_blank')}
                className="social-media-icon"
                src="/images/instagram-icon.png"
                alt="instagram"
              />
              <img
                onClick={() => window.open('https://www.linkedin.com/company/playnice', '_blank')}
                className="social-media-icon"
                src="/images/linkedin-icon.png"
                alt="linkedin"
              />
            </div>
          </div>
        </div>

        <div className="disclaimers">
          <p>
            Disclaimer: Communications between you and PlayNice are protected by our Privacy Policy
            but not by the attorney-client privilege or as work product. PlayNice provides access to
            self-help services at your specific direction. We are not a law firm or a substitute for
            an attorney or law firm. We cannot provide any kind of advice, explanation, opinion, or
            recommendation about possible legal rights, remedies, defenses, options, selection of
            forms or strategies. Your access to the website is subject to our Terms of Service.
          </p>
          <p>Photo by Ian Dooley on Unsplash</p>
        </div>
      </footer>
    );
  }
}

UnconnectedPageFooter.propTypes = {
  formEmail: PropTypes.string.isRequired,
  shouldSubscriptionFormBeVisible: PropTypes.bool,

  dispatchSetFormField: PropTypes.func.isRequired,
  dispatchSubscribe: PropTypes.func.isRequired,
  dispatchVerifyUserResponseToken: PropTypes.func.isRequired,

  history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

UnconnectedPageFooter.defaultProps = {
  shouldSubscriptionFormBeVisible: undefined,
};

const mapStateToProps = state => ({
  formEmail: state.me.form.email.value,
  shouldSubscriptionFormBeVisible: state.me.main.shouldSubscriptionFormBeVisible,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFormField(field, value) {
    dispatch(meActionCreator.setFormField(field, value));
  },

  dispatchSubscribe(email) {
    dispatch(meActionCreator.subscribe(email));
  },

  dispatchVerifyUserResponseToken(token) {
    dispatch(meActionCreator.verifyUserResponseToken(token));
  },
});

const PageFooter = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UnconnectedPageFooter));

export { UnconnectedPageFooter, PageFooter as default };
