import actionCreator from '../../actionCreators/createAgreement';
import StepsHeader from '../../components/headers/Steps';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import { formatPhoneNumber, showModal } from '../../utils/helpers';
import SignaturePad from 'signature_pad';
import PhoneNumberFormatter from 'phone-number-formats';
import { parse, format, subYears, isBefore } from 'date-fns';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../../static/assets/styles/containers/createAgreement/agreementReview.scss';

let signaturePad;

class UnconnectedAgreementReview extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    isUpdatingData: PropTypes.bool.isRequired,
    membershipStatus: PropTypes.string,
    formSignatureDataUrl: PropTypes.string,
    formSignatureDated: PropTypes.string,
    fullName: PropTypes.string,
    dob: PropTypes.string,
    phoneNumber: PropTypes.string,
    state: PropTypes.string.isRequired,
    toFullName: PropTypes.string.isRequired,
    toPhoneNumber: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,

    dispatchSetFormField: PropTypes.func.isRequired,
    dispatchCreateAgreementDirectly: PropTypes.func.isRequired,

    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    membershipStatus: undefined,
    formSignatureDataUrl: undefined,
    formSignatureDated: undefined,
    fullName: undefined,
    dob: undefined,
    phoneNumber: undefined,
  };

  state = {
    isModalVisible: false,
  };

  componentDidMount() {
    if (this.props.isAuthenticated) {
      if (!this.props.toFullName || !this.props.toPhoneNumber) {
        this.props.history.push('/create-agreement/basic-info');
      }
    } else if (
      !this.props.fullName ||
      !this.props.dob ||
      !this.props.phoneNumber ||
      !this.props.state ||
      !this.props.toFullName ||
      !this.props.toPhoneNumber ||
      !this.props.code
    ) {
      this.props.history.push('/create-agreement/basic-info');
    }

    window.scrollTo(0, 0);
  }

  static isOverAgeOfEighteen(dobValue) {
    const today = new Date();

    return isBefore(
      parse(dobValue, 'yyyy-MM-dd', today),
      new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())
    );
  }

  setCanvasRef = element => {
    const canvas = document.querySelector('canvas');

    if (canvas) {
      signaturePad = new SignaturePad(canvas);

      const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

      if (viewportWidth < 540) {
        element.width = 290;
      }
    }
  };

  saveSignature = () => {
    const signatureDataUrl = signaturePad.toDataURL(); // save image as PNG

    this.props.dispatchSetFormField('signatureDataUrl', signatureDataUrl);
    this.props.dispatchSetFormField('signatureDated', format(new Date(), 'M/d/yyyy'));

    this.setState({ isModalVisible: false });
  };

  isSignatureNotEmpty = () => this.props.formSignatureDataUrl && !signaturePad.isEmpty();

  handleNext = event => {
    event.preventDefault();

    if (!this.isSignatureNotEmpty()) {
      showModal('Signature is required!');
    } else if (this.props.membershipStatus === 'subscriber') {
      this.props.dispatchCreateAgreementDirectly(this.props.history, {
        signatureDataUrl: this.props.formSignatureDataUrl,
        signatureDated: this.props.formSignatureDated,
        toPhoneNumber: formatPhoneNumber(this.props.toPhoneNumber),
        toFullName: this.props.toFullName,
      });
    } else {
      this.props.history.push('/create-agreement/payment-and-send');
    }
  };

  renderRegularAgreementContent() {
    const { fullName, state, toFullName } = this.props;

    return (
      <section className="document-container">
        <h2>PlayNice Mutual Respect and Confidentiality Agreement</h2>

        <p>
          This Mutual Respect and Confidentiality Agreement (this “Agreement”) dated{' '}
          <span>{format(new Date(), 'M/d/yyyy')}</span> is entered into by and between{' '}
          <span>{fullName}</span>, an individual with state of <span>{state}</span>, and{' '}
          <span>{toFullName}</span>, an individual with state of ____, to protect the parties’
          reputational interests and confidentiality of certain information previously disclosed, or
          that may be disclosed, by one or both parties (“Disclosing Party”) to the other
          (“Receiving Party”). The parties are referred to in this Agreement each as a “Party,” and
          together, the “Parties.”
        </p>
        <p>
          In consideration of the mutual promises contained in this Agreement and other good and
          valuable consideration, the Parties agree as follows:
        </p>
        <p>
          1. Protected Information. “Protected Information” includes personal, private,
          confidential, or sensitive information, communications, messages, data, texts, media,
          music, sounds, images, photos, graphics, or other material in any manner or media now
          known or hereafter devised that depicts or relates or belongs to the Disclosing Party in
          in connection with this Agreement.
        </p>
        <p>
          2. Confidentiality/Non-Disclosure of Protected Information. Unless otherwise agreed by the
          Disclosing Party in writing, the Receiving Party agrees, at all times, to hold the
          Disclosing Party’s Protected Information in strict confidence and to take reasonable
          precautions against unauthorized use, disclosure, publication, exploitation, or
          dissemination of such Protected Information or related information (including, without
          limitation, those precautions the Receiving Party utilizes to protect its own Protected
          Information). However, the Receiving Party may disclose Protected Information if and as
          required by law or court order, provided that, if legally permissible, the Receiving Party
          promptly notifies the Disclosing Party of the disclosure and uses reasonable efforts to
          assist the Disclosing Party in obtaining a protective order preventing or limiting the
          disclosure.
        </p>
        <p>
          3. Return of Protected Information. At the Disclosing Party’s request, the Receiving Party
          will immediately return and permanently delete or cause to be deleted (if electronic) or
          destroy (if physical) all Protected Information, including all copies thereof and extracts
          therefrom, and promptly certify to the Disclosing Party that such Party has done so. The
          Receiving Party shall not keep any copies of or extracts from such Protected Information
          in any form or any media.
        </p>
        <p>4. Cooperation.</p>
        <p className="double-indent">
          a. The Receiving Party will immediately notify the Disclosing Party upon discovering any
          unauthorized disclosure or use of Protected Information and will cooperate with the
          Disclosing Party in every reasonable manner to help the Disclosing Party regain possession
          of the Protected Information and prevent its further unauthorized disclosure or use,
          including, but not limited to, by removing any compromised Protected Information from
          websites, social media pages, and other forums, publications, or distribution channels
          over which the Disclosing Party has ownership or control.
        </p>
        <p className="double-indent">
          b. During all reasonable times, the Receiving Party will cooperate with the Disclosing
          Party in any present or future Disputes (defined below) involving the Disclosing Party
          with respect to the unauthorized use or disclosure of Protected Information, including,
          but not limited to, by providing truthful testimony as a witness in such proceeding.
        </p>
        <p>
          5. Rights to Protected Information. The Disclosing Party shall retain all rights,
          including all intellectual property rights and privacy rights, to all Protected
          Information and does not grant to the Receiving Party any right, interest, or license to
          the Protected Information. Each Party’s intellectual property shall remain its own;
          provided, however, any intellectual property that constitutes Protected Information that
          is created jointly by the Parties (“Jointly Protected Information”) shall be owned jointly
          by the Parties and treated and protected as if each Party is a Receiving Party, and
          neither Party shall display, perform, distribute, prepare derivative works from, reproduce
          or otherwise exploit the Jointly Protected Information without the express written consent
          of the other Party.
        </p>
        <p>
          6. Non-Disparagement. The Parties agree not to make any negative, derogatory, or
          disparaging statements or communications, oral or written, about the other regarding the
          Protected Information or its contents. Unauthorized use, disclosure, publication, or
          dissemination of Protected Information may, in and of itself, constitute disparagement of
          the Disclosing Party.
        </p>
        <p>
          7. Harm Resulting from Unauthorized Use or Disclosure. Each Party acknowledges and agrees
          that any breach or threatened breach of this Agreement may constitute or result in any of
          the following, including, but not limited to, defamation (i.e., libel or slander);
          invasion of privacy; intrusion upon seclusion; public disclosure of private facts; false
          light; appropriation of name or likeness; intentional or negligent infliction of emotional
          distress; cyberbullying; personal injury; interference with an existing or prospective
          contract, business relationship, or economic advantage; loss of employment or educational
          opportunities; or other personal, reputational, dignitary, or economic harms to the
          Receiving Party. Reporting or disclosing to any governmental authority any material that
          the Receiving Party reasonably believes is not Protected Information due to its unlawful
          nature, or reporting or disclosing any Protected Information as required by law or court
          order, shall not constitute a breach of this Agreement.
        </p>
        <p>
          8. Remedies. The Receiving Party acknowledges and agrees that if such Party breaches or
          threatens to breach this Agreement, the Disclosing Party will suffer immediate and
          irreparable harm, meaning harm for which monetary damages would be an inadequate remedy.
          The Receiving Party agrees that the Disclosing Party may seek to restrain the Receiving
          Party from violating this Agreement through a temporary, preliminary, and/or permanent
          injunction, and such equitable relief shall be in addition to (and not instead of) any
          other remedies to which the Disclosing Party may be entitled, including monetary damages.
        </p>
        <p>
          9. No Waiver of Criminal Liability. Nothing in this Agreement shall waive, now or in the
          future, either Party’s right to seek criminal prosecution for, or disclaim a Party’s
          criminal liability from, any illegal act arising from the use, disclosure, publication, or
          dissemination of Protected Information. These unlawful acts include anything that depicts,
          simulates, induces, or relates to unlawful or offensive acts including, without
          limitation, child pornography; rape, sexual assault, or gender violence; physical, mental,
          or emotional abuse or harassment; revenge porn; threats; violence; self-harm; terrorism;
          or other criminal behavior.
        </p>
        <p>
          10. Representations and Warranties. Each Party represents and warrants to the other Party
          that such Party: (i) is at least eighteen (18) years of age and has the power and
          authority to enter into and fully perform this Agreement; (ii) has legal capacity to
          contract and is not mentally impaired or under the influence of drugs or alcohol at the
          time of entering into this Agreement; and (iii) is not bound by any other agreements that
          would prevent such Party from entering in to or fulfilling the terms of this Agreement.
        </p>
        <p>
          11. No Other Assurances; Entire Agreement. Each Party acknowledges, in signing this
          Agreement, such Party has not relied on any oral or written promises, statements, or
          representations made by the other Party other than those in this Agreement. This Agreement
          constitutes the entire agreement between the Parties, and replaces and cancels all
          previous oral or written agreements, understandings, and commitments in connection with
          the subject matter of this Agreement.
        </p>
        <p>
          12. Waiver of Liability of Let’s Play Nice, LLC. The Parties acknowledge that: (a) Let’s
          Play Nice, LLC (“LPN”) is the provider of the PlayNice platform (the “Platform“); (b) each
          Party’s use of the Platform is at all times to subject to the Terms of Use of the
          Platform, as amended from time to time, which include provisions disclaiming LPN’s
          liability to each Party under this Agreement; (c) LPN is not a party to this Agreement;
          (d) LPN makes no representations or warranties hereunder to either Party regarding the
          Platform, the other Party’s performance or failure to perform under this Agreement, or any
          other subject matter of this Agreement; and (e) LPN does not guarantee or otherwise assume
          any obligations with respect to either Party’s performance under this Agreement.
        </p>
        <p>
          13. Assignment. The Parties agree that this Agreement is personal to them and may not be
          assigned by either Party to any other person or entity. Any purported assignment of this
          Agreement shall be void. Notwithstanding the foregoing, in the event of either Party’s
          death during the term of this Agreement, the deceased Party’s heirs, successors,
          executors, and administrators may enforce such Party’s rights under this Agreement.
        </p>
        <p>
          14. Notices. All notices permitted or required under this Agreement shall be in writing
          and delivered by personal delivery, electronic mail, first class mail, or overnight
          courier service (e.g., FedEx), and will be deemed delivered upon personal delivery, upon
          acknowledgment of receipt of electronic transmission, five (5) days after mailing, or the
          next business day if sent by overnight courier service. Notices shall be sent to the
          addresses set forth above or such other address as either Party may specify in writing.
        </p>
        <p>
          15. Severability. The invalidity or unenforceability of any provision of this Agreement
          shall not affect the validity or enforceability of any other provision of this Agreement,
          which shall remain in full force and effect. Any provision of this Agreement deemed
          overbroad or unenforceable shall be given the maximum effect allowed by law.
        </p>
        <p>16. Dispute Resolution.</p>
        <p className="double-indent">
          a. Governing Law. This Agreement, and any controversy, dispute, or claim arising out of or
          relating to this Agreement (a “Dispute”), shall be governed by and construed in accordance
          with the laws of the jurisdiction in which this Agreement was entered into as determined
          by the geolocation stamps below the Parties’ signatures hereto (“Governing Law”); in the
          event that the Parties enter into this Agreement in different jurisdictions, the laws of
          the State of Delaware shall apply, without regard to conflict of law principles.
        </p>
        <p className="double-indent">
          b. Arbitration as Exclusive Dispute Resolution. If a Dispute arises between the Parties
          and a mutual remedy or understanding cannot be reached by the Parties within three (3)
          days of such Dispute, using good faith efforts, the Parties agree that Disputes shall be
          resolved by final and binding arbitration by a neutral arbitrator, selected by the
          Disclosing Party. If both Parties in the Dispute can be considered a Disclosing Party,
          then the Parties shall each choose a neutral arbitrator, and those arbitrators shall
          mutually select a third arbitrator, unless both Parties can mutually agree upon a single
          neutral arbitrator. The arbitration proceeding and all related documents will be
          confidential, unless disclosure is required by law. The decision of the arbitrator shall
          be final, binding and conclusive on the Parties and their respective heirs and successors,
          and not be subject to further review. The arbitrator may, in the arbitrator’s discretion,
          award attorneys’ fees and costs to such Party as the arbitrator sees fit in rendering a
          decision.
        </p>
        <p className="double-indent">
          c. Enforcement of Award. Judgment upon any award rendered by an arbitrator may be entered
          in any court properly having jurisdiction over the Parties or Dispute.
        </p>
        <p className="double-indent">
          d. Waiver of Jury Trial. EACH PARTY ACKNOWLEDGES AND AGREES THAT ANY DISPUTE IS LIKELY TO
          INVOLVE COMPLICATED, DIFFICULT, AND SENSITIVE ISSUES, AND THEREFORE EACH KNOWINGLY,
          VOLUNTARILY, INTENTIONALLY, IRREVOCABLY, AND UNCONDITIONALLY WAIVES ANY RIGHT SUCH PARTY
          MAY HAVE TO A TRIAL BY JURY WITH RESPECT TO A DISPUTE.
        </p>
        <p>
          17. Term; Survival. This Agreement shall continue in perpetuity. The death of either Party
          shall not terminate this Agreement, and each Party’s obligations under this Agreement will
          survive, and continue in full force and effect, after the death of the death of such Party
          and be binding upon its heirs and successors.
        </p>
        <p>
          18. No Waiver; Modification/Amendment. Neither Party’s action or inaction shall be deemed
          to waive any provision of this Agreement. No waiver of any provision of this Agreement
          shall constitute a waiver of any other provision or of the same provision on another
          occasion. This Agreement may only be changed or modified by written agreement signed by
          both Parties, and not by any oral agreement, statement, promise, commitment, or
          representation by one or both Parties.
        </p>
        <p>
          19. Knowing and Voluntary Agreement; Binding Effect. Each Party affirms that such Party
          has entered into this Agreement knowingly, freely, and voluntarily, and that such Party
          has had adequate time to carefully consider these terms and conditions and to decide
          whether to sign this Agreement. Each Party affirms that such Party understands each and
          every provision of this Agreement and, to the extent that such Party did not understand
          any portion of this Agreement, such Party has taken appropriate steps to understand such
          provision. Each Party understands and acknowledges the final and binding effect of this
          Agreement.
        </p>
        <p>
          20. Counterparts; Electronic Signature. This Agreement may be signed in one or more
          counterparts, and counterparts may be exchanged by electronic submission (including
          through the Platform), each of which will be deemed an original, but which together
          constitute one and the same instrument. The Parties acknowledge this Agreement has been
          electronically executed in accordance with the requirements of the 2000 U.S. Electronic
          Signature in Global and National Commerce Act and the Uniform Electronic Transactions Act,
          as adopted by the Governing Law. The Parties acknowledge that LPN does not authenticate
          the Parties’ signatures or identities. The Parties agree not to challenge the valid
          execution or binding effect of this Agreement on the grounds of incomplete compliance with
          applicable electronic signature law.
        </p>
        <p>
          21. No Practice of Law. LPN and its respective officers, managers, members, employees,
          representatives, agents, successors, or assigns are not a law firm and do not provide
          legal advice. No attorney-client relationship is intended, sought, or created by or
          through the creation or execution of this Agreement. LPN and its respective members have
          not provided, nor will provide at any time in the future, legal advice to the Parties
          regarding or in connection with this Agreement, any Dispute, or arbitration proceeding.
        </p>
        <p>
          The Parties are signing this Mutual Respect and Confidentiality Agreement as of the date
          and time set forth below each of their signatures.
        </p>
      </section>
    );
  }

  renderUnderAgeAgreementContent() {
    const { fullName, toFullName } = this.props;

    return (
      <section className="document-container">
        <h2>PlayNice Mutual Respect and Confidentiality Agreement</h2>

        <p>
          This Cyber-Bullying Pledge dated <span>{format(new Date(), 'M/d/yyyy')}</span>{' '}
          between <span>{fullName}</span> and <span>{toFullName}</span>, is signed to protect the
          both individuals’ reputations and emotional well being.
        </p>
        <p>
          By signing this agreement, both individuals agree to never bully each other, and to never
          post anything online to cause emotional harm. Both individuals agree to set a higher
          example than the previous generations when it comes to respecting each other, regardless
          of color, race, gender, popularity, personal life preferences, athletic ability,
          intelligence, religion and nationality.
        </p>
        <p>
          We agree to be part of the first generation of young adults to take their privacy into
          their own hands by making a commitment with each other that neither individual will harm
          the other.
        </p>
        <p>
          Physical and emotional bullying can be any form of hitting, pushing, name-calling or
          exclusion. It includes making fun of any characteristics of an individual, their family or
          their personal life choices. We agree to be accepting of everyone. Furthermore, we agree
          to never use any electronic device like a computer or smart phone, to torment each other.
          Both parties agree that the use of email, texting, or social media to distribute any
          harmful messaging, including harmful photos and comments, whether truthful or not, is
          unacceptable.
        </p>
        <p>
          Both parties agree that if this agreement is broken, and one harms the other, the person
          harmed will do their best to resolve the matter either personally, through parents, or
          through a teacher at the school, and to not engage in revenge. As tough as that may be,
          revenge will only make the problem worse for both individuals.
        </p>
      </section>
    );
  }

  render() {
    const {
      fullName,
      dob,
      phoneNumber,
      state,
      toFullName,
      toPhoneNumber,
      formSignatureDataUrl,
      formSignatureDated,
      isUpdatingData,
    } = this.props;

    return (
      <div className="agreement-review">
        <Modal
          className="custom-modal"
          isVisible={this.state.isModalVisible}
          hasTopRightCloseButton={false}
          maskClosable={false}
          keyboard={false}
        >
          <div className="content-container">
            <a onClick={() => signaturePad.clear()} className="clear-signature">
              Clear Signature
            </a>
            <div className="canvas-container">
              <canvas height="250" width="472" ref={this.setCanvasRef} />
            </div>

            <p>By signing my name above I agree to the terms of this contract</p>
            <Button type="button" onClick={this.saveSignature}>
              {UnconnectedAgreementReview.isOverAgeOfEighteen(dob)
                ? 'Confirm'
                : 'Continue with Pledge'}
            </Button>
          </div>
        </Modal>

        <StepsHeader currentStepIndex={1} />

        <main>
          <h5>
            <div className="line-separator" />
            <div>Follow These 3 Steps</div>
            <div className="line-separator" />
          </h5>

          <section className="overview-container">
            <div>
              <img src="/images/read.png" alt="read" />
              <h6>1. READ CAREFULLY</h6>
              <p>
                Please read the agreement below and understand that you agree to never harm this
                person online. It’s important that you know your rights under the agreement.
              </p>
            </div>
            <div>
              <img src="/images/sign.png" alt="sign" />
              <h6>2. SIGN</h6>
              <p>
                After reading the language below, select the yellow tab and sign your name to agree
                with the terms. Swipe your finger if using smart phone, or use your mouse if at a
                computer.
              </p>
            </div>
            <div>
              <img src="/images/cart.png" alt="cart" />
              <h6>3. PURCHASE YOUR PLAN</h6>
              <p>
                You have the choice to purchase 1 stand-alone agreement (Pay Per Play), or you can
                select “Unlimited” to protect as many people as you can. Unlimited is better value
                but feel free to start off slow and get comfortable.
              </p>
            </div>
          </section>

          {/*
          <section className="covered-or-not-container">
            <h6>What’s Covered</h6>
            <p>
              Post that create harm for an individual on any website, dating platform, App, Social
              media, and texting. Any electronic communication that can disseminate harmful content.
              Harm is defined by any post that causes emotional trauma, inability to find work,
              etc…….
            </p>
            <h6>What’s not Covered</h6>
            <p>
              The reporting of sexual assault, harassment, threats of violence, suspicion of violent
              tendencies (school shootings, etc)….
            </p>
          </section>
          */}

          {UnconnectedAgreementReview.isOverAgeOfEighteen(dob)
            ? this.renderRegularAgreementContent()
            : this.renderUnderAgeAgreementContent()}

          <section>
            <form>
              <h6>Party 1</h6>
              <div className="inputs">
                <div className="input-container">
                  <div>{fullName}</div>
                  <label>Print Name</label>
                </div>

                <div className="input-container signature">
                  <div>
                    <img
                      onClick={() => this.setState({ isModalVisible: true })}
                      src={
                        this.isSignatureNotEmpty() ? formSignatureDataUrl : '/images/sign-icon.png'
                      }
                      alt="sign-icon"
                    />
                  </div>
                  <label>Signed</label>
                </div>

                <div className="input-container">
                  <div>{this.isSignatureNotEmpty() && formSignatureDated}</div>
                  <label>Dated</label>
                </div>

                <div className="input-container">
                  <div>{state}</div>
                  <label>State</label>
                </div>

                <div className="input-container">
                  <div>
                    {phoneNumber &&
                      new PhoneNumberFormatter(phoneNumber).format({ type: 'domestic' }).toString()}
                  </div>
                  <label>Phone Number</label>
                </div>

                <div className="input-container">
                  <div>
                    {dob && format(parse(dob, 'yyyy-MM-dd', subYears(new Date(), 18)), 'M/d/yyyy')}
                  </div>
                  <label>Date of Birth</label>
                </div>
              </div>

              <h6>Party 2</h6>
              <div className="inputs">
                <div className="input-container">
                  <div>{toFullName}</div>
                  <label>Print Name</label>
                </div>

                <div className="input-container">
                  <div className="will-send-text will-sign-text">
                    We will send to {toFullName} to sign
                  </div>
                  <label>Signed</label>
                </div>

                <div className="input-container">
                  <div className="will-send-text">
                    Date to be determined by {toFullName}
                  </div>
                  <label>Dated</label>
                </div>

                <div className="input-container">
                  <div className="will-send-text">
                    State to be determined by {toFullName}
                  </div>
                  <label>State</label>
                </div>

                <div className="input-container">
                  <div>
                    {toPhoneNumber &&
                      new PhoneNumberFormatter(toPhoneNumber)
                        .format({ type: 'domestic' })
                        .toString()}
                  </div>
                  <label>Phone Number</label>
                </div>

                <div className="input-container">
                  <div className="will-send-text">
                    DOB to be determined by {toFullName}
                  </div>
                  <label>Date of Birth</label>
                </div>
              </div>

              <div className="form-row">
                <p>
                  By signing this, you confirm your agreement to the{' '}
                  <Link to="/term-of-use">Terms of Service</Link>
                  .
                </p>
                <Button type="submit" onClick={this.handleNext} disabled={isUpdatingData}>
                  {this.props.membershipStatus === 'subscriber' ? 'Send the Agreement' : 'Next'}
                </Button>
              </div>
            </form>
          </section>
        </main>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { isAuthenticated } = state.me.main;

  return {
    isAuthenticated,
    isUpdatingData: state.createAgreement.updateData.isUpdatingData,
    membershipStatus: state.me.main.membershipStatus,
    formSignatureDataUrl: state.createAgreement.form.signatureDataUrl.value,
    formSignatureDated: state.createAgreement.form.signatureDated.value,

    fullName: isAuthenticated ? state.me.main.fullName : state.createAgreement.form.fullName.value,
    dob: isAuthenticated ? state.me.main.dob : state.createAgreement.form.dob.value,
    phoneNumber: isAuthenticated
      ? state.me.main.phoneNumber
      : state.createAgreement.form.phoneNumber.value,
    state: isAuthenticated ? state.me.main.state : state.createAgreement.form.state.value,
    toFullName: state.createAgreement.form.toFullName.value,
    toPhoneNumber: state.createAgreement.form.toPhoneNumber.value,
    code: state.createAgreement.form.code.value,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetFormField(field, value) {
      dispatch(actionCreator.setFormField(field, value));
    },

    dispatchCreateAgreementDirectly(...params) {
      dispatch(actionCreator.createAgreementDirectly(...params));
    },
  };
}

const AgreementReview = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedAgreementReview);

export { UnconnectedAgreementReview, AgreementReview as default };
