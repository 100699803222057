import buildFormReducer from './builders/form';
import buildUpdateDataReducer from './builders/updateData';
import actionTypes, { namespaces } from '../actionTypes';
import { combineReducers } from 'redux';

const { COUNTER_SIGN } = actionTypes;

const mainInitialState = {
  _id: undefined,
  phoneNumber: undefined,
  dob: undefined,
  state: undefined,
  fullName: undefined,
  signatureDataUrlFromRequester: undefined,
  signatureDatedFromRequester: undefined,

  toPhoneNumber: undefined,
  toDob: undefined,
  toState: undefined,
  toFullNameFromRequester: undefined,
  toFullNameFromReceiver: undefined,
  signatureDataUrlFromReceiver: undefined,
  signatureDatedFromReceiver: undefined,

  agreementName: undefined,
  agreementVersion: undefined,
  status: undefined,
  requestedTimestamp: undefined,
  agreementCompletedTimestamp: undefined,
};
const formInitialState = {
  // fullName: {
  //  value: '',
  // },
  // dob: {
  //  value: '',
  // },
  // state: {
  //  value: 'AL',
  // },

  code: {
    value: '',
  },

  signatureDataUrl: {
    value: undefined,
  },
  signatureDated: {
    value: undefined,
  },
};
const loadDataInitialState = {
  isLoadingData: false,
};
const updateDataInitialState = {
  isUpdatingData: false,
  error: {
    isVisible: false,
    message: '',
  },
};

const mainReducer = (state = mainInitialState, action) => {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case COUNTER_SIGN.RESET_STATE:
      return { ...mainInitialState };
    case COUNTER_SIGN.SET_DATA:
      return {
        ...state,
        ...actionPayload,
      };
    default:
      return state;
  }
};

const counterSignReducer = combineReducers({
  main: mainReducer,
  form: buildFormReducer(formInitialState, namespaces.COUNTER_SIGN),
  loadData: buildUpdateDataReducer(loadDataInitialState, namespaces.COUNTER_SIGN),
  updateData: buildUpdateDataReducer(updateDataInitialState, namespaces.COUNTER_SIGN),
});

export { counterSignReducer as default };
