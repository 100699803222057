import actionCreator from '../actionCreators/myDashboard';
import CheckboxInput from '../components/CheckboxInput';
import Button from '../components/Button';
import { willRedirectBackFromLogin, showModal } from '../utils/helpers';
import { errorMessages, agreementStatuses, agreementSortBy } from '../utils/constants';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import {
  FaSignature as Signature,
  FaRegPaperPlane as PaperPlaneOutline,
  FaBalanceScale as BalanceScale,
  FaRegFileAlt as FileAlt,
} from 'react-icons/fa/';
import PhoneNumberFormatter from 'phone-number-formats';
import { format, parse, parseISO } from 'date-fns';
import { Select } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';

import '../../static/assets/styles/containers/myDashboard.scss';

class UnconnectedMyDashboard extends Component {
  static propTypes = {
    isLoadingData: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    myPhoneNumber: PropTypes.string,
    formSortBy: PropTypes.string,
    formShowPendingOnly: PropTypes.bool.isRequired,
    formShowRequireSigningOnly: PropTypes.bool.isRequired,
    myAgreements: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        fullName: PropTypes.string.isRequired,
        phoneNumber: PropTypes.string.isRequired,
        toPhoneNumber: PropTypes.string.isRequired,
        toFullNameFromRequester: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        signatureDatedFromReceiver: PropTypes.string,
        requestedTimestamp: PropTypes.string.isRequired,
      })
    ).isRequired,

    dispatchSetFormField: PropTypes.func.isRequired,
    dispatchInitialize: PropTypes.func.isRequired,
    dispatchResendAgreement: PropTypes.func.isRequired,

    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    formSortBy: undefined,
    myPhoneNumber: undefined,
  };

  async componentDidMount() {
    try {
      await this.props.dispatchInitialize();
    } catch (err) {
      if (err.message === errorMessages.unauthenticated) {
        willRedirectBackFromLogin(this.props.history);
      }
    }
  }

  handleInputChange(field, value) {
    this.props.dispatchSetFormField(field, value);
  }

  handleCheckBoxChange(field, isChecked) {
    this.props.dispatchSetFormField(field, isChecked);
  }

  static handleDownload(id) {
    window.open(`/counter-sign/confirmation?id=${id}`);
  }

  renderMyActivityTimelineElements() {
    const {
      myAgreements,
      formShowPendingOnly,
      formShowRequireSigningOnly,
      formSortBy,
      myPhoneNumber,
    } = this.props;

    return myAgreements
      .filter(agreement => {
        let willBeIncluded;

        if (formShowPendingOnly || formShowRequireSigningOnly) {
          willBeIncluded = false;

          if (formShowPendingOnly && agreement.status === agreementStatuses.pending) {
            willBeIncluded = true;
          }

          if (
            formShowRequireSigningOnly &&
            (agreement.status !== agreementStatuses.completed &&
              agreement.phoneNumber !== myPhoneNumber)
          ) {
            willBeIncluded = true;
          }
        } else {
          willBeIncluded = true;
        }

        return willBeIncluded;
      })
      .sort((first, second) => {
        if (formSortBy) {
          if (formSortBy === agreementSortBy.receiverName) {
            return first.toFullNameFromRequester.localeCompare(second.toFullNameFromRequester);
          }

          if (formSortBy === agreementSortBy.agreementStatus) {
            return first.status.localeCompare(second.status);
          }
        }
        return first.requestedTimestamp - second.requestedTimestamp;
      })
      .map(agreement => {
        let isInitiatedByMe;

        if (agreement.phoneNumber === myPhoneNumber) {
          isInitiatedByMe = true;
        } else {
          isInitiatedByMe = false;
        }
        const name = isInitiatedByMe ? agreement.toFullNameFromRequester : agreement.fullName;
        const initials = name
          .trim()
          .split(' ')
          .map(part => part[0].toUpperCase())
          .join('');
        const tagClassName = cx(
          'tag',
          agreement.status === agreementStatuses.pending ? 'warning' : 'success'
        );

        return (
          <VerticalTimelineElement
            key={agreement._id}
            contentStyle={{ background: 'transparent', color: 'black', boxShadow: 'none' }}
            contentArrowStyle={{ borderRight: 'transparent' }}
            iconStyle={{ background: '#62C7F3', color: '#fff' }}
            icon={<div className="timeline-icon">{initials}</div>}
          >
            <div className="timeline-element">
              <section>
                <h5 className="timeline-element-header">
                  <div className={tagClassName}>{agreement.status.toUpperCase()}</div>
                  <span>
                    {agreement.signatureDatedFromReceiver &&
                      `Signed on ${format(
                        parse(agreement.signatureDatedFromReceiver, 'M/d/yyyy', new Date()),
                        'MMM d, yyyy'
                      )}`}
                  </span>
                </h5>
                <h3 className="timeline-element-title">{name}</h3>
                <h4 className="timeline-element-subtitle">
                  {new PhoneNumberFormatter(
                    isInitiatedByMe ? agreement.toPhoneNumber : agreement.phoneNumber
                  )
                    .format({ type: 'domestic' })
                    .toString()}
                </h4>
                <h4 className="timeline-element-subtitle">
                  {isInitiatedByMe ? 'Sent' : 'Received'} on{' '}
                  {format(parseISO(agreement.lastOpenedByReceiverTimestamp || agreement.requestedTimestamp), 'MMM d, yyyy, h:mm aaaa')}
                </h4>
              </section>

              <section>
                {this.renderMyActivityTimelineElementButton(isInitiatedByMe, agreement)}
              </section>
            </div>
          </VerticalTimelineElement>
        );
      });
  }

  renderMyActivityTimelineElementButton(isInitiatedByMe, agreementData) {
    if (agreementData.status === agreementStatuses.completed) {
      return [
        <button
          key="dispute"
          onClick={() =>
            showModal('Please follow the instructions to begin the dispute process.', 'info')}
        >
          <BalanceScale />
          <span>Dispute</span>
        </button>,
        <button
          key="download"
          onClick={UnconnectedMyDashboard.handleDownload.bind(this, agreementData._id)}
        >
          <FileAlt />
        </button>,
      ];
    }
    if (isInitiatedByMe) {
      return [
        <button
          key="resend"
          onClick={this.props.dispatchResendAgreement.bind(
            this,
            agreementData._id,
            agreementData.toFullNameFromRequester,
            agreementData.fullName,
            agreementData.toPhoneNumber
          )}
        >
          <PaperPlaneOutline />
          <span>Resend</span>
        </button>,
      ];
    }
    return (
      <button
        className="sign-now"
        onClick={UnconnectedMyDashboard.handleDownload.bind(this, agreementData._id)}
      >
        <Signature />
        <span>Sign Now</span>
      </button>
    );
  }

  render() {
    const {
      isLoadingData,
      isAuthenticated,
      formSortBy,
      formShowPendingOnly,
      formShowRequireSigningOnly,
    } = this.props;

    if (isLoadingData || !isAuthenticated) {
      return null;
    }

    return (
      <div className="my-dashboard">
        <main>
          <div className="white-board">
            <div className="main-header">
              <h4>My Dashboard</h4>
              <Button
                type="button"
                onClick={() => this.props.history.push('/create-agreement/basic-info')}
              >
                Create
              </Button>
            </div>
            <div className="panes">
              <section className="pane control">
                <Select
                  className="sort-by-select"
                  allowClear={true}
                  onChange={this.handleInputChange.bind(this, 'sortBy')}
                  placeholder="Sort by"
                  value={formSortBy}
                >
                  <Select.Option value={agreementSortBy.createdTimestamp}>
                    {agreementSortBy.createdTimestamp}
                  </Select.Option>
                  <Select.Option value={agreementSortBy.receiverName}>
                    {agreementSortBy.receiverName}
                  </Select.Option>
                  <Select.Option value={agreementSortBy.agreementStatus}>
                    {agreementSortBy.agreementStatus}
                  </Select.Option>
                </Select>

                <CheckboxInput
                  onChange={this.handleCheckBoxChange.bind(this, 'showPendingOnly')}
                  value={formShowPendingOnly}
                >
                  Show pending only
                </CheckboxInput>
                <CheckboxInput
                  onChange={this.handleCheckBoxChange.bind(this, 'showRequireSigningOnly')}
                  value={formShowRequireSigningOnly}
                >
                  Show require signing only
                </CheckboxInput>
              </section>

              <section className="pane">
                <VerticalTimeline className="pn-timeline" layout="1-column">
                  {this.renderMyActivityTimelineElements()}
                </VerticalTimeline>
              </section>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoadingData: state.myDashboard.loadData.isLoadingData,
  isAuthenticated: state.me.main.isAuthenticated,
  myPhoneNumber: state.me.main.phoneNumber,
  formSortBy: state.myDashboard.form.sortBy.value,
  formShowPendingOnly: state.myDashboard.form.showPendingOnly.value,
  formShowRequireSigningOnly: state.myDashboard.form.showRequireSigningOnly.value,
  myAgreements: state.myDashboard.main.myAgreements,
});

function mapDispatchToProps(dispatch) {
  return {
    dispatchInitialize(id) {
      return dispatch(actionCreator.initialize(id));
    },

    dispatchSetFormField(field, value) {
      dispatch(actionCreator.setFormField(field, value));
    },

    dispatchResendAgreement(...params) {
      dispatch(actionCreator.resendAgreement(...params));
    },
  };
}

const MyDashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedMyDashboard);

export { UnconnectedMyDashboard, MyDashboard as default };
