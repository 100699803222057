import * as datasource from './datasource';
import buildFormActionCreator from '../builders/form';
import buildUpdateDataActionCreator from '../builders/updateData';
import { showModal } from '../../utils/helpers';
import actionTypes, { namespaces } from '../../actionTypes';

const { CONTACT_US } = actionTypes;

export default {
  ...buildFormActionCreator(namespaces.CONTACT_US),
  ...buildUpdateDataActionCreator(namespaces.CONTACT_US),

  resetMainState() {
    return {
      type: CONTACT_US.RESET_STATE,
    };
  },

  resetState() {
    return dispatch => {
      dispatch(this.resetMainState());

      dispatch(this.resetFormState());

      dispatch(this.resetUpdateDataState());
    };
  },

  submitInquiry(payload) {
    return async dispatch => {
      try {
        dispatch(this.updateDataRequest());

        await datasource.submitInquiry(payload);

        showModal('Your inquiry is successfully sent!', 'success', 'Okay');

        dispatch(this.resetState());

        dispatch(this.updateDataSuccess());
      } catch (error) {
        showModal(error.message, 'error');

        dispatch(this.updateDataFailure(error.message));
      }
    };
  },
};
