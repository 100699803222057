import actionCreator from '../../actionCreators/createAgreement';
import meActionCreator from '../../actionCreators/me';
import commonActionCreator from '../../actionCreators/common';
import StepsHeader from '../../components/headers/Steps';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import UsStateSelect from '../../components/UsStateSelect';
import {
  formatPhoneNumber,
  showModal,
  willRedirectBackFromLogin,
  formatDate,
} from '../../utils/helpers';
import DropdownDate from 'react-dropdown-date';
import { isAfter, parse } from 'date-fns';
import PhoneNumberFormatter from 'phone-number-formats';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../../static/assets/styles/containers/createAgreement/basicInfo.scss';

class UnconnectedBasicInfo extends Component {
  static propTypes = {
    isLoadingData: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    isUpdatingData: PropTypes.bool.isRequired,
    formFullName: PropTypes.string.isRequired,
    formDob: PropTypes.string,
    formPhoneNumber: PropTypes.string.isRequired,
    formState: PropTypes.string.isRequired,
    formToFullName: PropTypes.string.isRequired,
    formToPhoneNumber: PropTypes.string.isRequired,

    dispatchResetState: PropTypes.func.isRequired,
    dispatchSetFormField: PropTypes.func.isRequired,
    dispatchCheckAuthentication: PropTypes.func.isRequired,
    dispatchRequestVerification: PropTypes.func.isRequired,

    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    formDob: undefined,
  };

  state = {
    isUnderageModalShown: false,
    isAdditionalInfoShown: false,
  };

  static isPhoneNumberValid(phoneNumber) {
    try {
      new PhoneNumberFormatter(phoneNumber).format({ type: 'domestic' });
      return true;
    } catch (error) {
      return false;
    }
  }

  static isUnderAgeOfThirteen(dobValue) {
    const today = new Date();
    const thirteenYearsAgo = new Date(today.getFullYear() - 13, today.getMonth(), today.getDate());

    return isAfter(parse(dobValue, 'yyyy-MM-dd', today), thirteenYearsAgo);
  }

  componentDidMount() {
    this.props.dispatchResetState();

    this.props.dispatchCheckAuthentication();
  }

  onDobInputComplete = dobValue => {
    const today = new Date();
    const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

    if (UnconnectedBasicInfo.isUnderAgeOfThirteen(dobValue)) {
      showModal('You must be 13 years of age to sign a PlayNice pledge.', 'warning', 'Confirm');
    } else if (isAfter(parse(dobValue, 'yyyy-MM-dd', today), eighteenYearsAgo)) {
      this.toggleUnderageModalVisibility();
    }
  };

  handleInputChange(field, event) {
    this.props.dispatchSetFormField(field, event.target ? event.target.value : event);
  }

  handleNext = event => {
    event.preventDefault();

    const {
      isAuthenticated,
      formFullName,
      formDob,
      formPhoneNumber,
      formState,
      formToFullName,
      formToPhoneNumber,
      history,
      dispatchRequestVerification,
    } = this.props;

    if (UnconnectedBasicInfo.isUnderAgeOfThirteen(formDob)) {
      showModal('You must be 13 years of age to sign a PlayNice pledge.', 'warning', 'Confirm');
      return;
    }

    if (isAuthenticated) {
      if (!formToFullName || !formToPhoneNumber) {
        showModal('At least one of the fields is missing!');
      } else if (!UnconnectedBasicInfo.isPhoneNumberValid(formToPhoneNumber)) {
        showModal('The phone number you want to send to is invalid!');
      } else {
        history.push('/create-agreement/agreement-review');
      }
    } else if (
      !formFullName ||
      !formDob ||
      !formPhoneNumber ||
      !formState ||
      !formToFullName ||
      !formToPhoneNumber
    ) {
      showModal('At least one of the fields is missing!');
    } else if (!UnconnectedBasicInfo.isPhoneNumberValid(formToPhoneNumber)) {
      showModal('The phone number you want to send to is invalid!');
    } else {
      dispatchRequestVerification(formatPhoneNumber(formPhoneNumber), 'sms', () => {
        history.push('/create-agreement/verify-phone-number');
      });
    }
  };

  toggleUnderageModalVisibility = () => {
    this.setState({ isUnderageModalShown: !this.state.isUnderageModalShown });
  };

  render() {
    const {
      isLoadingData,
      isAuthenticated,
      isUpdatingData,
      formFullName,
      // formDob,
      formPhoneNumber,
      // formState,
      formToFullName,
      formToPhoneNumber,
      history,
    } = this.props;

    if (isLoadingData) {
      return null;
    }

    return (
      <div className="basic-info">
        <Modal
          isVisible={this.state.isUnderageModalShown}
          hasTopRightCloseButton={false}
          onOk={this.toggleUnderageModalVisibility}
          onCancel={this.toggleUnderageModalVisibility}
          centered={true}
        >
          <div>
            Since you are under the age of 18, you are only eligible to sign a FREE anti
            Cyber-Bullying pledge with anyone you’d like, as long as they are under the age of
            18.&nbsp;
            {!this.state.isAdditionalInfoShown && [
              'For more information ',
              <span
                key="click here"
                className="link"
                onClick={() => this.setState({ isAdditionalInfoShown: true })}
              >
                click here
              </span>,
            ]}
            {this.state.isAdditionalInfoShown &&
              'You’ll both promise to protect each other from all forms of bullying, and be part of the movement to end cyber bullying for good. Once you both turn 18, you’ll be notified to upgrade your agreement to a fully protected adult agreement. Enjoy and be safe! Please Continue with the agreement.'}
          </div>
        </Modal>

        <StepsHeader currentStepIndex={0} />

        <main>
          <form>
            {!isAuthenticated && [
              <div key="Tell us about yourself" className="inputs">
                <h3>
                  Congratulations, you’re taking the first step to help protecting yourself and
                  someone you know from online bullying and harm. Please begin by completing the
                  questions below:
                </h3>
                <p className="align-left">
                  Already have a PlayNice account?{' '}
                  <Link to={willRedirectBackFromLogin(history, false)}>Sign in</Link>
                </p>

                <div className="input-container">
                  <label htmlFor="full-name">Full Name</label>
                  <input
                    autoComplete="name"
                    placeholder="John Doe"
                    id="full-name"
                    value={formFullName}
                    onChange={this.handleInputChange.bind(this, 'fullName')}
                  />
                </div>

                <div className="input-container">
                  <label htmlFor="dropdown-date">DOB</label>
                  <DropdownDate
                    startDate="1905-01-01" // 'yyyy-mm-dd' format only
                    // selectedDate={formDob} // 'yyyy-mm-dd' format only
                    order={['month', 'day', 'year']}
                    onDateChange={date => {
                      const formattedDate = formatDate(date);

                      this.onDobInputComplete(formattedDate);
                      this.handleInputChange('dob', formattedDate);
                    }}
                    classes={{
                      dateContainer: 'custom-dropdown-date',
                      year: 'custom-dropdown-date-select',
                      month: 'custom-dropdown-date-select',
                      day: 'custom-dropdown-date-select',
                    }}
                    defaultValues={{ month: 'Month', day: 'Day', year: 'Year' }}
                    options={{ yearReverse: true, monthShort: true, monthCaps: false }}
                  />
                </div>

                <div className="input-container">
                  <label htmlFor="phone-number">Phone Number</label>
                  <input
                    autoComplete="tel"
                    type="tel"
                    placeholder="(555) 555-5555"
                    id="phone-number"
                    value={formPhoneNumber}
                    onChange={this.handleInputChange.bind(this, 'phoneNumber')}
                  />
                </div>
              </div>,

              <div key="Which state do you live in" className="inputs">
                <h3 className="with-subtitle">Which state do you live in?</h3>
                <h4>So we can make sure the right details are included in your agreement.</h4>

                <div className="input-container">
                  <UsStateSelect onChange={this.handleInputChange.bind(this, 'state')} />
                </div>
              </div>,
            ]}

            <div className="inputs">
              <h3>Who do you want you PlayNice with?</h3>

              <div className="input-container">
                <label htmlFor="to-full-na me">Full Name</label>
                <input
                  autoComplete="name"
                  placeholder="John Doe"
                  id="to-full-name"
                  value={formToFullName}
                  onChange={this.handleInputChange.bind(this, 'toFullName')}
                />
              </div>

              <div className="input-container">
                <label htmlFor="to-phone-number">Phone Number</label>
                <input
                  autoComplete="tel"
                  type="tel"
                  placeholder="(555) 555-5555"
                  id="to-phone-number"
                  value={formToPhoneNumber}
                  onChange={this.handleInputChange.bind(this, 'toPhoneNumber')}
                />
              </div>
            </div>

            <div className="form-row">
              <Button type="submit" onClick={this.handleNext} disabled={isUpdatingData}>
                Next
              </Button>
              {!isAuthenticated && (
                <p>
                  Already have a PlayNice account?{' '}
                  <Link to={willRedirectBackFromLogin(history, false)}>Sign in</Link>
                </p>
              )}
            </div>
          </form>
        </main>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoadingData: state.me.loadData.isLoadingData,
  isAuthenticated: state.me.main.isAuthenticated,
  isUpdatingData: state.common.updateData.isUpdatingData,
  formFullName: state.createAgreement.form.fullName.value,
  formDob: state.createAgreement.form.dob.value,
  formPhoneNumber: state.createAgreement.form.phoneNumber.value,
  formState: state.createAgreement.form.state.value,
  formToFullName: state.createAgreement.form.toFullName.value,
  formToPhoneNumber: state.createAgreement.form.toPhoneNumber.value,
});

const mapDispatchToProps = dispatch => ({
  dispatchResetState() {
    dispatch(actionCreator.resetState());
  },

  dispatchCheckAuthentication() {
    dispatch(meActionCreator.checkAuthentication());
  },

  dispatchSetFormField(field, value) {
    dispatch(actionCreator.setFormField(field, value));
  },

  dispatchRequestVerification(...params) {
    dispatch(commonActionCreator.requestVerification(...params));
  },
});

const BasicInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedBasicInfo);

export { UnconnectedBasicInfo, BasicInfo as default };
