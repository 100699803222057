import PageFooter from './PageFooter';
import CreateAgreementFooter from '../components/CreateAgreementFooter';
import Button from '../components/Button';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { FaPlayCircle as PlayCircle } from 'react-icons/fa/';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import '../../static/assets/styles/containers/howItWorks.scss';

export default class HowItWorks extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  goToCreateAgreement = () => {
    this.props.history.push('/create-agreement/basic-info');
  };

  render() {
    return (
      <div className="how-it-works">
        <section className="intro">
          <div className="intro-statements">
            <h1>How PlayNice Works</h1>
            <p>
              PlayNice is the first platform that legally protects you from cyber bullying. Our agreements protect you for life from someone posting something online that can cause you harm.
            </p>

            <div className="buttons">
              <Button className="watch-video-btn" onClick={() => window.open('https://www.youtube.com/playniceapp', '_blank')}>
                <PlayCircle className="play-icon" />
                Watch Video
              </Button>
            </div>
          </div>

          <div className="intro-image" />
        </section>

        <section className="privacy">
          <div className="privacy-image-container">
            <div className="privacy-image" />
          </div>

          <div className="privacy-description">
            <h2>Get Protected. It’s insanely easy to get started!</h2>
            <p>It doesn’t matter if it’s a photo, a trusted secret, or even a lie.  It’s protected using the same type of agreements the rich and famous use, however, PlayNice agreements protect both parties, allowing complete fairness when entering into this commitment to protect each other.  These agreements are proven and they have worked for only those that had the means to afford them.  Until now.</p>
            <p>If someone breaks your agreement, they can be held financially accountable for the damage they have caused you, your reputation and your future.  Without a PlayNice agreement you may not have the ability demand compensation for the damages because too often it’s perfectly legal to harm you online, as it’s often protected by out first amendment.  PlayNice puts a stop to people’s ability to use the law to harm each other.</p>
          </div>
        </section>

        <section className="impact">
          <div className="impact-intro">
            <h1>How To PlayNice</h1>
            <p>VIDEO: Watch the breakdown of how to use our groundbreaking app.</p>
          </div>

          <div className="impact-video">
            <div className="iframe-container">
              <iframe
                src="https://player.vimeo.com/video/389738917"
                allow="autoplay; fullscreen"
                allowFullScreen={true}
              />
            </div>
          </div>
        </section>

        <section className="steps">
          <div className="steps-intro">
            <h2>How We Do it</h2>
            <p>
              PlayNice has created an agreement between two parties that prevents them from harming each other online. Share what you want to share, and feel safe that you won’t be harmed.
            </p>
            <p>
              Celebrities have often kept their lives behind closed doors safe, and when they don’t have an agreement in place, is when we see the news has gone viral all over social media with it’s gory details and everyone has something to say. Don’t let that happen to you, in your private circles.
            </p>
            <Button className="get-started-btn" onClick={this.goToCreateAgreement}>
              Create an Agreement
            </Button>
          </div>

          <div className="steps-details">
            <VerticalTimeline className="pn-timeline-basic" layout="1-column">
              <VerticalTimelineElement
                contentStyle={{ background: 'transparent', color: 'black', boxShadow: 'none' }}
                contentArrowStyle={{ borderRight: 'transparent' }}
                iconStyle={{ background: '#EB008B', color: '#fff' }}
                icon={<div className="timeline-basic-icon">1</div>}
              >
                <h4 className="timeline-basic-element-title">Choose</h4>
                <p className="timeline-basic-element-description">
                  Who do you want to sign and protect a relationship with? A romantic partner or an ex with whom you always want to keep things private. A close friend that knows your deepest secrets, or perhaps some new people that you’e met on social media or dating apps? Sign them with anyone you know, and protect each other so no matter what happens in the relationship, you are both protected.
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                contentStyle={{ background: 'transparent', color: 'black', boxShadow: 'none' }}
                contentArrowStyle={{ borderRight: 'transparent' }}
                iconStyle={{ background: '#EB008B', color: '#fff' }}
                icon={<div className="timeline-basic-icon">2</div>}
              >
                <h4 className="timeline-basic-element-title">Invite</h4>
                <p className="timeline-basic-element-description">
                  Once you’ve chosen who you want to protect your relationship with, simply sign the agreement and send to them. Once they countersign, you’ll both always have access to the agreement in your accounts, and most importantly, you’re both protected.
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                contentStyle={{ background: 'transparent', color: 'black', boxShadow: 'none' }}
                contentArrowStyle={{ borderRight: 'transparent' }}
                iconStyle={{ background: '#EB008B', color: '#fff' }}
                icon={<div className="timeline-basic-icon">3</div>}
              >
                <h4 className="timeline-basic-element-title">Sign</h4>
                <p className="timeline-basic-element-description">
                  On any smartphone, simply swipe your finger signature where it says “sign” and continue the process to send. PlayNice also works on tablets, laptops and desktops with the same ease of use as your smartphone.
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                contentStyle={{ background: 'transparent', color: 'black', boxShadow: 'none' }}
                contentArrowStyle={{ borderRight: 'transparent' }}
                iconStyle={{ background: '#EB008B', color: '#fff' }}
                icon={<div className="timeline-basic-icon">4</div>}
              >
                <h4 className="timeline-basic-element-title">Enjoy</h4>
                <p className="timeline-basic-element-description">
                  Now breathe. You can carry on with your relationship knowing that you’re safe, and can share whatever you’d like, and your private life is now where it belongs. In private.
                </p>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </div>
        </section>

        <section className="our-plans">
          <img className="our-plans-img" src="/images/our-plans.png" alt="how we do it" />

          <div className="our-plans-highlights">
            <h2>Our Plans</h2>
            <p>
              PlayNice has created an agreement between two parties that prevents them from harming each other online. Share what you want to share, and feel safe that you won’t be harmed.
            </p>

            <div className="plan">
              <h2>Unlimited One Year</h2>
              <h5>$9.29 / year</h5>

              <h6>+ Unlimited Agreements</h6>
              <h6>+ Ongoing Access</h6>
              <h6>+ Binding Agreement</h6>
            </div>

            <div className="plan">
              <h2>Pay Per Play</h2>
              <h5>$2.29 / each</h5>

              <h6>+ Cost per Individual Contract</h6>
              <h6>+ Ongoing Access</h6>
              <h6>+ Binding Agreement</h6>
            </div>

            <div className="plan">
              <h2>Under 18</h2>
              <h5>FREE / each</h5>

              <h6>+ Agreements Are Promises And Not Enforceable</h6>
              <h6>+ Ongoing Access</h6>
              <h6>+ Upgrade When Both Turn 18</h6>
            </div>
          </div>
        </section>

        <section className="features">
          <div className="features-container">
            <h2>The Agreement You Can Trust</h2>
            <p>
              If an agreement is broken, please logon to your account a file a “dispute”. This will give the other party 10 days to remedy the situation, if possible, by taking down the post, issuing an apology or whatever method is best for your situation. If after 10 days, the damage has been done and the harm is irreversible, then you can file for Arbitration. Arbitration is binding like court, but it’s faster, less expensive and PRIVATE, so whatever embarrassing situation has led you to this point, it won’t be for public consumption. The arbitrator has authority under this agreement to determine fault, assess the harm and determine financial compensation if they so choose.
            </p>
            <p>
              Please carefully read the <Link to="/term-of-use">PlayNice Terms of Use</Link>
            </p>
            <p>
              PlayNice is not just a promise or an awareness campaign. This is real, it’s binding, and the future of your reputation deserves something that truly protects you.
            </p>
          </div>

          <Button className="learn-how-btn" onClick={() => {}}>Read full agreement</Button>
          <p className="disclaimer">* We are not a law firm or a substitute for an attorney or law firm. We cannot provide any kind of advice, explanation, opinion, or recommendation about possible legal rights, remedies, defenses, options, selection of forms or strategies.</p>
        </section>

        <section className="protections">
          <h2>PlayNice Protects All</h2>

          <div className="protections-blocks">
            <div className="protection-block">+ Revenge posts from ex’s</div>
            <div className="protection-block">+ Things that impact your employment</div>
            <div className="protection-block">+ Outing of sexuality or preference</div>
            <div className="protection-block">+ Embarrassing family drama</div>
            <div className="protection-block">+ Shaming of lifestyle or choices</div>
            <div className="protection-block">+ Anything that can have emotional, physical or professional harm</div>
            <div className="protection-block">+ Things posted out of context to harm</div>
            <div className="protection-block">+ Inaccurate portrayals of your character</div>
            <div className="protection-block">+ Online shaming and bullying</div>
            <div className="protection-block">+ From having someone else post something harmful on their behalf</div>
            <div className="protection-block">+ Revenge Porn or graphic photos</div>
          </div>
        </section>

        <CreateAgreementFooter className="new-design-create-agreement-footer" />
        <PageFooter />
      </div>
    );
  }
}
