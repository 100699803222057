import * as datasource from './datasource';
import buildUpdateDataActionCreator from '../builders/updateData';
import { showModal } from '../../utils/helpers';
import { noop } from '../../utils/constants';
import actionTypes, { namespaces } from '../../actionTypes';

const { COMMON } = actionTypes;

export default {
  ...buildUpdateDataActionCreator(namespaces.COMMON),

  resetMainState() {
    return {
      type: COMMON.RESET_STATE,
    };
  },

  resetState() {
    return dispatch => {
      dispatch(this.resetMainState());

      dispatch(this.resetUpdateDataState());
    };
  },

  requestVerification(phoneNumber, channel, onSuccess = noop) {
    return async dispatch => {
      try {
        dispatch(this.updateDataRequest());

        await datasource.requestVerification(phoneNumber, channel);

        await onSuccess();

        dispatch(this.updateDataSuccess());
      } catch (error) {
        let errorMsg = error.message;

        if (error.message === 'Invalid parameter: To') {
          errorMsg = 'The phone number is invalid!';
        }

        showModal(errorMsg, 'error');

        dispatch(this.updateDataFailure(errorMsg));
      }
    };
  },

  checkVerification(code, phoneNumber, onSuccess) {
    return async dispatch => {
      try {
        dispatch(this.updateDataRequest());

        const {
          data: { isUserExisted },
        } = await datasource.checkVerification(code, phoneNumber);

        await onSuccess(isUserExisted);

        dispatch(this.updateDataSuccess());
      } catch (error) {
        showModal('The verification code is incorrect!');

        dispatch(this.updateDataFailure(error.message));
      }
    };
  },
};
