import PageFooter from './PageFooter';
import CreateAgreementFooter from '../components/CreateAgreementFooter';
import Button from '../components/Button';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import '../../static/assets/styles/containers/home.scss';

export default class Home extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  goToCreateAgreement = () => {
    this.props.history.push('/create-agreement/basic-info');
  };

  render() {
    return (
      <div className="home">
        <section className="intro">
          <div className="intro-statements">
            <h1>Keep Your Privates, Private.</h1>
            <p>
              Use the same confidentiality agreements celebrities use to keep the intimate details of their  private lives, private.
            </p>
            <p>
              This agreement protects you from people posting anything that can harm you, on any App, or anywhere online.  Period.  You control your digital reputation.
            </p>
            <div className="buttons">
              <Button className="watch-video-btn" onClick={() => this.props.history.push('/how-it-works')}>
                Learn More
              </Button>
              <Button className="learn-more-btn" onClick={this.goToCreateAgreement}>Create Agreement</Button>
            </div>
          </div>

          <div className="intro-image" />
        </section>

        <section className="impact visible-in-mobile">
          <div className="impact-intro">
            <h1>Cyberbullying Impacts All Of Us.</h1>
            <p>Watch this video of 10 people sharing their cyberbullying testimony</p>
          </div>

          <div className="impact-video">
            <div className="iframe-container">
              <iframe
                src="https://player.vimeo.com/video/389575980"
                allow="autoplay; fullscreen"
                allowFullScreen={true}
              />
            </div>
          </div>
        </section>

        <section className="privacy">
          <div className="privacy-image-container">
            <div className="privacy-image" />
          </div>

          <div className="privacy-description">
            <h2>FEEL EMPOWERED!!!….</h2>
            <p>Use the same confidentiality agreements celebrities use to keep the intimate details of their  private lives, private to share what you want, with who you want, without fear.</p>
            <p className="above-create-agreement-button">Say goodbye to ugly breakups, photos shared that were meant to be private, or damaging content posted out of jealousy or revenge.  Your digital footprint is in finally in your control.</p>
            <Button className="create-agreement-btn" onClick={this.goToCreateAgreement}>Create an Agreement</Button>

            <div className="privacy-highlights">
              <img className="vertical-separator" src="/images/vertical-separator.png" alt="vertical separator" />

              <div className="highlights-container">
                <h3>Privacy Is The New Luxury</h3>
                <p>
                  With the Internet taking control of your data, be in control of what you share with others, and what they can share about you. PlayNice agreements prevent people from posting things about you that are harmful.
                </p>

                <h3>Be The First</h3>
                <p className="no-margin">
                  Be the first generation to take control of your data. Take control of how people see you online, from friends and family, to future employers. Make sure your digital footprint stays the way you want, and doesn't put you in jeopardy in the future.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="impact hidden-in-mobile">
          <div className="impact-intro">
            <h1>Cyberbullying Impacts All Of Us.</h1>
            <p>Watch this video of 10 people sharing their cyberbullying testimony</p>
          </div>

          <div className="impact-video">
            <div className="iframe-container">
              <iframe
                src="https://player.vimeo.com/video/389575980"
                allow="autoplay; fullscreen"
                allowFullScreen={true}
              />
            </div>
          </div>
        </section>

        <section className="features">
          <h2 className="feature-header">The Contract You Can Trust.</h2>

          <div className="features-container">
            <div className="features-row">
              <div className="feature-container">
                <img className="feature-icon" src="/images/legal-team-icon.png" alt="legal team" />
                <div>
                  <h3>Legal Team</h3>
                  <p>
                    PlayNice has assembled the top corporate, divorce and general practice lawyers that understand how people are harmed and how people seek revenge online. One of our lead lawyers even helped write the revenge porn laws in the State of New York.
                  </p>
                </div>
              </div>
              <div className="feature-container">
                <img className="feature-icon" src="/images/accessible-icon.png" alt="accessible" />
                <div>
                  <h3>Accessible</h3>
                  <p>
                    Celebrity confidentiality agreements can be difficult to execute, and often take lawyers and managers to help them get signed. You can access and initiate PlayNice agreements anytime on your smartphone or computer.
                  </p>
                </div>
              </div>
            </div>
            <div className="features-row">
              <div className="feature-container">
                <img
                  className="feature-icon"
                  src="/images/accountability-icon.png"
                  alt="accountability"
                />
                <div>
                  <h3>Accountability</h3>
                  <p>
                    PlayNice uses technology to help ensure that people don’t make false claims they did not sign, which often happens with paper agreements. PlayNice tools will also help you hold people accountable in the event that you are harmed. You won’t be left on your own.
                  </p>
                </div>
              </div>
              <div className="feature-container">
                <img className="feature-icon" src="/images/private-icon.png" alt="private" />
                <div>
                  <h3>Private and Secure</h3>
                  <p>
                    Nobody wants to read a long legal contract. We provide a simple and easy-to-understand digital version, available on your mobile device or computer.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Button className="learn-how-btn" onClick={() => {}}>Learn How It Works</Button>
          <p className="disclaimer">* We are not a law firm or a substitute for an attorney or law firm. We cannot provide any kind of advice, explanation, opinion, or recommendation about possible legal rights, remedies, defenses, options, selection of forms or strategies.</p>
        </section>

        <section className="testimonials">
          <div className="testimonials-intro">
            <h2>See How PlayNice Can help.</h2>
            <p>
              Everyone has a personal story about their cyberbullying experience, see some below that may resonate with your story.  It happens to #allofus and it’s up to #allofus to fix it.
            </p>
          </div>

          <div className="testimonials-container">
            <div className="testimonial-container">
              <div className="iframe-container">
                <iframe
                  src="https://player.vimeo.com/video/389638131"
                  allow="autoplay; fullscreen"
                  allowFullScreen={true}
                />
              </div>
              <div className="description-container">
                <h2>Brooke</h2>
                <p>“Everyone is so much tougher behind a keyboard”</p>
              </div>
            </div>
            <div className="testimonial-container">
              <div className="iframe-container">
                <iframe
                  src="https://player.vimeo.com/video/389662533"
                  allow="autoplay; fullscreen"
                  allowFullScreen={true}
                />
              </div>
              <div className="description-container">
                <h2>Elizy</h2>
                <p>“They told me i was a mistake”</p>
              </div>
            </div>
            <div className="testimonial-container">
              <div className="iframe-container">
                <iframe
                  src="https://player.vimeo.com/video/389667527"
                  allow="autoplay; fullscreen"
                  allowFullScreen={true}
                />
              </div>
              <div className="description-container">
                <h2>Tyler</h2>
                <p>“She kind of outted me to the whole world when I wasn’t ready”</p>
              </div>
            </div>
          </div>

          <div className="testimonials-footer">
            <Button className="testimonials-footer-btn" onClick={() => window.open('https://www.youtube.com/playniceapp', '_blank')}>
              More User Videos
            </Button>
          </div>
        </section>

        <CreateAgreementFooter className="new-design-create-agreement-footer" />
        <PageFooter />
      </div>
    );
  }
}
