import * as datasource from './datasource';
import buildFormActionCreator from '../builders/form';
import buildUpdateDataActionCreator from '../builders/updateData';
import meActionCreator from '../me';
import { showModal } from '../../utils/helpers';
import actionTypes, { namespaces } from '../../actionTypes';

const { ACCOUNT_SETTINGS } = actionTypes;

export default {
  ...buildFormActionCreator(namespaces.ACCOUNT_SETTINGS),
  ...buildUpdateDataActionCreator(namespaces.ACCOUNT_SETTINGS),

  resetMainState() {
    return {
      type: ACCOUNT_SETTINGS.RESET_STATE,
    };
  },

  resetState() {
    return dispatch => {
      dispatch(this.resetMainState());

      dispatch(this.resetFormState());

      dispatch(this.resetUpdateDataState());
    };
  },

  updateProfile(payload) {
    return async dispatch => {
      try {
        dispatch(this.updateDataRequest());

        const {
          data: { user },
        } = await datasource.updateProfile(payload);

        dispatch(meActionCreator.setData({ ...user }));

        showModal('Your profile has been updated successfully!', 'success', 'Okay');

        dispatch(this.updateDataSuccess());
      } catch (error) {
        showModal(error.message, 'error');

        dispatch(this.updateDataFailure(error.message));
      }
    };
  },
};
