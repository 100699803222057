// import BasicInfo from './BasicInfo';
import VerifyPhoneNumber from './VerifyPhoneNumber';
import CreateAccount from './CreateAccount';
import AgreementReview from './AgreementReview';
import Confirmation from './Confirmation';
import { Route, Redirect, Switch } from 'react-router-dom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import '../../../static/assets/styles/containers/counterSign/index.scss';

// eslint-disable-next-line react/prefer-stateless-function
class CounterSign extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  render() {
    return (
      <div className="counter-sign">
        <Switch>
          {/* <Route exact={true} path={`${this.props.match.path}/basic-info`} component={BasicInfo} />*/}
          <Route
            exact={true}
            path={`${this.props.match.path}/verify-phone-number`}
            component={VerifyPhoneNumber}
          />
          <Route
            exact={true}
            path={`${this.props.match.path}/create-account`}
            component={CreateAccount}
          />
          <Route
            exact={true}
            path={`${this.props.match.path}/agreement-review`}
            component={AgreementReview}
          />
          <Route
            exact={true}
            path={`${this.props.match.path}/confirmation`}
            component={Confirmation}
          />

          <Redirect
            from={`${this.props.match.path}`}
            to={{
              pathname: `${this.props.match.path}/verify-phone-number`,
              search: this.props.location.search,
            }}
          />
        </Switch>
      </div>
    );
  }
}

export { CounterSign as default };
