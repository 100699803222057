import React, { Component } from 'react';
import PropTypes from 'prop-types';

import '../../../static/assets/styles/components/headers/steps.scss';

export default class StepsHeader extends Component {
  getStepClassNameFromIndex(stepIndex) {
    if (stepIndex === this.props.currentStepIndex) {
      return 'current-step';
    }
    return '';
  }

  render() {
    return (
      <header className="steps-header">
        <div className="steps">
          <div className={this.getStepClassNameFromIndex(0)}>Basic Information</div>
          <div className="left-caret">{'> '}</div>
          <div className={this.getStepClassNameFromIndex(1)}>Agreement Review</div>
          <div className="left-caret">{'> '}</div>
          <div className={this.getStepClassNameFromIndex(2)}>Payment & Send</div>
        </div>
      </header>
    );
  }
}

StepsHeader.propTypes = {
  currentStepIndex: PropTypes.number,
};

StepsHeader.defaultProps = {
  currentStepIndex: 0,
};
